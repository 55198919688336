import React from "react";
import { Link } from "react-router-dom";
import { SideBar } from "./sidebar";
import { getMyWinningList, maskClasses } from "../../models/auctions";
import { getAllGroups } from "../../models/groups";
import DataTable from "react-data-table-component";
import i18n from "../../i18n";
import { Spinner } from "react-bootstrap";
import { apiUrl } from "../../constants/global";

export class WinningBids extends React.Component {
  constructor() {
    super();
    this.state = {
      hide_menu: false,
      input: {},
      groups: {},
      bidders: {},
      phone_loader: false,
      open_popup: false,
      bidder_loading: false,
      customStyles: {
        headCells: {
          style: {
            fontWeight: "bold",
            backgroundColor: "#e60000",
            color: "#FFFFFF",
          },
        },
      },
      //actionsMemo : React.useMemo(() => <Export onExport={() => downloadCSV(data)} />, []),
      columns: [
        {
          name: "Auction ID",
          selector: "id",
          sortable: true,
          width: "70px",
        },
        {
          name: "Auction Number",
          selector: "phone_number",
          sortable: true,
        },
        {
          name: "Package",
          selector: "class",
          cell: (row) => <span>{maskClasses[row.class]}</span>,
        },
        {
          name: "Bid Amount",
          selector: "bid_amount",
        },
        {
          id: 10,
          name: i18n.t("invoice"),
          button: true,
          wrap: true,
          cell: (row) =>
            row.winner_file && (
              <div className="d-flex">
                <a
                  className="btn btn-dark btn-sm"
                  href={row.winner_file.link_en}
                  target="_new"
                >
                  {i18n.t("download")}
                </a>
              </div>
            ),
        },
      ],
    };

    this.handleChange = this.handleChange.bind(this);
    this.searchSubmit = this.searchSubmit.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadAllGroups();
    this.searchSubmit();
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
    if (event.target.name === "group") {
      this.setState({ input });
    }
  }

  async loadAllGroups() {
    const response = await getAllGroups();
    let groups = response.data;
    this.setState({ groups });
  }

  async searchSubmit() {
    this.setState({ bidders: {} });
    this.setState({ bidder_loading: true });
    const response = await getMyWinningList(this.state.input);
    if (response.data) {
      this.setState({ bidder_loading: false });
      this.setState({ bidders: response.data });
    }
  }

  render() {
    let groups = this.state.groups;
    let bidders = this.state.bidders;
    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image bg-background3"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-white">
                  <h1 className="">{i18n.t("reports")}</h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <Link to="/">{i18n.t("home")}</Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-white"
                      aria-current="page"
                    >
                      {i18n.t("dashboard")}
                    </li>
                    <li
                      className="breadcrumb-item active text-white"
                      aria-current="page"
                    >
                      {i18n.t("My Winning List")}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb">
          <div className="container">
            <div className="row">
              <div
                className={
                  this.state.hide_menu
                    ? "d-none"
                    : "col-xl-3 col-lg-12 col-md-12"
                }
              >
                <SideBar />
              </div>
              <div
                className={
                  this.state.hide_menu
                    ? "col-xl-12 col-lg-12 col-md-12"
                    : "col-xl-9 col-lg-12 col-md-12"
                }
              >
                <form
                  id="UpdateProfile"
                  className="card mb-0"
                  autoComplete="off"
                >
                  <div className="card-header">
                    <h3 className="card-title">
                      <span
                        onClick={() => {
                          this.setState({ hide_menu: !this.state.hide_menu });
                        }}
                      >
                        <i
                          class={
                            this.state.hide_menu
                              ? "fa fa-arrow-right"
                              : "fa fa-arrow-left"
                          }
                          aria-hidden="true"
                        ></i>
                        &nbsp;
                      </span>
                      {i18n.t("search")}
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("group")}
                          </label>
                          <select
                            className="form-control"
                            data-placeholder="Select Class"
                            name="group"
                            value={this.state.input.group}
                            onChange={this.handleChange}
                          >
                            <option value="">--{i18n.t("group")}--</option>
                            {groups.length > 0 &&
                              groups.map((item) => {
                                return (
                                  <option value={item.id} key={item.id}>
                                    {item.group_name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-2">
                        <div className="form-group">
                          <label className="form-label">&nbsp;</label>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={this.searchSubmit}
                            disabled={!this.state.input.group}
                          >
                            {i18n.t("search")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

                <hr />

                <div className="card mb-0">
                  <div className="card-header">
                    <h3 className="card-title">{i18n.t("result")}</h3>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive ">
                      {this.state.bidder_loading && (
                        <div className="text-center">
                          <Spinner animation="border" />
                        </div>
                      )}
                      {!bidders.length && (
                        <div className="alert alert-danger">
                          {i18n.t("no_result")}
                        </div>
                      )}
                      {bidders.length > 0 && (
                        <DataTable
                          title=""
                          columns={this.state.columns}
                          data={bidders}
                          customStyles={this.state.customStyles}
                          highlightOnHover
                          pagination
                          paginationPerPage={15}
                          paginationRowsPerPageOptions={[15, 25, 50, 100]}
                          paginationComponentOptions={{
                            rowsPerPageText: "Enrolls per page:",
                            rangeSeparatorText: "out of",
                          }}
                          //actions={this.state.actionsMemo}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
