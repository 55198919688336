import React from "react";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import { SideBar } from "./sidebar";
import swal from "sweetalert";
import i18n from "../../i18n";
import { getAllGroups, createGroup, deleteGroup } from "../../models/groups";
export class Groups extends React.Component {
  constructor() {
    super();
    this.state = {
      input: {},
      errors: {},
      groups: [],
      collapse: true,
      enquiries: [{ label: "", value: "" }],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.appendData = this.appendData.bind(this);
    this.removeEnquiryData = this.removeEnquiryData.bind(this);
    this.setEnquiryJson = this.setEnquiryJson.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadAllGroups();
  }

  async loadAllGroups() {
    const response = await getAllGroups();
    let groups = response.data;
    this.setState({ groups });
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    console.log(event.target.name + ", " + event.target.value);
    this.setState({ input });
  }
  handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let payload = this.state.input;
      payload.enquiries = this.state.enquiries;
      const response = await createGroup(payload);
      if (response && response.data && response.data.id) {
        swal("Success", response.message, "success", {
          buttons: false,
          timer: 2000,
        }).catch((response) => {
          swal("Failed", response.message, "error");
        });
        this.loadAllGroups();
        this.setState({ show_add_popup: false });
      } else {
        let error_message = response.message;
        let resObject = response.data;
        for (const key in resObject) {
          if (Object.hasOwnProperty.call(resObject, key)) {
            let element = resObject[key];
            error_message += "\n" + element;
          }
        }
        swal("Failed", error_message, "error");
      }
    } catch (err) {
      console.log(err);
    }
  };

  deleteRow(id) {
    const response = deleteGroup(id);
    console.log(response);
    swal("Success", "Item deleted", "success", {
      buttons: false,
      timer: 2000,
    })
      .then((response) => {
        this.loadAllGroups();
      })
      .catch((response) => {
        swal("Failed", "Something went wrong", "error");
      });
  }

  appendData(e) {
    e.preventDefault();
    let enquiries = this.state.enquiries;
    enquiries.push({ label: "", value: "" });
    this.setState({ enquiries: enquiries });
  }

  removeEnquiryData(key) {
    let enquiries = this.state.enquiries;
    delete enquiries[key];
    this.setState({ enquiries: enquiries });
  }

  setEnquiryJson(key, event, arrKey) {
    let enquiries = this.state.enquiries;
    enquiries[arrKey][key] = event.target.value;
    this.setState({ enquiries: enquiries });
  }

  render() {
    let groups = this.state.groups;
    let enquiries = this.state.enquiries;
    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image bg-background3"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-white">
                  <h1 className="">{i18n.t("dashboard")}</h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <Link to="/">{i18n.t("home")}</Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-white"
                      aria-current="page"
                    >
                      {i18n.t("dashboard")}
                    </li>
                    <li
                      className="breadcrumb-item active text-white"
                      aria-current="page"
                    >
                      {i18n.t("groups")}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <SideBar />
              </div>
              <div className="col-xl-9 col-lg-12 col-md-12">
                <p style={{ textAlign: "right" }}>
                  <button
                    class="btn btn-primary"
                    onClick={(event) => {
                      this.setState({
                        show_add_popup: !this.state.show_add_popup,
                      });
                    }}
                  >
                    Add New Group
                  </button>
                </p>

                <Modal
                  size="lg"
                  show={this.state.show_add_popup}
                  onHide={() => this.setState({ show_add_popup: false })}
                  aria-labelledby="example-modal-sizes-title-lg"
                >
                  <Modal.Header closeButton>
                    <Modal.Title id="example-modal-sizes-title-lg">
                      Create Group
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div
                      className={!this.state.show_add_popup ? "collapse" : ""}
                    >
                      <form
                        id="UpdateProfile"
                        className="card mb-0"
                        autoComplete="off"
                        onSubmit={this.handleSubmit}
                      >
                        <div className="card-body">
                          <div className="row">
                            <div className="col-sm-12 col-md-4">
                              <div className="form-group">
                                <label className="form-label">
                                  {i18n.t("group")}
                                </label>
                                <input
                                  type="text"
                                  name="group_name"
                                  className="form-control"
                                  placeholder="Group Name"
                                  value={this.state.input.group_name}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-4">
                              <div className="form-group">
                                <label className="form-label">
                                  {i18n.t("start_date")}
                                </label>
                                <input
                                  type="datetime-local"
                                  name="start_date"
                                  className="form-control"
                                  placeholder="Start Date"
                                  value={this.state.input.start_date}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-4">
                              <div className="form-group">
                                <label className="form-label">
                                  {i18n.t("end_date")}
                                </label>
                                <input
                                  type="datetime-local"
                                  name="end_date"
                                  className="form-control"
                                  placeholder="End Date"
                                  value={this.state.input.end_date}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>

                            <div className="col-sm-12 col-md-4">
                              <div className="form-group">
                                <label className="form-label">
                                  {i18n.t("registration_start_date")}
                                </label>
                                <input
                                  type="datetime-local"
                                  name="reg_start_date"
                                  className="form-control"
                                  placeholder="Registration Start Date"
                                  value={this.state.input.reg_start_date}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-4">
                              <div className="form-group">
                                <label className="form-label">
                                  {i18n.t("registration_end_date")}
                                </label>
                                <input
                                  type="datetime-local"
                                  name="reg_end_date"
                                  className="form-control"
                                  placeholder="Registration End Date"
                                  value={this.state.input.reg_end_date}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>

                            <div className="col-sm-12 col-md-4">
                              <div className="form-group">
                                <label className="form-label">
                                  {i18n.t("incr_amount")}(Comma seperated
                                  values)
                                </label>
                                <input
                                  type="text"
                                  name="increment_numbers"
                                  className="form-control"
                                  placeholder="Eg: 100,200,300..."
                                  value={this.state.input.increment_numbers}
                                  onChange={this.handleChange}
                                />
                              </div>
                            </div>

                            <div className="col-sm-12 col-md-12">
                              <div className="form-group">
                                <label className="form-label">
                                  {i18n.t("description")}
                                </label>
                                <textarea
                                  className="form-control"
                                  name="description"
                                  value={this.state.input.description}
                                  onChange={this.handleChange}
                                ></textarea>
                              </div>
                            </div>

                            <div className="col-sm-12 col-md-12">
                              <div className="form-group">
                                <label className="form-label">
                                  {i18n.t("terms_conditions")}
                                </label>
                                <textarea
                                  className="form-control"
                                  name="terms"
                                  value={this.state.input.terms}
                                  onChange={this.handleChange}
                                ></textarea>
                              </div>
                            </div>

                            <div className="col-sm-12 col-md-12">
                              <div className="form-group">
                                <label className="form-label">
                                  {i18n.t("terms_conditions")} (Arabic)
                                </label>
                                <textarea
                                  style={{ direction: "rtl" }}
                                  className="form-control"
                                  name="terms_arabic"
                                  value={this.state.input.terms_arabic}
                                  onChange={this.handleChange}
                                ></textarea>
                              </div>
                            </div>

                            <div className="col-sm-12 col-md-12">
                              <h5>{i18n.t("enquiry")}</h5>
                              {enquiries.length > 0 &&
                                enquiries.map((item, i) => {
                                  return (
                                    <div className="row" key={i}>
                                      <div className="col-sm-5 col-md-5">
                                        <div className="form-group">
                                          <label className="form-label">
                                            {i18n.t("label")}
                                          </label>
                                          <input
                                            type="text"
                                            name="jsonLabel"
                                            className="form-control"
                                            placeholder="Label"
                                            value={item.label || ""}
                                            onChange={(event) => {
                                              this.setEnquiryJson(
                                                "label",
                                                event,
                                                i
                                              );
                                            }}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-sm-5 col-md-5">
                                        <div className="form-group">
                                          <label className="form-label">
                                            {i18n.t("value")}
                                          </label>
                                          <input
                                            type="text"
                                            name="jsonValue"
                                            className="form-control"
                                            placeholder="Value"
                                            value={item.value || ""}
                                            onChange={(event) => {
                                              this.setEnquiryJson(
                                                "value",
                                                event,
                                                i
                                              );
                                            }}
                                          />
                                        </div>
                                      </div>

                                      {i > 0 && (
                                        <div className="col-sm-2 col-md-2">
                                          <div className="form-group">
                                            <label className="form-label">
                                              &nbsp;
                                            </label>
                                            <button
                                              type="button"
                                              className="btn btn-danger"
                                              key={i}
                                              onClick={(event) => {
                                                this.removeEnquiryData(i);
                                              }}
                                            >
                                              <i className="fa fa-minus"></i>
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                    </div>
                                  );
                                })}
                            </div>
                            <div className="col-sm-12 col-md-12">
                              <div
                                className="form-group"
                                style={{ textAlign: "right" }}
                              >
                                <button
                                  type="button"
                                  className="btn btn-info"
                                  onClick={this.appendData}
                                  style={{ marginRight: "20px" }}
                                >
                                  <i className="fa fa-plus"></i>
                                  <span>Add New</span>
                                </button>
                              </div>
                            </div>

                            <div className="col-sm-12 col-md-12">
                              <hr />
                              <div className="form-group">
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  Create Group
                                </button>{" "}
                                <button
                                  type="button"
                                  class="btn btn-danger"
                                  onClick={(event) => {
                                    this.setState({
                                      show_add_popup:
                                        !this.state.show_add_popup,
                                    });
                                  }}
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </Modal.Body>
                </Modal>

                <div className="card mb-0">
                  <div className="card-header">
                    <h3 className="card-title">{i18n.t("groups")}</h3>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive ">
                      {groups.length < 1 && <div>No groups found!!!</div>}
                      {groups.length > 0 && (
                        <table className="table table-bordered table-hover text-nowrap">
                          <thead>
                            <tr>
                              <th>{i18n.t("group")}</th>
                              <th>{i18n.t("start_date")}</th>
                              <th>{i18n.t("end_date")}</th>
                              <th>{i18n.t("edit")}</th>
                              <th>{i18n.t("delete")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {groups.map((item, i) => {
                              return (
                                <tr>
                                  <td>{item.group_name}</td>
                                  <td>{item.start_date}</td>
                                  <td>{item.end_date}</td>
                                  <td>
                                    <Link
                                      className="btn btn-warning btn-sm text-white m-1"
                                      to={"/edit_group/" + item.id}
                                    >
                                      <i className="fa fa-pencil"></i>&nbsp;
                                      {i18n.t("edit")}
                                    </Link>
                                  </td>
                                  <td>
                                    {item.can_delete && (
                                      <button
                                        className="btn btn-danger btn-sm text-white m-1"
                                        onClick={(e) => {
                                          this.deleteRow(item.id);
                                        }}
                                      >
                                        <i className="fa fa-times"></i>&nbsp;
                                        {i18n.t("delete")}
                                      </button>
                                    )}

                                    {!item.can_delete && (
                                      <small class="text text-danger">
                                        Linked to an auction. Can't delete.
                                      </small>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
