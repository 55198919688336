import { apiUrl } from "../constants/global";
import { getLoggedUser } from "../state/user";

const user = getLoggedUser();

async function getPayments() {
  return fetch(apiUrl + "payments", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getUserPayments() {
  return fetch(apiUrl + "user_payments", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}
export { getPayments, getUserPayments };
