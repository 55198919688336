import React from "react";
import { AuctionRow } from "../helpers/auctionRow";
import i18n from "../../i18n";
import { getMyAuctions } from "../../models/auctions";
import { SideBar } from "./sidebar";
import { Link } from "react-router-dom";
import { checkAuctionAvailability } from "../../models/groups";
import { Spinner } from "react-bootstrap";
import { getAllGroups } from "../../models/groups";
export class MyAuctions extends React.Component {
  constructor() {
    super();
    this.state = {
      input: {},
      groups: [],
      have_active_registrations: false,
      active_group: [],
      auctions: {},
      limit: 30,
      is_loading: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.loadAllAuctions = this.loadAllAuctions.bind(this);
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    const aucAvailabily = await checkAuctionAvailability();
    let have_active_registrations =
      aucAvailabily &&
      aucAvailabily.data &&
      aucAvailabily.data.have_active_registrations
        ? true
        : false;
    if (have_active_registrations) {
      let active_group =
        aucAvailabily && aucAvailabily.data && aucAvailabily.data.group;
      this.setState({ active_group: active_group });
    }
    this.setState({ have_active_registrations: have_active_registrations });

    this.loadAllGroups();
    //Load all auctions
    this.loadAllAuctions();
  }

  async loadAllGroups() {
    const response = await getAllGroups();
    let groups = response.data;
    this.setState({ groups });
    let meta = response.meta;
    if (meta && meta.active_group) {
      let { input } = this.state;
      input.group = meta.active_group.id;
      this.setState({
        input: input,
      });
    }
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
  }

  async loadAllAuctions() {
    this.setState({ is_loading: true });
    let { input, limit } = this.state;
    let q = "";
    if (limit) {
      q = "limit=" + limit;
    }
    if (input.group) {
      console.log(q);
      q = q ? q + `&group=${input.group}` : `?group=${input.group}`;
    }
    const response = await getMyAuctions(q);
    if (response) {
      let auctions = response.data;
      this.setState({ auctions: auctions });
      this.setState({ is_loading: false });
    }
  }

  render() {
    let groups = this.state.groups;
    let auctions = this.state.auctions;
    let limit = this.state.limit;
    let lt_30_cls =
      limit === 30
        ? "btn btn-primary btn-sm font-1vw"
        : "btn btn-light btn-sm font-1vw";
    let lt_50_cls =
      limit === 50
        ? "btn btn-primary btn-sm font-1vw"
        : "btn btn-light btn-sm font-1vw";
    let lt_100_cls =
      limit === 100
        ? "btn btn-primary btn-sm font-1vw"
        : "btn btn-light btn-sm font-1vw";
    let lt_200_cls =
      limit === 200
        ? "btn btn-primary btn-sm font-1vw"
        : "btn btn-light btn-sm font-1vw";
    let lt_all_cls =
      limit === false
        ? "btn btn-primary btn-sm font-1vw"
        : "btn btn-light btn-sm font-1vw";
    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image bg-background3"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-white">
                  <h1 className="">{i18n.t("dashboard")}</h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <Link to="/">{i18n.t("home")}</Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-white"
                      aria-current="page"
                    >
                      {i18n.t("dashboard")}
                    </li>
                    <li
                      className="breadcrumb-item active text-white"
                      aria-current="page"
                    >
                      {i18n.t("auctions")}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <SideBar />
              </div>
              <div className="col-xl-9 col-lg-12 col-md-12">
                <div className="card mb-0">
                  <div className="card-header">
                    <h3 className="card-title">{i18n.t("auctions")}</h3>
                  </div>
                  {auctions.length === 0 && !this.state.is_loading && (
                    <div className="card-body">
                      <div className="alert alert-danger">
                        No auctions found!!!
                      </div>
                    </div>
                  )}

                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                          <label className="form-label">Group</label>
                          <select
                            className="form-control"
                            data-placeholder="Select Group"
                            name="group"
                            value={this.state.input.group}
                            onChange={this.handleChange}
                          >
                            <option value="">--Group--</option>
                            {groups.length > 0 &&
                              groups.map((item) => {
                                return (
                                  <option value={item.id} key={item.id}>
                                    {item.group_name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                          <label className="form-label">&nbsp;</label>
                          <button
                            type="button"
                            class="btn btn-primary"
                            onClick={this.loadAllAuctions}
                          >
                            Search
                          </button>
                        </div>
                      </div>
                    </div>

                    {this.state.is_loading && (
                      <div
                        className="text-center m-3 text-dark"
                        style={{ textAlign: "center" }}
                      >
                        <Spinner animation="border" />
                      </div>
                    )}

                    {auctions.length > 0 && !this.state.is_loading && (
                      <div className="row">
                        <div>
                          <div className="bg-white p-5 item2-gl-nav">
                            <div className="d-sm-flex">
                              <label
                                className="font-1vw"
                                style={{
                                  float: "left",
                                  width: "auto",
                                  margin: "10px 0px",
                                }}
                              >
                                {!this.state.limit && (
                                  <div style={{ marginRight: "5px" }}>
                                    Showing all entries{" "}
                                  </div>
                                )}
                                {this.state.limit && (
                                  <div style={{ marginRight: "5px" }}>
                                    Showing 1 to {this.state.limit} of 30
                                    entries{" "}
                                  </div>
                                )}
                              </label>
                              <button
                                className={lt_30_cls}
                                onClick={() => {
                                  this.setState({ limit: 30 });
                                  this.loadAllAuctions(30);
                                }}
                              >
                                30
                              </button>
                              <button
                                className={lt_50_cls}
                                onClick={() => {
                                  this.setState({ limit: 50 });
                                  this.loadAllAuctions(50);
                                }}
                              >
                                50
                              </button>
                              <button
                                className={lt_100_cls}
                                onClick={() => {
                                  this.setState({ limit: 100 });
                                  this.loadAllAuctions(100);
                                }}
                              >
                                100
                              </button>
                              <button
                                className={lt_200_cls}
                                onClick={() => {
                                  this.setState({ limit: 200 });
                                  this.loadAllAuctions(200);
                                }}
                              >
                                200
                              </button>
                              <button
                                className={lt_all_cls}
                                onClick={() => {
                                  this.setState({ limit: false });
                                  this.loadAllAuctions();
                                }}
                              >
                                All
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="table-responsive ">
                          {auctions.length > 0 && (
                            <table className="table table-sm table-striped table-primary font-1vw">
                              <thead>
                                <tr>
                                  <th className="font-1vw">{i18n.t("slno")}</th>
                                  <th className="font-1vw">
                                    {i18n.t("number")}
                                  </th>
                                  <th className="font-1vw">
                                    {i18n.t("package")}
                                  </th>
                                  <th className="font-1vw">
                                    {i18n.t("current_amount")}
                                  </th>
                                  {/* <th className="d-none d-md-block">
                                  {i18n.t("start_date")}
                                </th> */}
                                  <th className="font-1vw">
                                    {i18n.t("days_left")}
                                  </th>
                                  <th className="font-1vw">
                                    {i18n.t("bid_count")}
                                  </th>
                                  <th className="font-1vw">
                                    {i18n.t("my_rank")}
                                  </th>
                                  <th></th>
                                </tr>
                              </thead>
                              <tbody>
                                {auctions.map((item, i) => {
                                  return (
                                    <AuctionRow
                                      key={item.id}
                                      index={i}
                                      item={item}
                                      auction_page="my_auctions"
                                      is_enrolled={true}
                                      user_role="U"
                                    />
                                  );
                                })}
                              </tbody>
                            </table>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
