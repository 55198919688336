import { apiUrl } from "../constants/global";
import { getLoggedUser } from "../state/user";

const maskClasses = {
  D: "Diamond",
  G: "Gold",
};

const endpoint = apiUrl + "groups";
const user = getLoggedUser();

async function createGroup(payload) {
  return fetch(endpoint, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function getAllGroups() {
  return fetch(endpoint, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getGroup(id) {
  return fetch(endpoint + "/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function updateGroup(payload, id) {
  return fetch(endpoint + "/" + id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

function deleteGroup(id) {
  return fetch(endpoint + "/" + id, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function checkAuctionAvailability() {
  return fetch(apiUrl + "is_auctions_available", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((data) => data.json());
}

export {
  createGroup,
  getAllGroups,
  getGroup,
  updateGroup,
  deleteGroup,
  checkAuctionAvailability,
  maskClasses,
};
