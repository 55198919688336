import React from "react";
import { getLoggedUser } from "../../state/user";
import { DashboardAdmin } from "./dashboard_admin";
import { DashboardUser } from "./dashboard_user";

const user = getLoggedUser();

export class Dashboard extends React.Component {
  constructor() {
    super();
    this.state = {};
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <React.Fragment>
        {(user.role === "A" || user.role === "VA") && <DashboardAdmin />}
        {user.role === "U" && <DashboardUser />}
      </React.Fragment>
    );
  }
}
