import { apiUrl } from "../constants/global";
import axios from "axios";

async function registerUser(payload) {
  return fetch(apiUrl + "register", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function registerAndEnroll(payload) {
  let config = {
    headers: {
      "Content-Type": "application/json",
    },
  };
  try {
    const { data: response } = await axios.post(
      apiUrl + "register_enroll",
      payload,
      config
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

function deleteUser(payload) {
  return fetch(apiUrl + "payment_failed", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

export { registerUser, registerAndEnroll, deleteUser };
