import { apiUrl } from "../constants/global";
import { getLoggedUser } from "../state/user";
import axios from "axios";

const maskClasses = {
  D: "Diamond",
  G: "Gold",
  S: "Silver",
};

const maskClassesAr = {
  D: "ألماسي",
  G: "ذهبي",
  S: "Silver",
};

const statuses = {
  A: "Approved",
  E: "Expired",
  C: "Cancel",
  N: "Not Approved",
};

const user = getLoggedUser();

async function getAuctions(q) {
  return fetch(apiUrl + "auctions?" + q, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getAllBidders(payload) {
  return fetch(apiUrl + "auctions/bidders", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function getActiveAuctions(q) {
  return fetch(apiUrl + "active/auctions?" + q, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((data) => data.json());
}

async function getEnrolledAuctions(q) {
  return fetch(apiUrl + "enrolled/auctions?" + q, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getMyAuctions(q) {
  return fetch(apiUrl + "my/auctions?" + q, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getAuction(id) {
  return fetch(apiUrl + "auctions/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((data) => data.json());
}

async function createAuction(payload) {
  return fetch(apiUrl + "auctions", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function createBulkAuction(payload) {
  return fetch(apiUrl + "auctions/bulk", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function updateAuction(payload, id) {
  return fetch(apiUrl + "auctions/" + id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

function deleteAuction(id) {
  return fetch(apiUrl + "auctions/" + id, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function checkUserValidity(payload) {
  return fetch(apiUrl + "check_user_validity", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function bidNow(payload) {
  return fetch(apiUrl + "bid_now", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function getTopBidders(payload) {
  return fetch(apiUrl + "get_top_bidders", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function updatePayment(payload) {
  return fetch(apiUrl + "update_payment", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function updatePaymentReg(payload) {
  return fetch(apiUrl + "update_payment_reg", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function enrollUser(payload) {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  };
  try {
    const { data: response } = await axios.post(
      apiUrl + "enroll_user",
      payload,
      config
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

async function getAllAuctionsByGroup(group) {
  return fetch(apiUrl + "auctions_by_group/" + group, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}
async function getAllClosedAuctionsByGroup(group) {
  return fetch(apiUrl + "closed_auctions_by_group/" + group, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getAllBiddersByGroup(payload) {
  return fetch(apiUrl + "bidder_report", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function getAllEnrolledUsers(payload) {
  return fetch(apiUrl + "enrolled_users_report", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function sendNotification(payload) {
  return fetch(apiUrl + "send_notification", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function getAllPaymentsByGroup(payload) {
  return fetch(apiUrl + "payment_report", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function getAllWinnersByGroup(payload) {
  return fetch(apiUrl + "winners_report", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function getAllBidderPositions(payload) {
  return fetch(apiUrl + "position_report", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function sendWinnerNotification(payload) {
  return fetch(apiUrl + "winner_notification", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function sendParticipantNotification(payload) {
  return fetch(apiUrl + "participants_notification", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function bulkAuctionCreateFromUpload(payload) {
  let config = {
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  };
  try {
    const { data: response } = await axios.post(
      apiUrl + "create_auction_upload",
      payload,
      config
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

async function getLiveActivity() {
  return fetch(apiUrl + "live_activity", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getMyWinningList(payload) {
  return fetch(apiUrl + "winning_list", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function getEnrolledUsers(q) {
  return fetch(apiUrl + "enrolled/users?" + q, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

export {
  createAuction,
  getAuctions,
  getActiveAuctions,
  getEnrolledAuctions,
  deleteAuction,
  getAuction,
  updateAuction,
  checkUserValidity,
  bidNow,
  getTopBidders,
  enrollUser,
  updatePayment,
  updatePaymentReg,
  getAllBidders,
  createBulkAuction,
  getAllAuctionsByGroup,
  getAllBiddersByGroup,
  getMyAuctions,
  sendNotification,
  getAllPaymentsByGroup,
  getAllEnrolledUsers,
  getAllWinnersByGroup,
  getAllClosedAuctionsByGroup,
  getAllBidderPositions,
  sendWinnerNotification,
  sendParticipantNotification,
  bulkAuctionCreateFromUpload,
  getLiveActivity,
  getMyWinningList,
  getEnrolledUsers,
  maskClasses,
  maskClassesAr,
  statuses,
};
