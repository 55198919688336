import React from "react";
import { Link } from "react-router-dom";
import { SideBar } from "./sidebar";
import { sendBulkMail } from "../../models/users";
import { getAllGroups } from "../../models/groups";
import swal from "sweetalert";
import i18n from "../../i18n";
//import RichTextEditor from 'react-rte';

export class BulkMail extends React.Component {
  constructor() {
    super();
    this.state = {
      input: {
        all_users: "Y",
        group: false,
      },
      groups: {},
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadAllGroups();
  }

  async loadAllGroups() {
    const response = await getAllGroups();
    let groups = response.data;
    this.setState({ groups });
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
    console.log(input);
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let payload = this.state.input;
      payload.rows = this.state.row;
      payload.enquiries = this.state.enquiries;
      const response = await sendBulkMail(payload);
      if (response && response.data && response.data.success) {
        swal("Success", response.message, "success", {
          buttons: false,
          timer: 2000,
        })
          .then((value) => {
            //this.setState({ input: {} });
          })
          .catch((response) => {
            swal("Failed", response.message, "error");
          });
      } else {
        let error_message = response.message;
        let resObject = response.data;
        for (const key in resObject) {
          if (Object.hasOwnProperty.call(resObject, key)) {
            let element = resObject[key];
            error_message += "\n" + element;
          }
        }
        swal("Failed", error_message, "error");
      }
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    let groups = this.state.groups;
    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image bg-background3"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-white">
                  <h1 className="">{i18n.t("dashboard")}</h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <Link to="/">{i18n.t("home")}</Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-white"
                      aria-current="page"
                    >
                      {i18n.t("mails")}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <SideBar />
              </div>
              <div className="col-xl-9 col-lg-12 col-md-12">
                <form
                  id="UpdateProfile"
                  className="card mb-0"
                  autoComplete="off"
                  onSubmit={this.handleSubmit}
                >
                  <div className="card-header">
                    <h3 className="card-title">{i18n.t("bulk mail")}</h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div
                        className={
                          this.state.input.all_users === "G"
                            ? "col-sm-12 col-md-6 p-5"
                            : "col-sm-12 col-md-12 p-5"
                        }
                      >
                        <div className="form-group d-flex">
                          <div class="form-check m-3">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="all_users"
                              value="Y"
                              checked={this.state.input.all_users === "Y"}
                              onChange={this.handleChange}
                            />
                            <label
                              class="form-check-label"
                              for="flexRadioDefault2"
                            >
                              All users
                            </label>
                          </div>

                          <div class="form-check m-3">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="all_users"
                              value="G"
                              checked={this.state.input.all_users === "G"}
                              onChange={this.handleChange}
                            />
                            <label
                              class="form-check-label"
                              for="flexRadioDefault1"
                            >
                              Group wise
                            </label>
                          </div>

                          <div class="form-check m-3">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="all_users"
                              value="N"
                              checked={this.state.input.all_users === "N"}
                              onChange={this.handleChange}
                            />
                            <label
                              class="form-check-label"
                              for="flexRadioDefault1"
                            >
                              Selected Emails
                            </label>
                          </div>
                        </div>
                      </div>

                      {this.state.input.all_users === "N" && (
                        <div className="col-sm-12 col-md-12">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("Emails")} ({"comma seperated"})
                            </label>
                            <textarea
                              className="form-control"
                              name="emails"
                              value={this.state.input.emails}
                              onChange={this.handleChange}
                            ></textarea>
                          </div>
                        </div>
                      )}

                      {this.state.input.all_users === "G" && (
                        <div className="col-sm-12 col-md-6">
                          <div className="form-group">
                            <label className="form-label">
                              {i18n.t("group")}
                            </label>
                            <select
                              className="form-control"
                              data-placeholder="Select Group"
                              name="group"
                              value={this.state.group}
                              onChange={this.handleChange}
                            >
                              <option value="">--{i18n.t("group")}--</option>
                              {groups.length > 0 &&
                                groups.map((item) => {
                                  return (
                                    <option value={item.id} key={item.id}>
                                      {item.group_name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                      )}

                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("title")}
                          </label>
                          <input
                            className="form-control"
                            name="title"
                            value={this.state.input.title}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("title_ar")}
                          </label>
                          <input
                            className="form-control"
                            name="title_ar"
                            value={this.state.input.title_ar}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-12">
                        <div className="form-group">
                          <label className="form-label">{i18n.t("body")}</label>
                          <textarea
                            className="form-control"
                            name="body"
                            value={this.state.input.body}
                            onChange={this.handleChange}
                          ></textarea>
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-12">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("body_ar")}
                          </label>
                          <textarea
                            className="form-control"
                            name="body_ar"
                            value={this.state.input.body_ar}
                            onChange={this.handleChange}
                          ></textarea>
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-12">
                        <div className="form-group">
                          <label className="form-label">{i18n.t("link")}</label>
                          <input
                            className="form-control"
                            name="link"
                            value={this.state.input.link}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer" style={{ textAlign: "right" }}>
                    <button type="submit" className="btn btn-primary">
                      Send Mail
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
