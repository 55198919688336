import React from "react";
import { Link } from "react-router-dom";
import { SideBar } from "./sidebar";
import { updateAuction, getAuction, statuses } from "../../models/auctions";
import { getAllGroups, getGroup } from "../../models/groups";
import { getAllMasks, getMask } from "../../models/masks";
import swal from "sweetalert";
import i18n from "../../i18n";
//import RichTextEditor from 'react-rte';

export class EditAuction extends React.Component {
  constructor(props) {
    super(props);
    let path = window.location.pathname;
    let auctionId = path.substring(path.lastIndexOf("/") + 1, path.length);

    this.state = {
      auctionId: auctionId,
      input: {
        //description: RichTextEditor.createEmptyValue(),
      },
      errors: {},
      masks: {},
      groups: {},
      enquiries: [{ label: "", value: "" }],
      jsonLabel: "",
      jsonValue: "",
      //value: RichTextEditor.createEmptyValue(),
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.appendData = this.appendData.bind(this);
    this.removeEnquiryData = this.removeEnquiryData.bind(this);
    this.setEnquiryJson = this.setEnquiryJson.bind(this);
  }

  componentDidMount() {
    this.loadAllMasks();
    this.loadAllGroups();
    this.loadAuction();
  }

  async loadAllGroups() {
    const response = await getAllGroups();
    let groups = response.data;
    this.setState({ groups });
  }

  async loadAuction() {
    let id = this.state.auctionId;
    const response = await getAuction(id);
    let auction = response.data;
    let enquiry = auction.enquiry;
    auction.selected_mask = auction.mask;
    this.setState({ input: auction, enquiries: enquiry });
  }

  async loadAllMasks() {
    const response = await getAllMasks();
    let masks = response.data;
    this.setState({ masks });
  }

  async loadMask(selected_mask) {
    const response = await getMask(selected_mask);
    let result = response.data;
    let input = this.state.input;
    input["class"] = result.class;
    input["start_amount"] = result.start_amount;
    input["bid_increment"] = result.bid_increment;
    this.setState({ input });
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
    if (event.target.name === "selected_mask") {
      this.loadMask(event.target.value);
    }
    if (event.target.name === "group") {
      this.loadGroup(event.target.value);
    }
  }

  async loadGroup(selected_group) {
    const response = await getGroup(selected_group);
    let result = response.data;
    let input = this.state.input;
    input["start_date"] = result.start_date;
    input["end_date"] = result.end_date;
    input["description"] = result.description;
    input["terms"] = result.terms;
    input["terms_arabic"] = result.terms_arabic;
    input["enquiries"] = result.enquiry;
    input["increment_numbers"] = result.increment_numbers;
    this.setState({ input });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let payload = this.state.input;
      console.log(payload);
      payload.enquiries = this.state.enquiries;
      let id = this.state.auctionId;
      const response = await updateAuction(payload, id);
      if (response && response.data && response.data.id) {
        swal("Success", response.message, "success", {
          buttons: false,
          timer: 2000,
        }).catch((response) => {
          swal("Failed", response.message, "error");
        });
      } else {
        let error_message = "Not found!!!";
        let error_messages = response.message;
        for (const key in error_messages) {
          if (Object.hasOwnProperty.call(error_messages, key)) {
            let element = error_messages[key];
            error_message += "\n" + element;
          }
        }
        swal("Failed", error_message, "error");
      }
    } catch (err) {
      console.log(err);
    }
  };

  appendData(e) {
    e.preventDefault();
    let enquiries = this.state.enquiries || [];
    console.log(enquiries);
    enquiries.push({ label: "", value: "" });
    this.setState({ enquiries: enquiries });
  }

  removeEnquiryData(key) {
    let enquiries = this.state.enquiries;
    delete enquiries[key];
    this.setState({ enquiries: enquiries });
  }

  setEnquiryJson(key, event, arrKey) {
    let enquiries = this.state.enquiries;
    enquiries[arrKey][key] = event.target.value;
    this.setState({ enquiries: enquiries });
  }

  render() {
    let masks = this.state.masks;
    let groups = this.state.groups;
    let enquiries = this.state.enquiries;
    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image bg-background3"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-white">
                  <h1 className="">{i18n.t("dashboard")}</h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <Link to="/">{i18n.t("home")}</Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-white"
                      aria-current="page"
                    >
                      {i18n.t("dashboard")}
                    </li>
                    <li
                      className="breadcrumb-item active text-white"
                      aria-current="page"
                    >
                      {i18n.t("auctions")}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <SideBar />
              </div>
              <div className="col-xl-9 col-lg-12 col-md-12">
                <form
                  id="UpdateProfile"
                  className="card mb-0"
                  autoComplete="off"
                  onSubmit={this.handleSubmit}
                >
                  <div className="card-header">
                    <h3 className="card-title">{i18n.t("edit_auction")}</h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">Group</label>
                          <select
                            className="form-control"
                            data-placeholder="Select Group"
                            name="group"
                            value={this.state.input.group}
                            onChange={this.handleChange}
                          >
                            <option value="">--Group--</option>
                            {groups.length > 0 &&
                              groups.map((item) => {
                                return (
                                  <option value={item.id} key={item.id}>
                                    {item.group_name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("phone_number")}
                          </label>
                          <input
                            type="text"
                            name="phone_number"
                            className="form-control"
                            placeholder="Phone Number"
                            value={this.state.input.phone_number}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      {masks.length > 0 && (
                        <div className="col-sm-12 col-md-6">
                          <div className="form-group">
                            <label className="form-label">Mask</label>
                            <select
                              className="form-control"
                              data-placeholder="Select Mask"
                              name="selected_mask"
                              value={this.state.input.selected_mask}
                              onChange={this.handleChange}
                            >
                              <option value="">--Mask--</option>
                              {masks.map((item) => {
                                return (
                                  <option value={item.id}>
                                    {item.mask_name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      )}
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("class")}
                          </label>
                          <select
                            className="form-control"
                            data-placeholder="Select Class"
                            name="class"
                            value={this.state.input.class}
                            onChange={this.handleChange}
                          >
                            <option value="">--{i18n.t("class")}--</option>
                            <option value="D">{i18n.t("diamond")}</option>
                            <option value="G">{i18n.t("gold")}</option>
                          </select>
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("price")}
                          </label>
                          <input
                            type="text"
                            name="start_amount"
                            className="form-control"
                            placeholder="Price"
                            value={this.state.input.start_amount}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("incr_amount")}
                          </label>
                          <input
                            type="text"
                            name="bid_increment"
                            className="form-control"
                            placeholder="Increment Amount"
                            value={this.state.input.bid_increment}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("incr_amount")} (comma seperated values)
                          </label>
                          <input
                            type="text"
                            name="increment_numbers"
                            className="form-control"
                            placeholder="eg: 100,200,300"
                            value={this.state.input.increment_numbers}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("start_date")} (
                            {this.state.input.start_date})
                          </label>
                          <input
                            type="datetime-local"
                            className="form-control"
                            placeholder="Email"
                            name="start_date"
                            value={this.state.input.start_date}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("end_date")} ({this.state.input.end_date})
                          </label>
                          <input
                            type="datetime-local"
                            name="end_date"
                            className="form-control"
                            placeholder="Phone"
                            value={this.state.input.end_date}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">Status</label>
                          <select
                            className="form-control"
                            data-placeholder="Change Status"
                            name="status"
                            value={this.state.input.status}
                            onChange={this.handleChange}
                          >
                            <option value="">--Status--</option>
                            {Object.keys(statuses).map(function (key) {
                              return (
                                <option value={key}>{statuses[key]}</option>
                              );
                            })}
                          </select>
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-12">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("description")}
                          </label>
                          <textarea
                            className="form-control"
                            name="description"
                            value={this.state.input.description}
                            onChange={this.handleChange}
                          ></textarea>
                          {/* <RichTextEditor 
												name="description"
												editorState={this.state.input.description} 
												value={this.state.input.description} 
												onChange={this.handleChange}
											/> */}
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-12">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("terms_conditions")}
                          </label>
                          <textarea
                            className="form-control"
                            name="terms"
                            value={this.state.input.terms}
                            onChange={this.handleChange}
                          ></textarea>
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-12">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("terms_conditions")} (Ar)
                          </label>
                          <textarea
                            style={{ direction: "rtl" }}
                            className="form-control"
                            name="terms_arabic"
                            value={this.state.input.terms_arabic}
                            onChange={this.handleChange}
                          ></textarea>
                        </div>
                      </div>

                      <div className="col-sm-12 col-md-12">
                        <h5>{i18n.t("enquiry")}</h5>
                        {enquiries &&
                          enquiries.length > 0 &&
                          enquiries.map((item, i) => {
                            return (
                              <div className="row" key={i}>
                                <div className="col-sm-6 col-md-6">
                                  <div className="form-group">
                                    <label className="form-label">Label</label>
                                    <input
                                      type="text"
                                      name="jsonLabel"
                                      className="form-control"
                                      placeholder="Label"
                                      value={item.label || ""}
                                      onChange={(event) => {
                                        this.setEnquiryJson("label", event, i);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-5 col-md-5">
                                  <div className="form-group">
                                    <label className="form-label">Value</label>
                                    <input
                                      type="text"
                                      name="jsonValue"
                                      className="form-control"
                                      placeholder="Value"
                                      value={item.value || ""}
                                      onChange={(event) => {
                                        this.setEnquiryJson("value", event, i);
                                      }}
                                    />
                                  </div>
                                </div>

                                {i > 0 && (
                                  <div className="col-sm-1 col-md-1">
                                    <div className="form-group">
                                      <label className="form-label">
                                        &nbsp;
                                      </label>
                                      <button
                                        type="button"
                                        className="btn btn-danger"
                                        key={i}
                                        onClick={(event) => {
                                          this.removeEnquiryData(i);
                                        }}
                                      >
                                        <i className="fa fa-minus"></i>
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            );
                          })}
                      </div>

                      <div className="col-sm-12 col-md-12">
                        <div
                          className="form-group"
                          style={{ textAlign: "right" }}
                        >
                          <button
                            type="button"
                            className="btn btn-info"
                            onClick={this.appendData}
                            style={{ marginRight: "20px" }}
                          >
                            <i className="fa fa-plus"></i>
                            <span>Add New</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer" style={{ textAlign: "right" }}>
                    <button type="submit" className="btn btn-primary">
                      Update Auction
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
