import React from "react";
import { Link } from "react-router-dom";

export class CeoMessage extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image bg-background3 sptb-2"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-white ">
                  <h1 className="">CEO Message</h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <Link exact="true" to="/">
                        Home
                      </Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-white"
                      aria-current="page"
                    >
                      About Us
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb">
          <div className="container">
            <div className="text-justify mb-7">
              <h2 className="mb-8">CEO Message</h2>
              <h4 className="leading-Automatic">
                Majority have suffered alteration in some form, by injected
                humor
              </h4>
              <p className="leading-Automatic mb-4">
                Mzadcom is an integrated electronic system specialized in the
                field of smart auctions produced by Mzadcom Smart Auction
                Solutions LLC. An Omani company established in 2019. The company
                studied and analyzed the local and international auction market
                and reached to innovative,
              </p>
              <p className="leading-Automatic mb-4">
                Organized and smart solutions to serve both exhibitor and bidder
                in partnership with local companies and an Omani work team.
                Mzadcom electronic auction system seeks to overcome all
                obstacles and barriers in the field of auctions, provide the
                community with opportunity to benefit from auctions available in
                the local and international markets, Save effort and money,
                support charitable organization, and create job opportunities.
              </p>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
