import React from "react";
import { Link } from "react-router-dom";
import { SideBar } from "./sidebar";
import {
  getEnrolledUsers,
  //activateUserEnroll,
  //deActivateUserEnroll,
  //activateAllUserEnroll,
} from "../../models/auctions";
import DataTable from "react-data-table-component";
import i18n from "../../i18n";
//import swal from "sweetalert";
import { Spinner } from "react-bootstrap";
import { getAllGroups } from "../../models/groups";

const customStyles = {
  headCells: {
    style: {
      fontWeight: "bold",
      backgroundColor: "#e60000",
      color: "#FFFFFF",
    },
  },
};

export class EnrolledUsers extends React.Component {
  constructor() {
    super();
    this.state = {
      totalRows: 0,
      currentPage: 1,
      input: {
        page: 1,
        limit: 15,
      },
      query: "",
      auctions: {},
      is_loading: false,
      groups: {},
      columns: [
        {
          name: "ID",
          selector: "id",
          sortable: true,
          width: "100px",
        },
        {
          name: "Group ID",
          selector: "auction_id",
          sortable: true,
          width: "100px",
          cell: (row) => row.group.id,
        },
        {
          name: "Group",
          selector: "auction_id",
          sortable: true,
          cell: (row) => row.group.group_name,
        },
        {
          name: i18n.t("name"),
          selector: "name",
          sortable: true,
          cell: (row) => row.user.name,
        },
        {
          name: i18n.t("username"),
          selector: "username",
          sortable: true,
          cell: (row) => row.user.username,
        },
        {
          name: i18n.t("email"),
          selector: "email",
          cell: (row) => row.user.email,
        },
        {
          name: i18n.t("mobile"),
          selector: "mobile",
          wrap: true,
          cell: (row) => row.user.mobile,
        },
        {
          name: i18n.t("identity_type"),
          wrap: true,
          cell: (row) => row.identity_type,
        },
        {
          name: i18n.t("Civil Card"),
          selector: "files5",
          cell: (row) =>
            row.file_civil_card ? (
              <a href={row.file_civil_card} target="_blank" rel="noreferrer">
                Download
              </a>
            ) : (
              ""
            ),
        },
        {
          name: i18n.t("Signature"),
          selector: "files4",
          cell: (row) =>
            row.file_signature ? (
              <a href={row.file_signature} target="_blank" rel="noreferrer">
                Download
              </a>
            ) : (
              ""
            ),
        },
        {
          name: i18n.t("civil_id_number"),
          wrap: true,
          cell: (row) => row.civil_id_number,
        },
        // {
        //   name: i18n.t("payment type"),
        //   wrap: true,
        //   cell: (row) => {
        //     if (row.auction.payment_type === "Both") {
        //       return "Online & Offline";
        //     }
        //     return row.auction.payment_type;
        //   },
        // },
        // {
        //   name: i18n.t("payment status"),
        //   wrap: true,
        //   cell: (row) =>
        //     row.payment != null && row.payment.status === "P" ? (
        //       <span className="text-success">Paid</span>
        //     ) : (
        //       <span className="text-danger"></span>
        //     ),
        // },
        {
          name: i18n.t("status"),
          selector: "status",
          cell: (row) =>
            row.status === "A" ? (
              <span className="text-success">Approved</span>
            ) : (
              <span className="text-warning">Pending</span>
            ),
        },
        // {
        //   name: i18n.t("Action"),
        //   button: true,
        //   cell: (row) => (
        //     <button
        //       className={
        //         row.status === "A" ? "btn btn-danger" : "btn btn-success"
        //       }
        //       onClick={(e) => {
        //         if (row.status === "A") {
        //           row.status = "N";
        //           this.deActivate(row.id);
        //         } else {
        //           row.status = "A";
        //           this.activate(row.id);
        //         }
        //       }}
        //     >
        //       {row.status === "A" ? i18n.t("Disable") : i18n.t("Enable")}
        //     </button>
        //   ),
        // },
      ],
    };
    this.loadAllGroups = this.loadAllGroups.bind(this);
    this.handleChange = this.handleChange.bind(this);
    // this.activateAllUserEnroll = this.activateAllUserEnroll.bind(this);
    // this.activate = this.activate.bind(this);
    // this.deActivate = this.deActivate.bind(this);
  }

  componentDidMount() {
    let input = this.state.input;
    let qstring = new URLSearchParams(input).toString();
    this.setState({ query: qstring });
    this.loadAllGroups();
    this.loadAllEnrolledUsers(qstring);
  }

  async loadAllGroups() {
    const response = await getAllGroups();
    let groups = response.data;
    this.setState({ groups });
  }

  async loadAllEnrolledUsers(q) {
    this.setState({ is_loading: true });
    if (this.state.input.group) {
      q = q + "&group=" + this.state.input.group;
      this.setState({ query: q });
    }
    const response = await getEnrolledUsers(q);
    let users = response.data;
    let meta = response.meta;
    if (users) {
      this.setState({ is_loading: false });
      this.setState({ auctions: users });
      this.setState({ totalRows: meta.total });
    }
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  handlePageChange = (page) => {
    let input = this.state.input;
    let currentPage = page;
    input["page"] = currentPage;
    this.setState({ currentPage: currentPage });
    this.setState({ input: input });
    let qstring = new URLSearchParams(input).toString();
    this.setState({ query: qstring });
    this.loadAllEnrolledUsers(qstring);
  };

  // activateAllUserEnroll() {
  //   swal("Are you sure.? Do you want to continue.?", {
  //     buttons: {
  //       yes: {
  //         text: "Yes",
  //         value: "yes",
  //       },
  //       no: {
  //         text: "No",
  //         value: "no",
  //       },
  //     },
  //   }).then((value) => {
  //     if (value === "yes") {
  //       const response = activateAllUserEnroll({
  //         group: this.state.input.group,
  //       });
  //       swal("Success", "All enrollments activated for this user.", "success", {
  //         buttons: false,
  //         timer: 2000,
  //       });
  //       this.loadAllEnrolledUsers(this.state.query);
  //     }
  //     return false;
  //   });
  // }

  // async activate(id) {
  //   const response = await activateUserEnroll({ enroll_id: id });
  //   swal("Success", "User activated", "success", {
  //     buttons: false,
  //     timer: 2000,
  //   })
  //     .then((response) => {
  //       this.loadAllEnrolledUsers(this.state.query);
  //     })
  //     .catch((response) => {
  //       swal("Failed", "Something went wrong", "error");
  //     });
  // }

  // async deActivate(id) {
  //   const response = await deActivateUserEnroll({ enroll_id: id });
  //   swal("Success", "User disabled", "success", {
  //     buttons: false,
  //     timer: 2000,
  //   })
  //     .then((response) => {
  //       this.loadAllEnrolledUsers(this.state.query);
  //     })
  //     .catch((response) => {
  //       swal("Failed", "Something went wrong", "error");
  //     });
  // }

  render() {
    let groups = this.state.groups;
    let auctions = this.state.auctions;
    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image bg-background3"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-white">
                  <h1 className="">{i18n.t("dashboard")}</h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <Link to="/">{i18n.t("home")}</Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-white"
                      aria-current="page"
                    >
                      {i18n.t("dashboard")}
                    </li>
                    <li
                      className="breadcrumb-item active text-white"
                      aria-current="page"
                    >
                      {i18n.t("auctions")}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                {!this.state.hideSidebar && <SideBar />}
              </div>
              <div
                className={
                  this.state.hideSidebar
                    ? "col-xl-12 col-lg-12 col-md-12"
                    : "col-xl-9 col-lg-12 col-md-12"
                }
              >
                <div className="card mb-0">
                  <div className="card-header">
                    <h3 className="card-title">
                      <span
                        style={{ fontSize: "1rem", marginRight: "0.5rem" }}
                        onClick={() => {
                          this.setState({
                            hideSidebar: !this.state.hideSidebar,
                          });
                        }}
                      >
                        {this.state.hideSidebar && (
                          <i className="fa fa-chevron-right"></i>
                        )}
                        {!this.state.hideSidebar && (
                          <i className="fa fa-chevron-left"></i>
                        )}
                      </span>
                      {i18n.t("enrolled_users")}
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6 col-md-6">
                        <div className="form-group">
                          <label className="form-label">Group</label>
                          <select
                            className="form-control"
                            data-placeholder="Select Group"
                            name="group"
                            value={this.state.input.group}
                            onChange={this.handleChange}
                          >
                            <option value="">--Group--</option>
                            {groups.length > 0 &&
                              groups.map((item) => {
                                return (
                                  <option value={item.id} key={item.id}>
                                    {item.group_name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-2 col-md-2">
                        <div className="form-group">
                          <label className="form-label">&nbsp;</label>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={(e) => {
                              this.loadAllEnrolledUsers(this.state.query);
                            }}
                          >
                            Search
                          </button>
                        </div>
                      </div>

                      {/* {this.state.input.group && auctions.length > 0 && (
                        <div className="col-sm-4 col-md-4">
                          <div className="form-group">
                            <label className="form-label">&nbsp;</label>
                            <button
                              type="button"
                              className="btn btn-info btn-block"
                              onClick={this.activateAllUserEnroll}
                            >
                              Activate all enrolls in this group
                            </button>
                          </div>
                        </div>
                      )} */}
                    </div>
                    {this.state.is_loading && (
                      <div className="text-center">
                        <Spinner animation="border" size="lg" />
                      </div>
                    )}

                    <div className="table-responsive ">
                      {auctions.length > 0 && (
                        <DataTable
                          title=""
                          fixedHeader
                          columns={this.state.columns}
                          data={auctions}
                          customStyles={customStyles}
                          highlightOnHover
                          pagination
                          paginationServer
                          paginationTotalRows={this.state.totalRows}
                          paginationPerPage={15}
                          paginationDefaultPage={this.state.currentPage}
                          paginationRowsPerPageOptions={[15, 25, 50, 100]}
                          paginationComponentOptions={{
                            rowsPerPageText: "Enrolls per page:",
                            rangeSeparatorText: "out of",
                          }}
                          onChangePage={this.handlePageChange}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
