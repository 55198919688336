import React from "react";
import { Link } from "react-router-dom";
import i18n from "../../i18n";

export class BeingPart extends React.Component {
  render() {
    let have_active_registrations = this.props.have_active_registrations;
    let active_group = this.props.active_group;
    return (
      <React.Fragment>
        <section className="bg-white pt-9 pb-9">
          <div className="container">
            {/* <div className="section-title center-block text-center">
              <h2>{i18n.t("beingpart_vodafone")}</h2>
            </div> */}
            <div className="row mb-2 mt-1 mr-8 ml-8 justify-content-center">
              {have_active_registrations && (
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <div className="card p-5">
                    <div className="mb-lg-0 mb-4">
                      <Link to={"/registration/" + active_group.group}>
                        <div className="service-card text-center">
                          <div className="bg-light icon-bg icon-service text-purple about box-shadow2">
                            <img
                              src="../assets/images/home/register_profile.svg"
                              alt="img"
                            />
                          </div>
                          <div className="servic-data mt-3">
                            <h4 className="font-weight-bold mb-2">
                              {i18n.t("registration")}
                            </h4>
                            <p className="text-muted mb-0">
                              {i18n.t("register_message")}
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              )}

              <div className="col-lg-6 col-md-6 col-sm-6">
                <div className="card p-5">
                  <div className="mb-lg-0 mb-4">
                    <Link to="/login">
                      <div className="service-card text-center">
                        <div className="bg-light icon-bg icon-service text-purple about box-shadow2">
                          <img
                            src="../assets/images/home/login_promise.svg"
                            alt="img"
                          />
                        </div>
                        <div className="servic-data mt-3">
                          <h4 className="font-weight-bold mb-2">
                            {i18n.t("login")}
                          </h4>
                          <p className="text-muted mb-0">
                            {i18n.t("login_message")}
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>

              {/* <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="">
                  <div className="mb-sm-0 mb-4">
                    <Link to="/login">
                      <div className="service-card text-center">
                        <div className="bg-light icon-bg icon-service text-purple about box-shadow2">
                          <img
                            src="../assets/images/home/enroll_rewards.svg"
                            alt="img"
                          />
                        </div>
                        <div className="servic-data mt-3">
                          <h4 className="font-weight-bold mb-2">
                            {i18n.t("enroll")}
                          </h4>
                          <p className="text-muted mb-0">
                             Nam libero tempore, cum soluta nobis est eligendi
                          cumque facere possimus 
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6 col-sm-6">
                <div className="">
                  <div className="">
                    <Link to="/login">
                      <div className="service-card text-center">
                        <div className="bg-light icon-bg icon-service text-purple about box-shadow2">
                          <img
                            src="../assets/images/home/bid_transfer.svg"
                            alt="img"
                          />
                        </div>
                        <div className="servic-data mt-3">
                          <h4 className="font-weight-bold mb-2">
                            {i18n.t("bid")}
                          </h4>
                          <p className="text-muted mb-0">
                            Nam libero tempore, cum soluta nobis est eligendi
                          cumque facere possimus
                          </p>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
