import React from "react";
import { getMyBanner } from "../../models/banner";
import { Spinner } from "react-bootstrap";

//import i18n from "../../i18n";
const lng = localStorage.getItem("I18N_LANGUAGE");
export class HomeBanner extends React.Component {
  constructor() {
    super();
    this.state = {
      banner_loading: false,
      banner: "",
    };
  }

  componentDidMount() {
    this.getBanner();
  }

  async getBanner() {
    this.setState({ banner_loading: true });
    const response = await getMyBanner(1);
    if (response.success) {
      this.setState({
        banner: lng === "ar" ? response.data.arabic : response.data.english,
      });
      this.setState({ banner_loading: false });
    }
  }

  render() {
    let { banner } = this.state;
    return (
      <React.Fragment>
        {this.state.banner_loading && (
          <div
            className="m-auto text-center mb-5 mt-5 text-danger"
            style={{ paddingTop: "10rem" }}
          >
            <Spinner animation="grow" size="" />
            <Spinner animation="grow" size="" />
            <Spinner animation="grow" size="" />
          </div>
        )}
        {!this.state.banner_loading && (
          <section>
            <div
              className="banner-2 cover-image sptb-2 sptb-tab bg-background2"
              data-image-src={banner}
              // style={{ background: "url('assets/images/banners/oman.jpeg')" }}
            >
              <div className="header-text mb-0">
                <img src={banner} alt="" />
                {/* <div className="container">
                <div className="text-center text-white">
                  <h1 className="mb-1">{i18n.t("banner_title")}</h1>
                   <p>
                    It is a long established fact that a reader will be
                    distracted by the when looking at its layout.
                  </p>
                </div>
              </div> */}
              </div>
              {/* /header-text */}
            </div>
          </section>
        )}
      </React.Fragment>
    );
  }
}
