import React from "react";
import { SideBar } from "./sidebar";
import i18n from "../../i18n";
import dashboardData from "../../models/dashboard";
import { Spinner } from "react-bootstrap";
import { getAllGroups } from "../../models/groups";
let lang = localStorage.getItem("I18N_LANGUAGE");

export class DashboardAdmin extends React.Component {
  constructor() {
    super();
    this.state = {
      input: {},
      is_loading: false,
      hide_menu: false,
      data: [],
      groups: {},
    };
    this.handleChange = this.handleChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadAllGroups();
    this.loadData();
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
    if (event.target.name === "group") {
      this.setState({ input });
      this.loadData();
    }
  }

  async loadAllGroups() {
    const response = await getAllGroups();
    let groups = response.data;
    this.setState({ groups });
  }

  async loadData() {
    this.setState({ is_loading: true });
    let input = this.state.input;
    const response = await dashboardData(input);
    if (response) {
      this.setState({ data: response.data });
      this.setState({ is_loading: false });
    }
  }

  render() {
    let groups = this.state.groups;
    let data = this.state.data;
    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image bg-background3"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-white">
                  <h1 className="">{i18n.t("dashboard")}</h1>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="mt-5 mb-9 pb-1">
          <div className="container">
            <div className="row">
              <div
                className={
                  this.state.hide_menu
                    ? "d-none"
                    : "col-xl-3 col-lg-12 col-md-12"
                }
              >
                <SideBar />
              </div>
              <div
                className={
                  this.state.hide_menu
                    ? "col-xl-12 col-lg-12 col-md-12"
                    : "col-xl-9 col-lg-12 col-md-12"
                }
              >
                <div className="card-header mb-5">
                  <h3 className="card-title">
                    <span
                      onClick={() => {
                        this.setState({ hide_menu: !this.state.hide_menu });
                      }}
                    >
                      <i
                        class={
                          this.state.hide_menu
                            ? "fa fa-arrow-right"
                            : "fa fa-arrow-left"
                        }
                        aria-hidden="true"
                      ></i>
                      &nbsp;
                    </span>
                    {i18n.t("dashboard")}
                  </h3>

                  <div
                    className="form-group"
                    style={
                      lang === "ar"
                        ? {
                            position: "absolute",
                            left: "2rem",
                            top: "1rem",
                            width: "10rem",
                          }
                        : {
                            position: "absolute",
                            right: "2rem",
                            top: "1rem",
                            width: "10rem",
                          }
                    }
                  >
                    <select
                      className="form-control"
                      data-placeholder="Select Class"
                      name="group"
                      value={this.state.input.group}
                      onChange={this.handleChange}
                    >
                      <option value="">--{i18n.t("group")}--</option>
                      {groups.length > 0 &&
                        groups.map((item) => {
                          return (
                            <option value={item.id} key={item.id}>
                              {item.group_name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>

                <div className="row mb-9">
                  <div className="col-xl-4 col-lg-4">
                    <div
                      className="card flex-row"
                      style={{ background: "#644dac", color: "#FFF" }}
                    >
                      <div className="card-icon-custom p-1">
                        <img src="assets/dashboard/1.png" alt="" />
                      </div>
                      <div className="card-body-custom pt-5">
                        <div className="mb-4">
                          <h5 className="card-title mb-0 text-capitalize">
                            <b>Auction Numbers</b>
                          </h5>
                        </div>
                        <div className="row align-items-center mb-2 d-flex">
                          <div className="col-12">
                            <h4 className="d-flex align-items-center mb-0">
                              {this.state.is_loading && (
                                <Spinner animation="border" size="sm" />
                              )}
                              {!this.state.is_loading && data.auction_numbers}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ends -1 */}

                  <div className="col-xl-4 col-lg-4">
                    <div
                      className="card flex-row"
                      style={{ background: "#3199dd", color: "#FFF" }}
                    >
                      <div className="card-icon-custom p-1">
                        <img src="assets/dashboard/2.png" alt="" />
                      </div>
                      <div className="card-body-custom pt-5">
                        <div className="mb-4">
                          <h5 className="card-title mb-0 text-capitalize">
                            <b>Active Bidders</b>
                          </h5>
                        </div>
                        <div className="row align-items-center mb-2 d-flex">
                          <div className="col-12">
                            <h4 className="d-flex align-items-center mb-0">
                              {this.state.is_loading && (
                                <Spinner animation="border" size="sm" />
                              )}
                              {!this.state.is_loading && data.active_bidders}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ends -2 */}

                  <div className="col-xl-4 col-lg-4">
                    <div
                      className="card flex-row"
                      style={{ background: "#30c6d3", color: "#FFF" }}
                    >
                      <div className="card-icon-custom p-1">
                        <img src="assets/dashboard/3.png" alt="" />
                      </div>
                      <div className="card-body-custom pt-5">
                        <div className="mb-4">
                          <h5 className="card-title mb-0 text-capitalize">
                            <b>Total Bids</b>
                          </h5>
                        </div>
                        <div className="row align-items-center mb-2 d-flex">
                          <div className="col-12">
                            <h4 className="d-flex align-items-center mb-0">
                              {this.state.is_loading && (
                                <Spinner animation="border" size="sm" />
                              )}
                              {!this.state.is_loading && data.total_bidders}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ends -3 */}

                  <div className="col-xl-4 col-lg-4">
                    <div
                      className="card flex-row"
                      style={{ background: "#c5a045", color: "#FFF" }}
                    >
                      <div className="card-icon-custom p-1">
                        <img src="assets/dashboard/4.png" alt="" />
                      </div>
                      <div className="card-body-custom pt-5">
                        <div className="mb-4">
                          <h5 className="card-title mb-0 text-capitalize">
                            <b>Max Bid Amount</b>
                          </h5>
                        </div>
                        <div className="row align-items-center mb-2 d-flex">
                          <div className="col-12">
                            <h4 className="d-flex align-items-center mb-0">
                              {this.state.is_loading && (
                                <Spinner animation="border" size="sm" />
                              )}
                              {!this.state.is_loading && data.max_bid_amount}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ends -4 */}

                  <div className="col-xl-4 col-lg-4">
                    <div
                      className="card flex-row"
                      style={{ background: "#e84e5b", color: "#FFF" }}
                    >
                      <div className="card-icon-custom p-1">
                        <img src="assets/dashboard/5.png" alt="" />
                      </div>
                      <div className="card-body-custom pt-5">
                        <div className="mb-4">
                          <h5 className="card-title mb-0 text-capitalize">
                            <b>Number in Bidding</b>
                          </h5>
                        </div>
                        <div className="row align-items-center mb-2 d-flex">
                          <div className="col-12">
                            <h4 className="d-flex align-items-center mb-0">
                              {this.state.is_loading && (
                                <Spinner animation="border" size="sm" />
                              )}
                              {!this.state.is_loading && data.number_in_bidding}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ends -5 */}

                  <div className="col-xl-4 col-lg-4">
                    <div
                      className="card flex-row"
                      style={{ background: "#9042af", color: "#FFF" }}
                    >
                      <div className="card-icon-custom p-1">
                        <img src="assets/dashboard/6.png" alt="" />
                      </div>
                      <div className="card-body-custom pt-5">
                        <div className="mb-4">
                          <h5 className="card-title mb-0 text-capitalize">
                            <b>Total Amount No Bidding</b>
                          </h5>
                        </div>
                        <div className="row align-items-center mb-2 d-flex">
                          <div className="col-12">
                            <h4 className="d-flex align-items-center mb-0">
                              {this.state.is_loading && (
                                <Spinner animation="border" size="sm" />
                              )}
                              {!this.state.is_loading &&
                                data.total_amount_no_bidding}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ends -6 */}

                  <div className="col-xl-4 col-lg-4">
                    <div
                      className="card flex-row"
                      style={{ background: "#30c6d3", color: "#FFF" }}
                    >
                      <div className="card-icon-custom p-1">
                        <img src="assets/dashboard/7.png" alt="" />
                      </div>
                      <div className="card-body-custom pt-5">
                        <div className="mb-4">
                          <h5 className="card-title mb-0 text-capitalize">
                            <b>Total Awarded Winners</b>
                          </h5>
                        </div>
                        <div className="row align-items-center mb-2 d-flex">
                          <div className="col-12">
                            <h4 className="d-flex align-items-center mb-0">
                              {this.state.is_loading && (
                                <Spinner animation="border" size="sm" />
                              )}
                              {!this.state.is_loading && data.awarding_list}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ends -7 */}

                  <div className="col-xl-4 col-lg-4">
                    <div
                      className="card flex-row"
                      style={{ background: "#007430", color: "#FFF" }}
                    >
                      <div className="card-icon-custom p-1">
                        <img src="assets/dashboard/8.png" alt="" />
                      </div>
                      <div className="card-body-custom pt-5">
                        <div className="mb-4">
                          <h5 className="card-title mb-0 text-capitalize">
                            <b>Auction Allocated Number</b>
                          </h5>
                        </div>
                        <div className="row align-items-center mb-2 d-flex">
                          <div className="col-12">
                            <h4 className="d-flex align-items-center mb-0">
                              {this.state.is_loading && (
                                <Spinner animation="border" size="sm" />
                              )}
                              {!this.state.is_loading &&
                                data.auction_allocated_number}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ends -8 */}

                  <div className="col-xl-4 col-lg-4">
                    <div
                      className="card flex-row"
                      style={{ background: "#3199dd", color: "#FFF" }}
                    >
                      <div className="card-icon-custom p-1">
                        <img src="assets/dashboard/9.png" alt="" />
                      </div>
                      <div className="card-body-custom pt-5">
                        <div className="mb-4">
                          <h5 className="card-title mb-0 text-capitalize">
                            <b>Max Allocated Amount</b>
                          </h5>
                        </div>
                        <div className="row align-items-center mb-2 d-flex">
                          <div className="col-12">
                            <h4 className="d-flex align-items-center mb-0">
                              {this.state.is_loading && (
                                <Spinner animation="border" size="sm" />
                              )}
                              {!this.state.is_loading &&
                                data.max_allocated_amount}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ends -9 */}

                  <div className="col-xl-4 col-lg-4">
                    <div
                      className="card flex-row"
                      style={{ background: "#e84e5b", color: "#FFF" }}
                    >
                      <div className="card-icon-custom p-1">
                        <img src="assets/dashboard/10.png" alt="" />
                      </div>
                      <div className="card-body-custom pt-5">
                        <div className="mb-4">
                          <h5 className="card-title mb-0 text-capitalize">
                            <b>Total Allocated Number</b>
                          </h5>
                        </div>
                        <div className="row align-items-center mb-2 d-flex">
                          <div className="col-12">
                            <h4 className="d-flex align-items-center mb-0">
                              {this.state.is_loading && (
                                <Spinner animation="border" size="sm" />
                              )}
                              {!this.state.is_loading &&
                                data.total_allocated_number}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* ends -10 */}
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
