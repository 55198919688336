import { apiUrl } from "../constants/global";
import { getLoggedUser } from "../state/user";
const user = getLoggedUser();

async function createBanner(payload) {
  return fetch(apiUrl + "banners", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function getAllBanners() {
  return fetch(apiUrl + "banners", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getBanner(id) {
  return fetch(apiUrl + "banners/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getMyBanner(id) {
  return fetch(apiUrl + "get_my_banner?id=" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((data) => data.json());
}

async function updateBanner(payload, id) {
  return fetch(apiUrl + "banners/" + id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

function deleteBanner(id) {
  return fetch(apiUrl + "banners/" + id, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

export {
  createBanner,
  getAllBanners,
  getBanner,
  updateBanner,
  deleteBanner,
  getMyBanner,
};
