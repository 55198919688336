import React from "react";
import { Spinner } from "react-bootstrap";
import { AuctionRow } from "../helpers/auctionRow";
import i18n from "../../i18n";
import { getActiveAuctions } from "../../models/auctions";
import { getLoggedUser } from "../../state/user";

let is_logged = localStorage.getItem("is_logged_in") ? true : false;
const user = getLoggedUser();
export class AuctionListing extends React.Component {
  constructor() {
    super();
    this.state = {
      auctions: {},
      limit: 30,
      meta: {},
      loading: false,
    };
  }

  componentDidMount() {
    this.loadAllAuctions(this.state.limit);
  }

  async loadAllAuctions(limit) {
    let q = "";
    if (limit) {
      q = "limit=" + limit;
    }
    this.setState({ loading: true });
    const response = await getActiveAuctions(q);
    let auctions = response.data;
    let meta = response.meta;
    if (auctions) {
      this.setState({ auctions: auctions, meta, loading: false });
    }
  }

  render() {
    let auctions = this.state.auctions;
    let total_auctions = this.state.meta.total;
    let limit = this.state.limit;
    let lt_30_cls =
      limit === 30
        ? "btn btn-primary btn-sm font-1vw"
        : "btn btn-light btn-sm font-1vw";
    let lt_50_cls =
      limit === 50
        ? "btn btn-primary btn-sm font-1vw"
        : "btn btn-light btn-sm font-1vw";
    let lt_100_cls =
      limit === 100
        ? "btn btn-primary btn-sm font-1vw"
        : "btn btn-light btn-sm font-1vw";
    let lt_200_cls =
      limit === 200
        ? "btn btn-primary btn-sm font-1vw"
        : "btn btn-light btn-sm font-1vw";
    let lt_all_cls =
      limit === false
        ? "btn btn-primary btn-sm font-1vw"
        : "btn btn-light btn-sm font-1vw";

    let is_enrolled = this.props.is_enrolled;
    let user_role = user && user.role;
    if (user_role === "A" || user_role === "VA") {
      is_enrolled = true;
    }
    return (
      <React.Fragment>
        {this.state.loading && (
          <div className="p-5 m-9">
            <div className="text-center text-dark">
              <Spinner animation="grow" variant="danger" size="lg" />
            </div>
          </div>
        )}

        {auctions.length > 0 && !this.state.loading && (
          <div className={is_logged ? "container pt-5" : "container"}>
            <div
              className={
                is_logged
                  ? "section-title center-block text-center active-auc-title"
                  : "section-title center-block text-center"
              }
            >
              <h2 className="text-primary position-relative">
                {i18n.t("active_auctions")}
              </h2>
              {/* <p className="text-primary-50 position-relative">
              {i18n.t("message_auction_start")}
            </p> */}
            </div>
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className=" mb-0">
                  <div className="">
                    <div className="item2-gl ">
                      <div className=" mb-0">
                        <div className="">
                          <div className="bg-white p-5 item2-gl-nav">
                            {total_auctions <= 30 && (
                              <div className="d-flex">
                                <label
                                  className="font-1vw"
                                  style={{
                                    float: "left",
                                    width: "auto",
                                    margin: "auto",
                                  }}
                                >
                                  <div className="table-top-text">
                                    {i18n.t("showing")} {total_auctions}{" "}
                                    {i18n.t("auctions")}
                                  </div>
                                </label>
                              </div>
                            )}

                            {total_auctions > 30 && (
                              <div className="d-sm-flex">
                                <label
                                  className="font-1vw"
                                  style={{
                                    float: "left",
                                    width: "auto",
                                    margin: "10px 0px",
                                  }}
                                >
                                  {!this.state.limit && (
                                    <div
                                      className="table-top-text"
                                      style={{ marginRight: "5px" }}
                                    >
                                      {i18n.t("showing")} {total_auctions}{" "}
                                      {i18n.t("auctions")}{" "}
                                    </div>
                                  )}
                                  {this.state.limit && (
                                    <div
                                      className="table-top-text"
                                      style={{ marginRight: "5px" }}
                                    >
                                      {i18n.t("showing")} 1 {i18n.t("to")}{" "}
                                      {this.state.limit} {i18n.t("of")}{" "}
                                      {total_auctions} {i18n.t("entries")}{" "}
                                    </div>
                                  )}
                                </label>
                                <button
                                  className={lt_30_cls}
                                  onClick={() => {
                                    this.setState({ limit: 30 });
                                    this.loadAllAuctions(30);
                                  }}
                                >
                                  30
                                </button>
                                <button
                                  className={lt_50_cls}
                                  onClick={() => {
                                    this.setState({ limit: 50 });
                                    this.loadAllAuctions(50);
                                  }}
                                >
                                  50
                                </button>
                                <button
                                  className={lt_100_cls}
                                  onClick={() => {
                                    this.setState({ limit: 100 });
                                    this.loadAllAuctions(100);
                                  }}
                                >
                                  100
                                </button>
                                <button
                                  className={lt_200_cls}
                                  onClick={() => {
                                    this.setState({ limit: 200 });
                                    this.loadAllAuctions(200);
                                  }}
                                >
                                  200
                                </button>
                                <button
                                  className={lt_all_cls}
                                  onClick={() => {
                                    this.setState({ limit: false });
                                    this.loadAllAuctions();
                                  }}
                                >
                                  All
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>

                      <div className="table-responsive">
                        <div className="bg-white p-5 mb-9">
                          <div className="table-responsive-sm table-condensed">
                            {auctions.length > 0 && (
                              <table className="table table-sm table-striped table-primary font-1vw">
                                <thead>
                                  <tr>
                                    <th className="font-1vw">
                                      {i18n.t("slno")}
                                    </th>
                                    <th className="font-1vw">
                                      {i18n.t("number")}
                                    </th>
                                    <th
                                      className="font-1vw"
                                      style={{ textAlign: "center" }}
                                      width="150"
                                    >
                                      {i18n.t("package")}
                                    </th>
                                    {is_enrolled && (
                                      <th className="font-1vw">
                                        {i18n.t("current_amount")}
                                      </th>
                                    )}
                                    {/* {is_enrolled && (
                                      <th className="font-1vw">
                                        {i18n.t("total")}
                                      </th>
                                    )} */}
                                    {/* <th className="d-none d-md-block">
                                    {i18n.t("start_date")}
                                  </th> */}
                                    {is_logged && is_enrolled && (
                                      <th className="font-1vw">
                                        {i18n.t("days_left")}
                                        &nbsp;
                                        {"(D:H:M:S)"}
                                      </th>
                                    )}
                                    {is_logged && is_enrolled && (
                                      <th className="font-1vw">
                                        {i18n.t("bid_count")}
                                      </th>
                                    )}
                                    {is_logged && <th></th>}
                                  </tr>
                                </thead>
                                <tbody>
                                  {auctions.map((item, i) => {
                                    return (
                                      <AuctionRow
                                        key={item.id}
                                        index={i}
                                        item={item}
                                        is_enrolled={is_enrolled}
                                        user_role={user_role}
                                      >
                                        {user_role}
                                      </AuctionRow>
                                    );
                                  })}
                                </tbody>
                              </table>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}
