import React, { useState } from "react";
import { Link } from "react-router-dom";
import { SocialSignin } from "./socialSignin";
import swal from "sweetalert";
import { loginUser } from "../../models/login";
import i18n from "../../i18n";

export default function Login() {
  const [username, setUserName] = useState();
  const [password, setPassword] = useState();
  const [passwordType, setPasswordState] = useState("password");
  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await loginUser({
      username,
      password,
    });

    if ("token" in response.data) {
      swal(i18n.t("success"), i18n.t("user_signed_in"), "success", {
        buttons: false,
        timer: 2000,
      })
        .then((value) => {
          localStorage.setItem("is_logged_in", true);
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("mzad_user_info", JSON.stringify(response.data));
          if (response.data.role === "A" || response.data.role === "VA") {
            window.location.href = "/dashboard";
          } else {
            window.location.href = "/";
          }
        })
        .catch((response) => {
          swal("Failed", response.message, "error");
        });
    } else {
      swal("Failed", response.message, "error");
    }
  };

  //render() {
  return (
    <div>
      <section>
        <div
          className="bannerimg cover-image bg-background3"
          data-image-src="../assets/images/banners/banner2.jpg"
        >
          <div className="header-text mb-0">
            <div className="container">
              <div className="text-center text-white">
                <h1 className="">{i18n.t("login")}</h1>
                <ol className="breadcrumb text-center">
                  <li className="breadcrumb-item">
                    <Link exact="true" to="/">
                      {i18n.t("home")}
                    </Link>
                  </li>
                  <li
                    className="breadcrumb-item active text-white"
                    aria-current="page"
                  >
                    {i18n.t("login")}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="sptb">
        <div className="container customerpage">
          <div className="row">
            <div className="single-page">
              <div className="col-lg-5 col-xl-5 col-md-6 d-block mx-auto">
                <div className="wrapper wrapper2">
                  <form
                    id="login"
                    className="card-body"
                    onSubmit={handleSubmit}
                  >
                    <h3>{i18n.t("login")}</h3>

                    <div className="mail">
                      <input
                        type="text"
                        name="username"
                        onChange={(e) => setUserName(e.target.value)}
                      />
                      <label>{i18n.t("username_or_email")}</label>
                    </div>
                    <div class="input-group mb-3">
                      <input
                        type={passwordType}
                        name="password"
                        class="form-control"
                        onChange={(e) => setPassword(e.target.value)}
                      />
                      <span
                        style={{
                          position: "absolute",
                          right: "1rem",
                          color: "#000",
                          width: "20px",
                          top: "1rem",
                        }}
                        onClick={(e) => {
                          if (passwordType === "password") {
                            setPasswordState("text");
                          } else {
                            setPasswordState("password");
                          }
                        }}
                      >
                        <i
                          class={
                            passwordType === "password"
                              ? "fa fa-eye"
                              : "fa fa-eye-slash"
                          }
                          id="togglePassword"
                          style={{ cursor: "pointer" }}
                        ></i>
                      </span>
                      <label>{i18n.t("password")}</label>
                    </div>
                    <div className="submit">
                      <button
                        type="submit"
                        className="btn btn-primary btn-block"
                      >
                        {i18n.t("login")}
                      </button>
                    </div>

                    <div className="submit">
                      <Link to="/login_otp" className="text-primary ms-1">
                        {i18n.t("login_using_otp")}
                      </Link>
                    </div>

                    <p className="mb-2">
                      <Link to="/forgotPassword" className="text-info ms-1">
                        {i18n.t("forget_password")}
                      </Link>
                    </p>
                    {/* <p className="text-dark mb-0">
                      {i18n.t("donthaveaccount")}
                      <Link to="/registration" className="text-dark ms-1">
                        {i18n.t("registration")}
                      </Link>
                    </p> */}
                  </form>
                  <SocialSignin />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
  //}
}
