import React from "react";
//import { Link } from "react-router-dom";
import { enrollUser } from "../models/auctions";
import { getLoggedUser } from "../state/user";
import i18n from "../i18n";
import swal from "sweetalert";
import { createThawaniCustomer, createThawaniSession } from "../models/thawani";
import { Spinner } from "react-bootstrap";
import {
  thawaniPaymentUrl,
  thawaniConfig,
  paymentSuccessUrl,
  paymentCancelledUrl,
} from "../constants/global";
import logoThawani from "../logoThawani.svg";

//let is_logged = localStorage.getItem("is_logged_in") ? true : false;
const user = getLoggedUser();
export class Enroll extends React.Component {
  constructor() {
    super();
    let path = window.location.pathname;
    let auctionId = path.substring(path.lastIndexOf("/") + 1, path.length);
    this.state = {
      input: {
        name: user.name,
        identity_type: i18n.t("civil_card"),
      },
      t_c_acept: false,
      payment: {},
      auction: auctionId,
      is_payment: false,
      civilCard: null,
      signature: null,
      is_payment_loading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handlePayment = this.handlePayment.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.saveEnroll = this.saveEnroll.bind(this);
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  handlePayment(event) {
    let payment = this.state.payment;
    payment[event.target.name] = event.target.value;
    this.setState({ payment });
  }

  onFileChange = (event) => {
    if (event.target.name === "civilCard") {
      this.setState({ civilCard: event.target.files[0] });
    }
    if (event.target.name === "signature") {
      this.setState({ signature: event.target.files[0] });
    }
  };

  async saveEnroll(event) {
    event.preventDefault();

    if (
      !this.state.auction ||
      !this.state.input.name ||
      !this.state.input.identity_type ||
      !this.state.input.civil_id_number ||
      !this.state.civilCard ||
      !this.state.signature
    ) {
      swal("Failed", "All fields are mandatory. Please fill them.", "error");
      return;
    }

    const formData = new FormData();
    formData.append("auction", this.state.auction);
    formData.append("enroll_name", this.state.input.name);
    formData.append("identity_type", this.state.input.identity_type);
    formData.append("civil_id_number", this.state.input.civil_id_number);
    formData.append(
      "civilCard",
      this.state.civilCard,
      this.state.civilCard.name
    );
    formData.append(
      "signature",
      this.state.signature,
      this.state.signature.name
    );

    this.setState({ is_payment_loading: true });
    const response = await enrollUser(formData);
    if (response.success) {
      this.createCustomer(response.data);
      //this.setState({ is_payment: true });
    } else {
      if (response.data && response.data.error) {
        swal("Failed", response.message, "error");
        this.setState({ is_payment_loading: false });
        return;
      }
    }
  }

  async gotoPayment(customer_id, enroll) {
    let payload = {
      client_reference_id: user.email,
      mode: "payment",
      products: [
        {
          name: "Vodafone Auction",
          quantity: 1,
          unit_amount: 10000,
        },
      ],
      success_url: paymentSuccessUrl + enroll.group_id,
      cancel_url: paymentCancelledUrl,
      customer_id: customer_id,
      metadata: {
        "Customer name": user.name,
        "Order id": enroll.id,
        user: user.id,
        group: this.state.auction,
      },
    };

    let thw = await createThawaniSession(payload);
    this.setState({ is_payment_loading: false });

    if (thw && thw.data && thw.success && thw.data.session_id) {
      let session_id = thw.data.session_id;
      localStorage.setItem("invoice", thw.data.invoice);
      window.location.href =
        thawaniPaymentUrl +
        session_id +
        "?key=" +
        thawaniConfig.publishable_key;
    }
  }

  async createCustomer(enroll) {
    if (user && (user.email || user.username)) {
      let thw = await createThawaniCustomer({
        client_customer_id: user.email,
      });
      console.log(thw);
      if (thw && thw.data) {
        localStorage.setItem("thawani_customer_id", thw.data.id);
        this.gotoPayment(thw.data.id, enroll);
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image bg-background3"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-white">
                  <h1>{i18n.t("enroll")}</h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <a href={true}>{i18n.t("home")}</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href={true}>{i18n.t("auctions")}</a>
                    </li>
                    <li
                      className="breadcrumb-item active text-white"
                      aria-current="page"
                    >
                      {i18n.t("enroll")}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sptb">
          <div className="container">
            <div className="row">
              <div className="col-xl-8">
                <div className="card mb-xl-0">
                  <div className="card-header">
                    {!this.state.is_payment && (
                      <h3 className="card-title">{i18n.t("enroll")}</h3>
                    )}
                    {this.state.is_payment && (
                      <h3 className="card-title">{i18n.t("payment")}</h3>
                    )}
                  </div>
                  <div className="card-body">
                    <form
                      id="enrollForm"
                      method="get"
                      className="form-horizontal mb-0"
                    >
                      <div
                        id="rootwizard"
                        className="border pt-0"
                        style={{ overflow: "hidden" }}
                      >
                        <div className="card-body mt-3 mb-0 b-0">
                          {/** Enroll Section */}
                          {!this.state.is_payment && (
                            <div className="" id="first">
                              <div className="control-group form-group">
                                <div className="form-group">
                                  <label
                                    className="form-label text-dark"
                                    style={{ textAlign: "left" }}
                                  >
                                    {i18n.t("name")}{" "}
                                    <span className="text text-danger">
                                      (*)
                                    </span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control required Title"
                                    placeholder=""
                                    value={this.state.input.name}
                                    name="name"
                                    onChange={this.handleChange}
                                  />
                                </div>
                              </div>
                              {/* <div className="control-group form-group">
                                <div className="form-group">
                                  <label
                                    className="form-label text-dark"
                                    style={{ textAlign: "left" }}
                                  >
                                    {i18n.t("identity_type")}{" "}
                                    <span className="text text-danger">
                                      (*)
                                    </span>
                                  </label>
                                  <select
                                    className="form-control form-select required category"
                                    value={this.state.input.identity_type}
                                    name="identity_type"
                                    onChange={this.handleChange}
                                  >
                                    <option value={i18n.t("civil_card")}>
                                      {i18n.t("civil_card")}
                                    </option>
                                  </select>
                                </div>
                              </div> */}
                              <div className="control-group form-group">
                                <div className="form-group">
                                  <label
                                    className="form-label text-dark"
                                    style={{ textAlign: "left" }}
                                  >
                                    {i18n.t("civil_id_number")}{" "}
                                    <span className="text text-danger">
                                      (*)
                                    </span>
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control required Title"
                                    placeholder={i18n.t("civil_id_number")}
                                    value={this.state.input.civil_id_number}
                                    name="civil_id_number"
                                    onChange={this.handleChange}
                                  />
                                </div>
                              </div>
                              <div className="form-group">
                                <div className="form-group">
                                  <label
                                    for="form-label"
                                    className="form-label"
                                    style={{ textAlign: "left" }}
                                  >
                                    {i18n.t("attacth_civil_card")}{" "}
                                    <span className="text text-danger">
                                      (*)
                                    </span>
                                  </label>
                                  <input
                                    className="form-control "
                                    type="file"
                                    placeholder=""
                                    name="civilCard"
                                    onChange={this.onFileChange}
                                  />
                                  <small class="form-text text-warning">
                                    {i18n.t("iphone_msg_1")}
                                  </small>
                                </div>
                              </div>
                              <div className="form-group">
                                <div className="form-group">
                                  <label
                                    for="form-label"
                                    className="form-label"
                                    style={{ textAlign: "left" }}
                                  >
                                    {i18n.t("signature")}{" "}
                                    <span className="text text-danger">
                                      (*)
                                    </span>
                                  </label>
                                  <input
                                    className="form-control "
                                    type="file"
                                    placeholder=""
                                    name="signature"
                                    onChange={this.onFileChange}
                                  />
                                  <small class="form-text text-warning">
                                    {i18n.t("iphone_msg_1")}
                                  </small>
                                </div>
                              </div>

                              <div className="form-group">
                                <div className="form-group">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    placeholder=""
                                    name="t_c"
                                    checked={this.state.t_c_acept}
                                    onChange={(event) => {
                                      this.setState({
                                        t_c_acept: !this.state.t_c_acept,
                                      });
                                    }}
                                  />{" "}
                                  <span>
                                    Please accept{" "}
                                    <a href="/terms_payment" target="_blank">
                                      {i18n.t("terms_conditions_payment")}
                                    </a>
                                    <span className="text text-danger">
                                      (*)
                                    </span>
                                  </span>
                                </div>
                              </div>

                              <div className="form-group row clearfix">
                                <div className="text-center mb-3">
                                  <span>
                                    <img src={logoThawani} alt="" height={30} />
                                  </span>
                                  <span>
                                    submitting this form will redirect to
                                    Thawani payment services
                                  </span>
                                </div>
                                <div className="text-center mb-3">
                                  <button
                                    className="btn btn-info  mb-0 me-2 text-white"
                                    onClick={this.saveEnroll}
                                    disabled={!this.state.t_c_acept}
                                  >
                                    Save & Continue to Payment
                                  </button>
                                </div>

                                {this.state.is_payment_loading && (
                                  <div
                                    className="text-center mt-3"
                                    style={{ textAlign: "center" }}
                                  >
                                    <Spinner animation="border" />
                                  </div>
                                )}
                              </div>
                            </div>
                          )}
                          {/** Enroll section ends */}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-xl-4">
                {/* <img src={vodaBanner} alt="" /> */}
                {/* <Terms /> */}
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
