import React from "react";
import { Link } from "react-router-dom";
import { SideBar } from "./sidebar";
import swal from "sweetalert";
import {
  getAllMasks,
  createMask,
  deleteMask,
  maskClasses,
} from "../../models/masks";
import i18n from "../../i18n";
export class Masks extends React.Component {
  constructor() {
    super();
    this.state = {
      input: {},
      errors: {},
      masks: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadAllMasks();
  }

  async loadAllMasks() {
    const response = await getAllMasks();
    let masks = response.data;
    this.setState({ masks });
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    console.log(event.target.name + ", " + event.target.value);
    this.setState({ input });
  }
  handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await createMask(this.state.input);
      if (response && response.data && response.data.id) {
        swal("Success", response.message, "success", {
          buttons: false,
          timer: 2000,
        }).catch((response) => {
          swal("Failed", response.message, "error");
        });
        this.loadAllMasks();
      } else {
        let error_message = response.message;
        let resObject = response.data;
        for (const key in resObject) {
          if (Object.hasOwnProperty.call(resObject, key)) {
            let element = resObject[key];
            error_message += "\n" + element;
          }
        }
        swal("Failed", error_message, "error");
      }
    } catch (err) {
      console.log(err);
    }
  };

  deleteRow(id) {
    const response = deleteMask(id);
    console.log(response);
    swal("Success", "Item deleted", "success", {
      buttons: false,
      timer: 2000,
    })
      .then((response) => {
        this.loadAllMasks();
      })
      .catch((response) => {
        swal("Failed", "Something went wrong", "error");
      });
  }

  render() {
    let masks = this.state.masks;
    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image bg-background3"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-white">
                  <h1 className="">{i18n.t("dashboard")}</h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <Link to="/">{i18n.t("home")}</Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-white"
                      aria-current="page"
                    >
                      {i18n.t("dashboard")}
                    </li>
                    <li
                      className="breadcrumb-item active text-white"
                      aria-current="page"
                    >
                      {i18n.t("masks")}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <SideBar />
              </div>
              <div className="col-xl-9 col-lg-12 col-md-12">
                <form
                  id="UpdateProfile"
                  className="card mb-0"
                  autoComplete="off"
                  onSubmit={this.handleSubmit}
                >
                  <div className="card-header">
                    <h3 className="card-title">Create Mask</h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12 col-md-3">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("mask_name")}
                          </label>
                          <input
                            type="text"
                            name="mask_name"
                            className="form-control"
                            placeholder="Mask Name"
                            value={this.state.input.mask_name}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-3">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("package")}
                          </label>
                          <select
                            className="form-control"
                            data-placeholder="Select Class"
                            name="class"
                            value={this.state.input.class}
                            onChange={this.handleChange}
                          >
                            <option value="">--{i18n.t("package")}--</option>
                            <option value="D">Diamond</option>
                            <option value="G">Gold</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("price")}
                          </label>
                          <input
                            type="text"
                            name="start_amount"
                            className="form-control"
                            placeholder="Amount"
                            value={this.state.input.start_amount}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-2">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("incr_amount")}
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Amount"
                            name="bid_increment"
                            value={this.state.input.bid_increment}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-2">
                        <div className="form-group">
                          <label className="form-label">&nbsp;</label>
                          <button type="submit" className="btn btn-primary">
                            Create Mask
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

                <hr />

                <div className="card mb-0">
                  <div className="card-header">
                    <h3 className="card-title">Masks</h3>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive ">
                      {masks.length < 1 && <div>No masks found!!!</div>}
                      {masks.length > 0 && (
                        <table className="table table-bordered table-hover text-nowrap">
                          <thead>
                            <tr>
                              <th>{i18n.t("mask_name")}</th>
                              <th>{i18n.t("package")}</th>
                              <th>{i18n.t("price")}</th>
                              <th>{i18n.t("incr_amount")}</th>
                              <th>{i18n.t("edit")}</th>
                              <th>{i18n.t("delete")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {masks.map((item, i) => {
                              return (
                                <tr>
                                  <td>{item.mask_name}</td>
                                  <td>{maskClasses[item.class]}</td>
                                  <td className="font-weight-semibold fs-16">
                                    OMR {item.start_amount}
                                  </td>
                                  <td>OMR {item.bid_increment}</td>
                                  <td>
                                    <Link
                                      className="btn btn-warning btn-sm text-white m-1"
                                      to={"/edit_mask/" + item.id}
                                    >
                                      <i className="fa fa-pencil"></i>&nbsp;
                                      {i18n.t("edit")}
                                    </Link>
                                  </td>
                                  <td>
                                    {item.can_delete && (
                                      <button
                                        className="btn btn-danger btn-sm text-white m-1"
                                        onClick={(e) => {
                                          this.deleteRow(item.id);
                                        }}
                                      >
                                        <i className="fa fa-times"></i>&nbsp;
                                        {i18n.t("delete")}
                                      </button>
                                    )}
                                    {!item.can_delete && (
                                      <small class="text text-danger">
                                        Linked to an auction. Can't delete.
                                      </small>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
