import { apiUrl } from "../constants/global";
import { getLoggedUser } from "../state/user";
const user = getLoggedUser();

async function createSetting(payload) {
  return fetch(apiUrl + "settings", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function getAllSettings() {
  return fetch(apiUrl + "settings", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getSetting(id) {
  return fetch(apiUrl + "settings/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getSettingsByName(name) {
  return fetch(apiUrl + "get_settings?name=" + name, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((data) => data.json());
}

async function updateSetting(payload, id) {
  return fetch(apiUrl + "settings/" + id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

function deleteSetting(id) {
  return fetch(apiUrl + "settings/" + id, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

export {
  createSetting,
  getAllSettings,
  getSetting,
  getSettingsByName,
  updateSetting,
  deleteSetting,
};
