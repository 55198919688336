import React from "react";
import { Link } from "react-router-dom";
import { deleteUser } from "../models/register";

export class PaymentCancelled extends React.Component {
  constructor(props) {
    super(props);
    let path = window.location.pathname;
    let groupId = path.substring(path.lastIndexOf("/") + 1, path.length);
    this.state = {
      group_id: groupId,
      error: false,
      error_msg: "",
    };
  }

  async componentDidMount() {
    let data = JSON.parse(localStorage.getItem("mzad_reg_info"));
    let userID = data && data.user.id;
    let token = data && data.token;
    if (userID && token) {
      const response = await deleteUser({ token, id: userID });
      if (!response.success) {
        this.setState({ error: true, error_msg: response.message });
      }
      if (response.success) {
      }
    }
  }

  render() {
    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image bg-background3 sptb-2"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-white ">
                  <h1 className="">Payment Cancelled</h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <Link exact="true" to="/">
                        Home
                      </Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-white"
                      aria-current="page"
                    >
                      <Link exact="true" to="/enroll">
                        Enroll
                      </Link>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb">
          <div className="container">
            <div className="container text-center">
              <div className="display-1 mb-5"></div>
              <h1 className="h2 mb-3">Payment Cancelled</h1>
              <p className="h4 font-weight-Automatic mb-8 leading-Automatic">
                Oops!!!! Your payment process is cancelled
              </p>
              <Link className="btn btn-warning" to="/">
                Back To Home
              </Link>
              <p className="h4 font-weight-Automatic mb-8 leading-Automatic">
                Plase try once again with registration.
              </p>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
