import React from "react";

//import i18n from './i18n';
//import loader from "./loader.svg";
import logo from "./logo.png";
import "./App.css";
import { Footer } from "./components/footer/footer";
import { Topbar } from "./components/navigation/topbar";
import { checkBlackList } from "./models/users";
import { checkAuctionAvailability } from "./models/groups";

const isAndroid = navigator.userAgent.toLowerCase().indexOf("android") !== -1;
const isGoogleBot =
  navigator.userAgent.toLowerCase().indexOf("googlebot") !== -1;
const isIE = /*@cc_on!@*/ false || !!document.documentMode;
const isEdge = !isIE && !!window.StyleMedia;
const isFirefox = typeof InstallTrigger !== "undefined";
const isOpera =
  (!!window.opr && !!window.opr.addons) ||
  !!window.opera ||
  navigator.userAgent.indexOf(" OPR/") >= 0;
const isChrome =
  !isGoogleBot &&
  !isEdge &&
  !isOpera &&
  !!window.chrome &&
  (!!window.chrome.webstore ||
    navigator.vendor.toLowerCase().indexOf("google inc.") !== -1);
const isSafari =
  !isChrome && navigator.userAgent.toLowerCase().indexOf("safari") !== -1;

function getLoggedUser() {
  let userInfo = localStorage.getItem("mzad_user_info");
  userInfo = JSON.parse(userInfo);
  return userInfo && userInfo.length > 0 ? userInfo : false;
}
class App extends React.Component {
  constructor() {
    super();
    this.state = {
      have_active_registrations: false,
      active_group: [],
    };
  }

  async componentDidMount() {
    if (isSafari) {
      document.body.classList.add("safari");
    }

    let is_logged = localStorage.getItem("is_logged_in") ? true : false;
    if (is_logged) {
      const response = await checkBlackList();
      let blackList =
        response && response.data && response.data.blacklist ? true : false;
      if (blackList) {
        localStorage.clear();
        window.location.href = "/";
      }
    }

    const aucAvailabily = await checkAuctionAvailability();
    let have_active_registrations =
      aucAvailabily &&
      aucAvailabily.data &&
      aucAvailabily.data.have_active_registrations
        ? true
        : false;
    if (have_active_registrations) {
      let active_group =
        aucAvailabily && aucAvailabily.data && aucAvailabily.data.group;
      this.setState({ active_group: active_group });
    }
    this.setState({ have_active_registrations: have_active_registrations });
  }

  render() {
    let rootClass = isSafari ? "App safari" : "App";
    let user = getLoggedUser() || false;
    let is_logged = localStorage.getItem("is_logged_in") ? true : false;
    let have_active_registrations = this.state.have_active_registrations;
    return (
      <div className={rootClass}>
        <div id="global-loader">
          <img src={logo} className="loader-img" alt="" />
        </div>
        <Topbar
          is_logged={is_logged}
          user={user}
          have_active_registrations={have_active_registrations}
          active_group={this.state.active_group}
        />
        <Footer />
      </div>
    );
  }
}
export default App;
