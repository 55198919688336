import { thawaniUrl, thawaniConfig } from "../constants/global";
import axios from "axios";

async function createThawaniCustomer(payload) {
  try {
    let config = {
      headers: {
        "Content-Type": "application/json",
        "thawani-api-key": thawaniConfig["thawani-api-key"],
      },
    };
    const { data: response } = await axios.post(
      thawaniUrl + "customers",
      payload,
      config
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

async function createThawaniSession(payload) {
  try {
    let config = {
      headers: {
        "Content-Type": "application/json",
        "thawani-api-key": thawaniConfig["thawani-api-key"],
      },
    };
    const { data: response } = await axios.post(
      thawaniUrl + "checkout/session",
      payload,
      config
    );
    return response;
  } catch (error) {
    console.log(error);
  }
}

export { createThawaniCustomer, createThawaniSession };
