import React from "react";
import { Link } from "react-router-dom";
import i18n from "../../i18n";

export class WhoWeAre extends React.Component {
  render() {
    return (
      <div>
        <section>
          <div
            className="bannerimg cover-image bg-background3 sptb-2"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-white ">
                  <h1 className="">{i18n.t("aboutus")}</h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <Link exact="true" to="/">
                        {i18n.t("home")}
                      </Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-white"
                      aria-current="page"
                    >
                      {i18n.t("aboutus")}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb">
          <div className="container mb-8">
            <div className="text-justify">
              <h2 className="mb-7">{i18n.t("about_connect")}</h2>
              <p className="leading-Automatic">{i18n.t("about_para_1")}</p>
              <p className="leading-Automatic">{i18n.t("about_para_2")}</p>
              <p className="leading-Automatic">{i18n.t("about_para_3")}</p>
              <p className="leading-Automatic mb-7">{i18n.t("about_para_4")}</p>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
