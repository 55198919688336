//Production
//const apiUrl = "";

//Test
const ENV_TEST = "test";
const ENV_DEVELOPMENT = "dev";
const ENV_PRODUCTION = "production";

let env = ENV_TEST;
let apiUrl = "";
let thawaniUrl = "";
let thawaniConfig = {};
let paymentSuccessUrl = "";
let paymentCancelledUrl = "";
let thawaniPaymentUrl = "https://uatcheckout.thawani.om/pay/";
//Test Environment
if (env === ENV_TEST) {
  apiUrl = "https://test-vodafone.mzadcom.om/services/public/api/";
  // thawaniUrl = "https://uatcheckout.thawani.om/api/v1/";
  // thawaniConfig = {
  //   "thawani-api-key": "rRQ26GcsZzoEhbrP2HZvLYDbn9C9et",
  //   publishable_key: "HGvTMLDssJghr9tlN9gr4DVYt0qyBy",
  // };
  thawaniUrl = "https://uatcheckout.thawani.om/api/v1/";
  thawaniConfig = {
    "thawani-api-key": "rRQ26GcsZzoEhbrP2HZvLYDbn9C9et",
    publishable_key: "HGvTMLDssJghr9tlN9gr4DVYt0qyBy",
  };
  paymentSuccessUrl = "https://test-vodafone.mzadcom.om/payment-success/";
  paymentCancelledUrl = "https://test-vodafone.mzadcom.om/payment-cancelled";
}

//Development Environment
if (env === ENV_DEVELOPMENT) {
  apiUrl = "http://localhost/auction-api/public/api/";
  thawaniUrl = "https://uatcheckout.thawani.om/api/v1/";
  thawaniConfig = {
    "thawani-api-key": "rRQ26GcsZzoEhbrP2HZvLYDbn9C9et",
    publishable_key: "HGvTMLDssJghr9tlN9gr4DVYt0qyBy",
  };
  paymentSuccessUrl = "http://localhost:3000/payment-success/";
  paymentCancelledUrl = "http://localhost:3000/payment-cancelled";
}

//Production Environment
if (env === ENV_PRODUCTION) {
  apiUrl = "https://vodafone.mzadcom.om/services/public/api/";
  thawaniUrl = "https://checkout.thawani.om/api/v1/";
  thawaniConfig = {
    "thawani-api-key": "SQeBH2iJiRsRS2r4yPClP1mHO1UkPH",
    publishable_key: "R0YzWOnB1HdUKZmiiQqnjuNJjscETk",
  };
  paymentSuccessUrl = "https://vodafone.mzadcom.om/payment-success/";
  paymentCancelledUrl = "https://vodafone.mzadcom.om/payment-cancelled";
  thawaniPaymentUrl = "https://checkout.thawani.om/pay/";
}

export {
  apiUrl,
  thawaniUrl,
  thawaniConfig,
  thawaniPaymentUrl,
  paymentSuccessUrl,
  paymentCancelledUrl,
};
