import React from "react";
import { Link } from "react-router-dom";
import { SideBar } from "./sidebar";
import getUser from "../../models/getUser";
import updateUser from "../../models/updateUser";
import swal from "sweetalert";
import i18n from "../../i18n";

export class Profile extends React.Component {
  constructor() {
    super();
    this.state = {
      input: {},
      errors: {},
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    const response = await getUser();
    let user = response.data;
    this.setState({ input: user });
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  handleSubmit = async (event) => {
    event.preventDefault();
    if (!this.validate()) {
      return;
    }

    try {
      const response = await updateUser(this.state.input);
      if ("id" in response.data) {
        swal("Success", response.message, "success", {
          buttons: false,
          timer: 2000,
        })
          .then((value) => {
            localStorage.setItem("token", response.data.token);
            localStorage.setItem(
              "mzad_user_info",
              JSON.stringify(response.data)
            );
          })
          .catch((response) => {
            swal("Failed", response.message, "error");
          });
      } else {
        let error_message = response.message;
        let resObject = response.data;
        for (const key in resObject) {
          if (Object.hasOwnProperty.call(resObject, key)) {
            let element = resObject[key];
            error_message += "\n" + element;
          }
        }
        swal("Failed", error_message, "error");
      }
    } catch (err) {
      console.log(err);
    }
  };

  validate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;
    if (!input["name"]) {
      isValid = false;
      errors["name"] = "Please enter your Name.";
      swal("Failed", errors["name"], "error");
      return isValid;
    }
    if (!input["username"]) {
      isValid = false;
      errors["username"] = "Please enter a valid Username.";
      swal("Failed", errors["username"], "error");
      return isValid;
    }
    // if (!input["country_code"]) {
    //   isValid = false;
    //   errors["country_code"] = "Please enter phone country code.";
    //   swal("Failed", errors["country_code"], "error");
    //   return isValid;
    // }
    if (!input["mobile"]) {
      isValid = false;
      errors["mobile"] = "Please enter your Phone Number.";
      swal("Failed", errors["mobile"], "error");
      return isValid;
    }

    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (!input["email"] || !pattern.test(input["email"])) {
      isValid = false;
      errors["email"] = "Please enter valid email address.";
      swal("Failed", errors["email"], "error");
      return isValid;
    }
    this.setState({
      errors: errors,
    });
    return isValid;
  }

  render() {
    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image bg-background3"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-white">
                  <h1 className="">{i18n.t("dashboard")}</h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <Link to="/">{i18n.t("home")}</Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-white"
                      aria-current="page"
                    >
                      {i18n.t("dashboard")}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <SideBar />
              </div>
              <div className="col-xl-9 col-lg-12 col-md-12">
                <form
                  id="UpdateProfile"
                  className="card mb-0"
                  autoComplete="off"
                  onSubmit={this.handleSubmit}
                >
                  <div className="card-header">
                    <h3 className="card-title">{i18n.t("edit_profile")}</h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">{i18n.t("name")}</label>
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            placeholder={i18n.t("name")}
                            value={this.state.input.name}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("username")}
                          </label>
                          <input
                            type="text"
                            name="username"
                            className="form-control"
                            placeholder={i18n.t("username")}
                            value={this.state.input.username}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("email")}
                          </label>
                          <input
                            type="email"
                            className="form-control"
                            placeholder={i18n.t("email")}
                            name="email"
                            value={this.state.input.email}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("phone_number")}
                          </label>
                          <input
                            type="number"
                            name="mobile"
                            className="form-control"
                            placeholder={i18n.t("phone_number")}
                            value={this.state.input.mobile}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("password")}
                          </label>
                          <input
                            type="text"
                            name="password_new"
                            className="form-control"
                            placeholder={i18n.t("password")}
                            value={this.state.input.password_new}
                            onChange={this.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card-footer" style={{ textAlign: "right" }}>
                    <button type="submit" className="btn btn-success">
                      {i18n.t("submit")}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
