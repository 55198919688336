import React from "react";
import { Link } from "react-router-dom";
import { SideBar } from "./sidebar";
import {
  deleteUser,
  getUsers,
  activateUser,
  deActivateUser,
} from "../../models/users";
import DataTable from "react-data-table-component";
import i18n from "../../i18n";
import swal from "sweetalert";
import { Spinner } from "react-bootstrap";

const columns = [
  {
    name: "ID",
    selector: "userId",
    sortable: true,
    wrap: true,
    width: "100px",
  },
  {
    name: i18n.t("name"),
    selector: "name",
    sortable: true,
  },
  {
    name: i18n.t("username"),
    selector: "username",
    sortable: true,
  },
  {
    name: i18n.t("email"),
    selector: "email",
  },
  {
    name: i18n.t("mobile"),
    selector: "mobile",
    wrap: true,
    cell: (row) => row.country_code + " " + row.mobile,
  },
  {
    name: i18n.t("Blacklisted"),
    button: true,
    cell: (row) => (
      <span
        className={
          row.is_blacklisted ? "badge badge-danger" : "badge badge-success"
        }
      >
        {row.is_blacklisted ? "Yes" : "No"}
      </span>
    ),
  },
  {
    name: i18n.t("activation"),
    button: true,
    cell: (row) => (
      <button
        className={row.is_deactivate ? "btn btn-success" : "btn btn-warning"}
        onClick={(e) => {
          row.is_deactivate ? activate(row.id) : deActivate(row.id);
        }}
      >
        {row.is_deactivate ? i18n.t("Enable") : i18n.t("Disable")}
      </button>
    ),
  },
  {
    name: i18n.t("delete"),
    button: true,
    cell: (row) => (
      <button
        className="btn btn-danger"
        onClick={(e) => {
          deleteRow(row.id);
        }}
      >
        {i18n.t("delete")}
      </button>
    ),
  },
];

const customStyles = {
  headCells: {
    style: {
      fontWeight: "bold",
      backgroundColor: "#e60000",
      color: "#FFFFFF",
    },
  },
};

function deleteRow(id) {
  const response = deleteUser(id);
  console.log(response);
  swal("Success", "User deleted", "success", {
    buttons: false,
    timer: 2000,
  })
    .then((response) => {
      window.location.reload();
    })
    .catch((response) => {
      swal("Failed", "Something went wrong", "error");
    });
}

function activate(id) {
  const response = activateUser({ user: id });
  swal("Success", "User activated", "success", {
    buttons: false,
    timer: 2000,
  })
    .then((response) => {
      window.location.reload();
    })
    .catch((response) => {
      swal("Failed", "Something went wrong", "error");
    });
}

function deActivate(id) {
  const response = deActivateUser({ user: id });
  swal("Success", "User de-activated", "success", {
    buttons: false,
    timer: 2000,
  })
    .then((response) => {
      window.location.reload();
    })
    .catch((response) => {
      swal("Failed", "Something went wrong", "error");
    });
}
export class UsersList extends React.Component {
  constructor() {
    super();
    this.state = {
      totalRows: 0,
      currentPage: 1,
      input: {
        page: 1,
        limit: 15,
      },
      query: "",
      auctions: {},
      is_loading: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let input = this.state.input;
    let qstring = new URLSearchParams(input).toString();
    this.setState({ query: qstring });
    this.loadAllUsers(qstring);
  }

  async loadAllUsers(q) {
    this.setState({ is_loading: true });
    const response = await getUsers(q);
    let users = response.data;
    let meta = response.meta;
    if (users) {
      this.setState({ is_loading: false });
      this.setState({ auctions: users });
      this.setState({ totalRows: meta.total });
    }
  }

  handlePageChange = (page) => {
    let input = this.state.input;
    let currentPage = page;
    input["page"] = currentPage;
    this.setState({ currentPage: currentPage });
    this.setState({ input: input });
    let qstring = new URLSearchParams(input).toString();
    this.setState({ query: qstring });
    this.loadAllUsers(qstring);
    console.log(page);
  };

  render() {
    let auctions = this.state.auctions;
    console.log(auctions);
    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image bg-background3"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-white">
                  <h1 className="">{i18n.t("dashboard")}</h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <Link to="/">{i18n.t("home")}</Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-white"
                      aria-current="page"
                    >
                      {i18n.t("dashboard")}
                    </li>
                    <li
                      className="breadcrumb-item active text-white"
                      aria-current="page"
                    >
                      {i18n.t("auctions")}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <SideBar />
              </div>
              <div className="col-xl-9 col-lg-12 col-md-12">
                <div className="card mb-0">
                  <div className="card-header">
                    <h3 className="card-title">{i18n.t("users")}</h3>
                  </div>
                  <div className="card-body">
                    {this.state.is_loading && (
                      <div className="text-center">
                        <Spinner animation="border" size="lg" />
                      </div>
                    )}

                    <div className="table-responsive ">
                      {auctions.length > 0 && (
                        <DataTable
                          title=""
                          fixedHeader
                          columns={columns}
                          data={auctions}
                          customStyles={customStyles}
                          highlightOnHover
                          pagination
                          paginationServer
                          paginationTotalRows={this.state.totalRows}
                          paginationPerPage={15}
                          paginationDefaultPage={this.state.currentPage}
                          paginationRowsPerPageOptions={[15, 25, 50, 100]}
                          paginationComponentOptions={{
                            rowsPerPageText: "Users per page:",
                            rangeSeparatorText: "out of",
                          }}
                          onChangePage={this.handlePageChange}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
