import React from "react";
import { Link } from "react-router-dom";
import { registerAndEnroll } from "../../models/register";
import swal from "sweetalert";
//import contryData from "../../constants/country.json";
import i18n from "../../i18n";
import {
  thawaniPaymentUrl,
  thawaniConfig,
  paymentSuccessUrl,
  paymentCancelledUrl,
} from "../../constants/global";
import {
  createThawaniCustomer,
  createThawaniSession,
} from "../../models/thawani";
import logoThawani from "../../logoThawani.svg";
import { Spinner } from "react-bootstrap";

const lng = localStorage.getItem("I18N_LANGUAGE");
//const codeData = [...contryData];
export class Registration extends React.Component {
  constructor() {
    super();
    let path = window.location.pathname;
    let groupID = path.substring(path.lastIndexOf("/") + 1, path.length);
    this.state = {
      passwordType: "password",
      is_payment_loading: false,
      group: groupID,
      saving: false,
      input: {
        group: groupID,
        country_code: "+968",
        identity_type: i18n.t("civil_card"),
      },
      t_c_acept: false,
      errors: {},
      user: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handlePayment = this.handlePayment.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
  }
  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({
      input,
    });
  }

  async handleSubmit(event) {
    event.preventDefault();
    if (!this.validate()) {
      return;
    }

    this.setState({ saving: true });
    let formData = new FormData();
    formData.append("group", this.state.input.group);
    formData.append("name", this.state.input.name);
    formData.append("username", this.state.input.username);
    formData.append("country_code", this.state.input.country_code);
    formData.append("mobile", this.state.input.mobile);
    formData.append("email", this.state.input.email);
    formData.append("password", this.state.input.password);
    formData.append("confirm_password", this.state.input.confirm_password);
    formData.append("civil_id_number", this.state.input.civil_id_number);
    formData.append("identity_type", this.state.input.identity_type);
    formData.append(
      "civilCard",
      this.state.civilCard,
      this.state.civilCard.name
    );
    formData.append(
      "signature",
      this.state.signature,
      this.state.signature.name
    );

    this.setState({ is_payment_loading: true });
    const response = await registerAndEnroll(formData);
    console.log(response);
    if (response.success) {
      this.setState({ saving: false });
      this.createCustomer(response.data);
      localStorage.setItem("mzad_reg_info", JSON.stringify(response.data));
    } else {
      this.setState({ saving: false });
      let error_message = response.message;
      let resObject = response.data;
      for (const key in resObject) {
        if (Object.hasOwnProperty.call(resObject, key)) {
          let element = resObject[key];
          error_message += "\n" + element;
        }
      }
      swal("Failed", error_message, "error");
    }
  }

  handlePayment(event) {
    let payment = this.state.payment;
    payment[event.target.name] = event.target.value;
    this.setState({ payment });
  }

  onFileChange = (event) => {
    if (event.target.name === "civilCard") {
      this.setState({ civilCard: event.target.files[0] });
    }
    if (event.target.name === "signature") {
      this.setState({ signature: event.target.files[0] });
    }
  };

  async gotoPayment(customer_id, enroll) {
    let payload = {
      client_reference_id: this.state.user.email,
      mode: "payment",
      products: [
        {
          name: "Vodafone Auction",
          quantity: 1,
          unit_amount: 10000,
        },
      ],
      success_url: paymentSuccessUrl + enroll.group_id,
      cancel_url: paymentCancelledUrl,
      customer_id: customer_id,
      metadata: {
        "Customer name": this.state.user.name,
        "Order id": enroll.id,
        user: this.state.user.id,
        group: this.state.auction,
      },
    };

    let thw = await createThawaniSession(payload);
    this.setState({ is_payment_loading: false });

    if (thw && thw.data && thw.success && thw.data.session_id) {
      let session_id = thw.data.session_id;
      localStorage.setItem("invoice", thw.data.invoice);
      window.location.href =
        thawaniPaymentUrl +
        session_id +
        "?key=" +
        thawaniConfig.publishable_key;
    }
  }

  async createCustomer(enroll) {
    if (enroll.user && enroll.user.email) {
      let thw = await createThawaniCustomer({
        client_customer_id: enroll.user.email,
      });

      if (thw && thw.data) {
        localStorage.setItem("thawani_customer_id", thw.data.id);
        this.gotoPayment(thw.data.id, enroll);
      }
    }
  }

  resetForm() {
    let input = {};
    input["name"] = "";
    input["mobile"] = "";
    input["email"] = "";
    input["username"] = "";
    input["country_code"] = "+968";
    input["password"] = "";
    input["confirm_password"] = "";
    input["civil_id_number"] = "";
    this.setState({ input: input });
  }

  testMobile(phoneNumber) {
    let reg =
      "^(+|00)(9[976]d|8[987530]d|6[987]d|5[90]d|42d|3[875]d|2[98654321]d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)(s?d){9}$";
    var OK = reg.match(phoneNumber);
    if (!OK) {
      window.alert("phone number isn't  valid");
    } else {
      window.alert("phone number is  valid");
    }
  }

  validate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;
    if (!input["name"]) {
      isValid = false;
      errors["name"] = "Please enter your Name.";
      swal("Failed", errors["name"], "error");
      return isValid;
    }
    if (!input["username"]) {
      isValid = false;
      errors["username"] = "Please enter a valid Username.";
      swal("Failed", errors["username"], "error");
      return isValid;
    }
    if (!input["country_code"]) {
      isValid = false;
      errors["country_code"] = "Please enter phone country code.";
      swal("Failed", errors["country_code"], "error");
      return isValid;
    }
    if (!input["mobile"]) {
      isValid = false;
      errors["mobile"] = "Please enter your Phone Number.";
      swal("Failed", errors["mobile"], "error");
      return isValid;
    }

    var pattern = new RegExp(
      /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
    );

    if (!input["email"] || !pattern.test(input["email"])) {
      isValid = false;
      errors["email"] = "Please enter valid email address.";
      swal("Failed", errors["email"], "error");
      return isValid;
    }
    if (!input["password"]) {
      isValid = false;
      errors["password"] = "Please enter your Password.";
      swal("Failed", errors["password"], "error");
      return isValid;
    }
    if (!input["confirm_password"]) {
      isValid = false;
      errors["confirm_password"] = "Please enter Confirm Password.";
      swal("Failed", errors["confirm_password"], "error");
      return isValid;
    }
    if (input["password"] !== input["confirm_password"]) {
      isValid = false;
      errors["confirm_password"] = "Password didn't match.";
      swal("Failed", errors["confirm_password"], "error");
      return isValid;
    }
    if (!this.state.civilCard) {
      isValid = false;
      errors["civilCard"] = "Please upload your civil card.";
      swal("Failed", errors["civilCard"], "error");
      return isValid;
    }
    if (!input["civil_id_number"]) {
      isValid = false;
      errors["civil_id_number"] = "Please enter civil id number.";
      swal("Failed", errors["civil_id_number"], "error");
      return isValid;
    }
    if (!this.state.signature) {
      isValid = false;
      errors["signature"] = "Please upload your signature.";
      swal("Failed", errors["signature"], "error");
      return isValid;
    }
    this.setState({
      errors: errors,
    });
    return isValid;
  }

  render() {
    let lang = localStorage.getItem("I18N_LANGUAGE");
    return (
      <div>
        <section>
          <div
            className="bannerimg cover-image bg-background3 sptb-2"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-white ">
                  <h1 className="">{i18n.t("registration")}</h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <Link exact="true" to="/">
                        {i18n.t("home")}
                      </Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-white"
                      aria-current="page"
                    >
                      {i18n.t("registration")}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sptb">
          <div className="container customerpage">
            <div className="row">
              <div className="single-page">
                <div className="col-lg-6 col-xl-6 col-md-6 d-block mx-auto">
                  <div className="wrapper wrapper2">
                    <form id="Register" className="card-body" tabindex="500">
                      <h3>{i18n.t("registration")}</h3>
                      <div className="name">
                        <input
                          type="text"
                          name="name"
                          value={this.state.input.name}
                          onChange={this.handleChange}
                          autoComplete="name"
                        />
                        <label>{i18n.t("name")}</label>
                        <div className="text-danger">
                          {this.state.errors.name}
                        </div>
                      </div>

                      <div className="input-group input-group-sm mt-2">
                        <select
                          className="form-control"
                          data-placeholder="Select Country"
                          style={{
                            height: "50px",
                            padding: "5px 15px",
                            border: "solid 1px #d8dde6",
                            zIndex: 0,
                          }}
                          name="country_code_dis"
                          value={this.state.input.country_code}
                          onChange={this.handleChange}
                          disabled={true}
                        >
                          <option value="">--Country--</option>
                          <option value={"+968"}>{i18n.t("oman")}</option>
                          {/* {codeData.map((item, i) => {
                            return (
                              <option value={item.dial_code}>
                                {item.name}
                              </option>
                            );
                          })} */}
                        </select>
                        <input
                          type="hidden"
                          value={this.state.input.country_code}
                          name="country_code"
                        />
                        <input
                          type="text"
                          className="form-control col-9"
                          style={{ "z-index": 0 }}
                          name="mobile"
                          value={this.state.input.mobile}
                          onChange={this.handleChange}
                        />
                        <label>{i18n.t("phone_number")}</label>
                        <div className="text-danger">
                          {this.state.errors.mobile}
                        </div>
                      </div>
                      <div className="email">
                        <input
                          type="text"
                          name="email"
                          value={this.state.input.email}
                          onChange={this.handleChange}
                        />
                        <label>{i18n.t("email")}</label>
                        <div className="text-danger">
                          {this.state.errors.email}
                        </div>
                      </div>

                      <div className="username">
                        <input
                          type="text"
                          name="username"
                          value={this.state.input.username}
                          onChange={this.handleChange}
                          autoComplete="username"
                        />
                        <label>{i18n.t("username")}</label>
                        <div className="text-danger">
                          {this.state.errors.username}
                        </div>
                      </div>

                      <div className="passwd">
                        <input
                          type={this.state.passwordType}
                          name="password"
                          value={this.state.input.password}
                          onChange={this.handleChange}
                        />
                        <span
                          style={{
                            position: "absolute",
                            left: lng === "ar" ? "1rem" : "auto",
                            right: lng === "ar" ? "auto" : "1rem",
                            color: "#000",
                            width: "20px",
                            top: "1rem",
                          }}
                          onClick={(e) => {
                            if (this.state.passwordType === "password") {
                              this.setState({ passwordType: "text" });
                            } else {
                              this.setState({ passwordType: "password" });
                            }
                          }}
                        >
                          <i
                            class={
                              this.state.passwordType === "password"
                                ? "fa fa-eye"
                                : "fa fa-eye-slash"
                            }
                            id="togglePassword"
                            style={{ cursor: "pointer" }}
                          ></i>
                        </span>
                        <label>{i18n.t("password")}</label>
                        <div className="text-danger">
                          {this.state.errors.password}
                        </div>
                      </div>
                      <div className="confirm_password">
                        <input
                          type="password"
                          name="confirm_password"
                          value={this.state.input.confirm_password}
                          onChange={this.handleChange}
                        />
                        <label>{i18n.t("confirm_password")}</label>
                        <div className="text-danger">
                          {this.state.errors.confirm_password}
                        </div>
                      </div>

                      {/* <div className="form-group">
                        <select
                          className="form-control form-select required category"
                          value={this.state.input.identity_type}
                          name="identity_type"
                          onChange={this.handleChange}
                          style={{ height: "50px" }}
                        >
                          <option value={i18n.t("civil_card")}>
                            {i18n.t("civil_card")}
                          </option>
                        </select>
                        <label className="">
                          {i18n.t("identity_type")}{" "}
                          <span className="text text-danger">(*)</span>
                        </label>
                        <div className="text-danger">
                          {this.state.errors.identity_type}
                        </div>
                      </div> */}

                      <div className="civil_id_number">
                        <input
                          type="text"
                          name="civil_id_number"
                          value={this.state.input.civil_id_number}
                          onChange={this.handleChange}
                        />
                        <label>
                          {i18n.t("civil_id_number")}{" "}
                          <span className="text text-danger">(*)</span>
                        </label>
                        <div className="text-danger">
                          {this.state.errors.civil_id_number}
                        </div>
                      </div>

                      <div className="form-group">
                        <input
                          className="form-control "
                          type="file"
                          placeholder=""
                          name="civilCard"
                          onChange={this.onFileChange}
                          style={{ height: "45px", lineHeight: "2.3rem" }}
                        />
                        <label
                          for="form-label"
                          className="form-label"
                          style={{ textAlign: "left" }}
                        >
                          {i18n.t("attacth_civil_card")}{" "}
                          <span className="text text-danger">(*)</span>
                        </label>
                        <small
                          class="form-text text-warning"
                          style={{ textAlign: lng === "ar" ? "right" : "left" }}
                        >
                          {i18n.t("iphone_msg_1")}
                        </small>
                        <div className="text-danger">
                          {this.state.errors.civilCard}
                        </div>
                      </div>

                      <div className="form-group">
                        <input
                          className="form-control "
                          type="file"
                          placeholder=""
                          name="signature"
                          onChange={this.onFileChange}
                          style={{ height: "45px", lineHeight: "2.3rem" }}
                        />
                        <label
                          for="form-label"
                          className="form-label"
                          style={{ textAlign: "left" }}
                        >
                          {i18n.t("signature")}{" "}
                          <span className="text text-danger">(*)</span>
                        </label>
                        <small
                          class="form-text text-warning"
                          style={{ textAlign: lng === "ar" ? "right" : "left" }}
                        >
                          {i18n.t("iphone_msg_1")}
                        </small>
                        <div className="text-danger">
                          {this.state.errors.signature}
                        </div>
                      </div>

                      <div className="form-group row clearfix">
                        <div className="text-center mb-3">
                          <span>
                            <img src={logoThawani} alt="" height={30} />
                          </span>
                          <span className="text-dark">
                            {i18n.t("thawani_message")}
                          </span>
                        </div>

                        {this.state.is_payment_loading && (
                          <div
                            className="text-center mt-3 text-danger"
                            style={{ textAlign: "center" }}
                          >
                            <Spinner animation="border" />
                          </div>
                        )}
                      </div>

                      <div
                        style={{ textAlign: lang === "ar" ? "right" : "left" }}
                      >
                        <input
                          style={{
                            height: "15px",
                            padding: "0",
                            width: "15px",
                          }}
                          className="form-check-input"
                          type="checkbox"
                          name="t_c"
                          checked={this.state.t_c_acept}
                          onChange={(event) => {
                            this.setState({
                              t_c_acept: !this.state.t_c_acept,
                            });
                          }}
                        />{" "}
                        <span className="text text-dark">
                          {i18n.t("please_accept")}{" "}
                          <a href="/terms" target="_new">
                            {i18n.t("terms_conditions")}
                          </a>
                        </span>
                      </div>

                      <button
                        type="button"
                        className="btn btn-primary btn-block"
                        disabled={!this.state.t_c_acept}
                        onClick={this.handleSubmit}
                      >
                        {i18n.t("continue")}
                      </button>

                      <p className="text-dark mb-0">
                        {i18n.t("alreadyhaveaccount")}
                        <Link to="/login"> {i18n.t("login")} </Link>
                      </p>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
