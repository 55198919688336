import React from "react";
import { Link } from "react-router-dom";
import { SideBar } from "./sidebar";
import {
  createBulkAuction,
  bulkAuctionCreateFromUpload,
} from "../../models/auctions";
import { getAllMasks, getMask } from "../../models/masks";
import { getAllGroups, getGroup } from "../../models/groups";
import swal from "sweetalert";
import i18n from "../../i18n";
//import RichTextEditor from 'react-rte';

export class CreateAuction extends React.Component {
  constructor() {
    super();
    this.state = {
      bulk_create: false,
      auctions: null,
      saving: false,
      input: {
        //description: RichTextEditor.createEmptyValue(),
      },
      row: [
        {
          phone_number: "",
          class: "",
          start_amount: "",
          bid_increment: "",
          increment_numbers: "",
          start_date: "",
          end_date: "",
        },
      ],
      tempArray: {},
      errors: {},
      masks: {},
      groups: {},
      enquiries: [{ label: "", value: "" }],
      jsonLabel: "",
      jsonValue: "",
      //value: RichTextEditor.createEmptyValue(),
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleRowChange = this.handleRowChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.appendData = this.appendData.bind(this);
    this.removeEnquiryData = this.removeEnquiryData.bind(this);
    this.setEnquiryJson = this.setEnquiryJson.bind(this);
    this.onFileChange = this.onFileChange.bind(this);
    this.bulkUpload = this.bulkUpload.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadAllMasks();
    this.loadAllGroups();
  }

  async loadAllMasks() {
    const response = await getAllMasks();
    let masks = response.data;
    this.setState({ masks });
  }

  async loadAllGroups() {
    const response = await getAllGroups();
    let groups = response.data;
    this.setState({ groups });
  }

  async loadMask(selected_mask) {
    const response = await getMask(selected_mask);
    let result = response.data;
    let arr = this.state.row;
    for (let i = 0; i < arr.length; i++) {
      arr[i]["class"] = result.class;
      arr[i]["start_amount"] = result.start_amount;
      arr[i]["bid_increment"] = result.bid_increment;
      //arr[i]["increment_numbers"] = result.increment_numbers;
    }
    let tempArray = this.state.tempArray;
    tempArray["class"] = result.class;
    tempArray["start_amount"] = result.start_amount;
    tempArray["bid_increment"] = result.bid_increment;
    //tempArray["increment_numbers"] = result.increment_numbers;
    this.setState({ row: arr });
  }

  async loadGroup(selected_group) {
    const response = await getGroup(selected_group);
    let result = response.data;
    let arr = this.state.row;
    for (let i = 0; i < arr.length; i++) {
      arr[i]["start_date"] = result.start_date;
      arr[i]["end_date"] = result.end_date;
      arr[i]["description"] = result.description;
      arr[i]["terms"] = result.terms;
      arr[i]["terms_arabic"] = result.terms_arabic;
      arr[i]["enquiry"] = result.enquiry;
      arr[i]["increment_numbers"] = result.increment_numbers;
    }

    let tempArray = this.state.tempArray;
    tempArray["start_date"] = result.start_date;
    tempArray["end_date"] = result.end_date;
    tempArray["description"] = result.description;
    tempArray["terms"] = result.terms;
    tempArray["terms_arabic"] = result.terms_arabic;
    tempArray["enquiry"] = result.enquiry;
    tempArray["increment_numbers"] = result.increment_numbers;
    this.setState({ row: arr, tempArray });
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
    if (event.target.name === "mask") {
      this.loadMask(event.target.value);
    }
    if (event.target.name === "group") {
      this.loadGroup(event.target.value);
    }
  }

  handleRowChange(event, i) {
    let row = this.state.row;
    row[i][event.target.name] = event.target.value;
    this.setState({ row });
  }

  onFileChange = (event) => {
    if (event.target.name === "auctions") {
      this.setState({ auctions: event.target.files[0] });
    }
  };

  handleSubmit = async (event) => {
    event.preventDefault();
    try {
      let payload = this.state.input;
      payload.rows = this.state.row;
      payload.enquiries = this.state.enquiries;
      const response = await createBulkAuction(payload);
      if (response && response.data && response.data.success) {
        swal("Success", response.message, "success", {
          buttons: false,
          timer: 2000,
        })
          .then((value) => {
            window.location.href = "";
          })
          .catch((response) => {
            swal("Failed", response.message, "error");
          });
      } else {
        let error_message = response.message;
        let resObject = response.data;
        for (const key in resObject) {
          if (Object.hasOwnProperty.call(resObject, key)) {
            let element = resObject[key];
            error_message += "\n" + element;
          }
        }
        swal("Failed", error_message, "error");
      }
    } catch (err) {
      console.log(err);
    }
  };

  appendData(e) {
    e.preventDefault();
    let row = this.state.row;
    let tempArray = this.state.tempArray;
    row.push({
      phone_number: tempArray.phone_number,
      class: tempArray.class,
      start_amount: tempArray.start_amount,
      bid_increment: tempArray.bid_increment,
      increment_numbers: tempArray.increment_numbers,
      start_date: tempArray.start_date,
      end_date: tempArray.end_date,
      description: tempArray.description,
      terms: tempArray.terms,
      terms_arabic: tempArray.terms_arabic,
      enquiry: tempArray.enquiry,
    });
    this.setState({ row });
  }

  removeEnquiryData(key) {
    let enquiries = this.state.enquiries;
    delete enquiries[key];
    this.setState({ enquiries: enquiries });
  }

  setEnquiryJson(key, event, arrKey) {
    let enquiries = this.state.enquiries;
    enquiries[arrKey][key] = event.target.value;
    this.setState({ enquiries: enquiries });
  }

  async bulkUpload(event) {
    event.preventDefault();
    if (!this.validate()) {
      return;
    }

    this.setState({ saving: true });
    let formData = new FormData();
    formData.append("group", this.state.input.group);
    formData.append("auctions", this.state.auctions, this.state.auctions.name);

    const response = await bulkAuctionCreateFromUpload(formData);
    if (response.success) {
      this.setState({ saving: false });
      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      })
        .then((value) => {
          //window.location.href = "";
        })
        .catch((response) => {
          swal("Failed", response.message, "error");
        });
    } else {
      this.setState({ saving: false });
      let error_message = response.message;
      swal("Failed", error_message, "error");
    }
  }

  validate() {
    let input = this.state.input;
    let errors = {};
    let isValid = true;
    if (!input["group"]) {
      isValid = false;
      errors["group"] = "Please select a group.";
      swal("Failed", errors["group"], "error");
      return isValid;
    }

    if (!this.state.auctions) {
      isValid = false;
      errors["auctions"] = "Please upload your csv file.";
      swal("Failed", errors["auctions"], "error");
      return isValid;
    }

    var parts = this.state.auctions.name.split(".");
    var ext = parts[parts.length - 1];
    if (ext.toLowerCase() !== "csv") {
      isValid = false;
      errors["auctions"] = "Please check file format.";
      swal("Failed", errors["auctions"], "error");
      return isValid;
    }

    return isValid;
  }

  render() {
    //let masks = this.state.masks;
    let groups = this.state.groups;
    let rows = this.state.row;
    let bulk_create = this.state.bulk_create;
    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image bg-background3"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-white">
                  <h1 className="">{i18n.t("dashboard")}</h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <Link to="/">{i18n.t("home")}</Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-white"
                      aria-current="page"
                    >
                      {i18n.t("dashboard")}
                    </li>
                    <li
                      className="breadcrumb-item active text-white"
                      aria-current="page"
                    >
                      {i18n.t("create_auction")}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <SideBar />
              </div>
              <div className="col-xl-9 col-lg-12 col-md-12">
                <form
                  id="UpdateProfile"
                  className="card mb-0"
                  autoComplete="off"
                  onSubmit={this.handleSubmit}
                >
                  <div className="card-header">
                    <h3
                      className="card-title"
                      style={{ width: "80%", float: "left" }}
                    >
                      {i18n.t("create_auction")}
                    </h3>
                    <button
                      type="button"
                      className="btn btn-info"
                      style={{ width: "20%", float: "left" }}
                      onClick={() => {
                        this.setState({ bulk_create: !this.state.bulk_create });
                      }}
                    >
                      {!bulk_create && <span>Create using file upload</span>}
                      {bulk_create && <span>Create using form</span>}
                    </button>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("group")}
                          </label>
                          <select
                            className="form-control"
                            data-placeholder="Select Group"
                            name="group"
                            value={this.state.input.group}
                            onChange={this.handleChange}
                          >
                            <option value="">--{i18n.t("group")}--</option>
                            {groups.length > 0 &&
                              groups.map((item) => {
                                return (
                                  <option value={item.id} key={item.id}>
                                    {item.group_name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>

                      {/* <div className="col-sm-12 col-md-6">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("mask_name")}
                          </label>
                          <select
                            className="form-control"
                            data-placeholder="Select Mask"
                            name="mask"
                            value={this.state.input.selected_mask}
                            onChange={this.handleChange}
                          >
                            <option value="">--{i18n.t("mask_name")}--</option>
                            {masks.length > 0 &&
                              masks.map((item) => {
                                return (
                                  <option value={item.id} key={item.id}>
                                    {item.mask_name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div> */}

                      {bulk_create && (
                        <div className="col-sm-12 col-md-6">
                          <div className="form-group">
                            <label
                              for="form-label"
                              className="form-label"
                              style={{ textAlign: "left" }}
                            >
                              {i18n.t("Upload CSV file")}{" "}
                              <span className="text text-danger">(*)</span>
                            </label>
                            <input
                              className="form-control "
                              type="file"
                              placeholder=""
                              name="auctions"
                              onChange={this.onFileChange}
                              style={{
                                height: "45px",
                                lineHeight: "2.3rem",
                              }}
                            />
                            <a
                              href="assets/csv/sample.csv"
                              target="_new"
                              className="text text-primary text-sm"
                            >
                              Download smaple for CSV format
                            </a>
                            <div>
                              <small class="form-text text-muted">
                                Each row should contains the following details
                              </small>

                              <small class="form-text text-muted">
                                Phone number, Package(Diamond/Gold), Bid Incr
                                Amount, Start Amount
                              </small>
                            </div>
                          </div>
                        </div>
                      )}

                      {!bulk_create &&
                        rows.length > 0 &&
                        rows.map((item, i) => {
                          return (
                            <div className="container">
                              <hr />
                              <div className="row bg bg-light">
                                <div className="col-sm-12 col-md-3">
                                  <div className="form-group">
                                    <label className="form-label">
                                      {i18n.t("phone_number")} {i + 1}
                                    </label>
                                    <input
                                      type="text"
                                      required={true}
                                      name="phone_number"
                                      className="form-control"
                                      placeholder="Phone Number"
                                      value={this.state.row[i].phone_number}
                                      onChange={(event) => {
                                        this.handleRowChange(event, i);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-12 col-md-3">
                                  <div className="form-group">
                                    <label className="form-label">
                                      {i18n.t("package")}
                                    </label>
                                    <select
                                      className="form-control"
                                      data-placeholder="Select Class"
                                      name="class"
                                      value={this.state.row[i].class}
                                      onChange={(event) => {
                                        this.handleRowChange(event, i);
                                      }}
                                    >
                                      <option value="">
                                        --{i18n.t("package")}--
                                      </option>
                                      <option value="D">
                                        {i18n.t("diamond")}
                                      </option>
                                      <option value="G">
                                        {i18n.t("gold")}
                                      </option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-sm-12 col-md-3">
                                  <div className="form-group">
                                    <label className="form-label">
                                      {i18n.t("price")}
                                    </label>
                                    <input
                                      type="text"
                                      name="start_amount"
                                      className="form-control"
                                      placeholder="Price"
                                      value={this.state.row[i].start_amount}
                                      onChange={(event) => {
                                        this.handleRowChange(event, i);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-12 col-md-3">
                                  <div className="form-group">
                                    <label className="form-label">
                                      {i18n.t("incr_amount")}
                                    </label>
                                    <input
                                      type="text"
                                      name="bid_increment"
                                      className="form-control"
                                      placeholder="Increment Amount"
                                      value={this.state.row[i].bid_increment}
                                      onChange={(event) => {
                                        this.handleRowChange(event, i);
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="col-sm-12 col-md-4">
                                  <div className="form-group">
                                    <label className="form-label">
                                      {i18n.t("incr_amount")}(Comma seperated
                                      values)
                                    </label>
                                    <input
                                      type="text"
                                      name="increment_numbers"
                                      className="form-control"
                                      placeholder="Eg: 100,200,300..."
                                      value={
                                        this.state.row[i].increment_numbers
                                      }
                                      onChange={(event) => {
                                        this.handleRowChange(event, i);
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="col-sm-12 col-md-3">
                                  <div className="form-group">
                                    <label className="form-label">
                                      {i18n.t("start_date")}{" "}
                                      {this.state.row[i].start_date
                                        ? "(" +
                                          this.state.row[i].start_date +
                                          ")"
                                        : ""}
                                    </label>
                                    <input
                                      type="datetime-local"
                                      className="form-control"
                                      placeholder="Email"
                                      name="start_date"
                                      value={this.state.row[i].start_date}
                                      onChange={(event) => {
                                        this.handleRowChange(event, i);
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="col-sm-12 col-md-3">
                                  <div className="form-group">
                                    <label className="form-label">
                                      {i18n.t("end_date")}{" "}
                                      {this.state.row[i].end_date
                                        ? "(" + this.state.row[i].end_date + ")"
                                        : ""}
                                    </label>
                                    <input
                                      type="datetime-local"
                                      name="end_date"
                                      className="form-control"
                                      placeholder="Phone"
                                      value={this.state.row[i].end_date}
                                      onChange={(event) => {
                                        this.handleRowChange(event, i);
                                      }}
                                    />
                                  </div>
                                </div>
                                {rows && rows.length === i + 1 && (
                                  <div className="col-sm-12 col-md-1">
                                    <div
                                      className="form-group"
                                      style={{ textAlign: "right" }}
                                    >
                                      <label className="form-label">
                                        &nbsp;
                                      </label>
                                      <button
                                        type="button"
                                        className="btn btn-info"
                                        onClick={this.appendData}
                                        style={{ marginRight: "20px" }}
                                      >
                                        <i className="fa fa-plus"></i>
                                        <span>Add</span>
                                      </button>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                  <div className="card-footer" style={{ textAlign: "right" }}>
                    {!bulk_create && (
                      <button type="submit" className="btn btn-primary">
                        Save Auction
                      </button>
                    )}
                    {bulk_create && (
                      <button
                        type="button"
                        className="btn btn-primary"
                        onClick={this.bulkUpload}
                      >
                        Upload Auction Numbers
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
