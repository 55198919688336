import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import { WhoWeAre } from "../about/WhoWeAre";
import { Homepage } from "../home/homepage";
import { Registration } from "../auth/registration";
import Login from "../auth/login";
import { LoginOtp } from "../auth/login_otp";
import { ForgotPassword } from "../auth/forgotPassword";
import { CategoryAuctions } from "../categoryAuctions";
import { Contact } from "../contact";
import $ from "jquery";
import { ChairmansMessage } from "../about/chairmansMessage";
import { CeoMessage } from "../about/ceoMessage";
import { TeamMzadcom } from "../about/teamMzadcom";
import { Enroll } from "../enroll";
import { Profile } from "../dashboard/profile";
import { Dashboard } from "../dashboard/dashboard";
import { Payments } from "../dashboard/payments";
import { CreateAuction } from "../dashboard/create_auction";
import { AuctionsList } from "../dashboard/auctions_list";
import { Masks } from "../dashboard/masks";
import { EditMask } from "../dashboard/edit_mask";
import { EditAuction } from "../dashboard/edit_auction";
import { NotFound } from "../notfound";
import { PaymentCancelled } from "../paymentCancelled";
import { PaymentSuccess } from "../paymentSuccess";
import { TermsConditions } from "../terms_conditions";
import { TermsConditionsPayment } from "../terms_conditions_payment";
import { UsersList } from "../dashboard/users";
import { Groups } from "../dashboard/groups";
import { EditGroup } from "../dashboard/edit_group";
import { EnrolledAuctions } from "../dashboard/enrolled_auctions";
import { getLoggedUser } from "../../state/user";
import { ResetPassword } from "../auth/resetPassword";
import { MyAuctions } from "../dashboard/my_auctions";
import { WinningBids } from "../dashboard/winning_bids";
import { Reports } from "../dashboard/reports/reports";
import { ReportsPayments } from "../dashboard/reports/reports_payments";
import { ReportsEnrolled } from "../dashboard/reports/reports_enrolled";
import { ReportsWinners } from "../dashboard/reports/reports_winners";
import { BulkMail } from "../dashboard/bulk_mail";
import { apiUrl } from "../../constants/global";

import swal from "sweetalert";
import { LiveActivity } from "../dashboard/live_activity";
import { PaymentSuccessReg } from "../paymentSuccessReg";
import { TopHeader } from "./topHeader";
import { ReportsPosition } from "../dashboard/reports/reports_position";
import { EnrolledUsers } from "../dashboard/enrolled_users";

const user = getLoggedUser();

function logout() {
  localStorage.removeItem("is_logged_in");
  localStorage.removeItem("token");
  localStorage.removeItem("mzad_user_info");
  window.location.href = "/";
}

export class Topbar extends React.Component {
  componentDidMount() {
    this.checkUserAuth();
    setInterval(this.checkUserAuth, 15000);
    (function (factory) {
      factory($);
      $(".horizontal-main").sticky({
        topSpacing: 0,
      });
      $(".horizontal-header").sticky({
        topSpacing: 0,
      });
    })(function ($) {
      var slice = Array.prototype.slice;
      var splice = Array.prototype.splice;
      var defaults = {
          topSpacing: 0,
          bottomSpacing: 0,
          className: "is-sticky",
          wrapperClassName: "sticky-wrapper",
          center: !1,
          getWidthFrom: "",
          widthFromWrapper: !0,
          responsiveWidth: !1,
          zIndex: "inherit",
        },
        $window = $(window),
        $document = $(document),
        sticked = [],
        windowHeight = $window.height(),
        scroller = function () {
          var scrollTop = $window.scrollTop(),
            documentHeight = $document.height(),
            dwh = documentHeight - windowHeight,
            extra = scrollTop > dwh ? dwh - scrollTop : 0;
          for (var i = 0, l = sticked.length; i < l; i++) {
            var s = sticked[i],
              elementTop = s.stickyWrapper.offset().top,
              etse = elementTop - s.topSpacing - extra;
            s.stickyWrapper.css("height", s.stickyElement.outerHeight());
            if (scrollTop <= etse) {
              if (s.currentTop !== null) {
                s.stickyElement.css({
                  width: "",
                  position: "",
                  top: "",
                  "z-index": "",
                });
                s.stickyElement.parent().removeClass(s.className);
                s.stickyElement.trigger("sticky-end", [s]);
                s.currentTop = null;
              }
            } else {
              var newTop =
                documentHeight -
                s.stickyElement.outerHeight() -
                s.topSpacing -
                s.bottomSpacing -
                scrollTop -
                extra;
              if (newTop < 0) {
                newTop = newTop + s.topSpacing;
              } else {
                newTop = s.topSpacing;
              }
              if (s.currentTop !== newTop) {
                var newWidth;
                if (s.getWidthFrom) {
                  let padding =
                    s.stickyElement.innerWidth() - s.stickyElement.width();
                  newWidth = $(s.getWidthFrom).width() - padding || null;
                } else if (s.widthFromWrapper) {
                  newWidth = s.stickyWrapper.width();
                }
                if (newWidth == null) {
                  newWidth = s.stickyElement.width();
                }
                s.stickyElement
                  .css("width", newWidth)
                  .css("position", "fixed")
                  .css("top", newTop)
                  .css("z-index", s.zIndex);
                s.stickyElement.parent().addClass(s.className);
                if (s.currentTop === null) {
                  s.stickyElement.trigger("sticky-start", [s]);
                } else {
                  s.stickyElement.trigger("sticky-update", [s]);
                }
                if (
                  (s.currentTop === s.topSpacing && s.currentTop > newTop) ||
                  (s.currentTop === null && newTop < s.topSpacing)
                ) {
                  s.stickyElement.trigger("sticky-bottom-reached", [s]);
                } else if (
                  s.currentTop !== null &&
                  newTop === s.topSpacing &&
                  s.currentTop < newTop
                ) {
                  s.stickyElement.trigger("sticky-bottom-unreached", [s]);
                }
                s.currentTop = newTop;
              }
              var stickyWrapperContainer = s.stickyWrapper.parent();
              var unstick =
                s.stickyElement.offset().top + s.stickyElement.outerHeight() >=
                  stickyWrapperContainer.offset().top +
                    stickyWrapperContainer.outerHeight() &&
                s.stickyElement.offset().top <= s.topSpacing;
              if (unstick) {
                s.stickyElement
                  .css("position", "absolute")
                  .css("top", "")
                  .css("bottom", 0)
                  .css("z-index", "");
              } else {
                s.stickyElement
                  .css("position", "fixed")
                  .css("top", newTop)
                  .css("bottom", "")
                  .css("z-index", s.zIndex);
              }
            }
          }
        },
        resizer = function () {
          windowHeight = $window.height();
          for (var i = 0, l = sticked.length; i < l; i++) {
            var s = sticked[i];
            var newWidth = null;
            if (s.getWidthFrom) {
              if (s.responsiveWidth) {
                newWidth = $(s.getWidthFrom).width();
              }
            } else if (s.widthFromWrapper) {
              newWidth = s.stickyWrapper.width();
            }
            if (newWidth != null) {
              s.stickyElement.css("width", newWidth);
            }
          }
        },
        methods = {
          init: function (options) {
            return this.each(function () {
              var o = $.extend({}, defaults, options);
              var stickyElement = $(this);
              var stickyId = stickyElement.attr("id");
              var wrapperId = stickyId
                ? stickyId + "-" + defaults.wrapperClassName
                : defaults.wrapperClassName;
              var wrapper = $("<div></div>")
                .attr("id", wrapperId)
                .addClass(o.wrapperClassName);
              stickyElement.wrapAll(function () {
                if ($(this).parent("#" + wrapperId).length === 0) {
                  return wrapper;
                }
              });
              var stickyWrapper = stickyElement.parent();
              if (o.center) {
                stickyWrapper.css({
                  width: stickyElement.outerWidth(),
                  marginLeft: "auto",
                  marginRight: "auto",
                });
              }
              if (stickyElement.css("float") === "right") {
                stickyElement
                  .css({
                    float: "none",
                  })
                  .parent()
                  .css({
                    float: "right",
                  });
              }
              o.stickyElement = stickyElement;
              o.stickyWrapper = stickyWrapper;
              o.currentTop = null;
              sticked.push(o);
              methods.setWrapperHeight(this);
              methods.setupChangeListeners(this);
            });
          },
          setWrapperHeight: function (stickyElement) {
            var element = $(stickyElement);
            var stickyWrapper = element.parent();
            if (stickyWrapper) {
              stickyWrapper.css("height", element.outerHeight());
            }
          },
          setupChangeListeners: function (stickyElement) {
            if (window.MutationObserver) {
              var mutationObserver = new window.MutationObserver(function (
                mutations
              ) {
                if (
                  mutations[0].addedNodes.length ||
                  mutations[0].removedNodes.length
                ) {
                  methods.setWrapperHeight(stickyElement);
                }
              });
              mutationObserver.observe(stickyElement, {
                subtree: !0,
                childList: !0,
              });
            } else {
              if (window.addEventListener) {
                stickyElement.addEventListener(
                  "DOMNodeInserted",
                  function () {
                    methods.setWrapperHeight(stickyElement);
                  },
                  !1
                );
                stickyElement.addEventListener(
                  "DOMNodeRemoved",
                  function () {
                    methods.setWrapperHeight(stickyElement);
                  },
                  !1
                );
              } else if (window.attachEvent) {
                stickyElement.attachEvent("onDOMNodeInserted", function () {
                  methods.setWrapperHeight(stickyElement);
                });
                stickyElement.attachEvent("onDOMNodeRemoved", function () {
                  methods.setWrapperHeight(stickyElement);
                });
              }
            }
          },
          update: scroller,
          unstick: function (options) {
            return this.each(function () {
              var that = this;
              var unstickyElement = $(that);
              var removeIdx = -1;
              var i = sticked.length;
              while (i-- > 0) {
                if (sticked[i].stickyElement.get(0) === that) {
                  splice.call(sticked, i, 1);
                  removeIdx = i;
                }
              }
              if (removeIdx !== -1) {
                unstickyElement.unwrap();
                unstickyElement.css({
                  width: "",
                  position: "",
                  top: "",
                  float: "",
                  "z-index": "",
                });
              }
            });
          },
        };
      if (window.addEventListener) {
        window.addEventListener("scroll", scroller, !1);
        window.addEventListener("resize", resizer, !1);
      } else if (window.attachEvent) {
        window.attachEvent("onscroll", scroller);
        window.attachEvent("onresize", resizer);
      }
      $.fn.sticky = function (method) {
        if (methods[method]) {
          return methods[method].apply(this, slice.call(arguments, 1));
        } else if (typeof method === "object" || !method) {
          return methods.init.apply(this, arguments);
        } else {
          $.error("Method " + method + " does not exist on jQuery.sticky");
        }
      };
      $.fn.unstick = function (method) {
        if (methods[method]) {
          return methods[method].apply(this, slice.call(arguments, 1));
        } else if (typeof method === "object" || !method) {
          return methods.unstick.apply(this, arguments);
        } else {
          $.error("Method " + method + " does not exist on jQuery.sticky");
        }
      };
      $(function () {
        setTimeout(scroller, 0);
      });
    });
  }

  async checkUserAuth() {
    if (user && user.role === "U") {
      // eslint-disable-next-line no-unused-vars
      const response = fetch(apiUrl + "auth", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
      })
        .then(function (response) {
          if (response.ok) {
            return response.text();
          }
          throw new Error("Something went wrong.");
        })
        .then(function (text) {
          //console.log("Request successful", text);
        })
        .catch(function (error) {
          swal(
            "Multiple login found!!!",
            "Close this window to logout and login again!",
            "error"
          )
            .then((value) => {
              logout();
            })
            .catch((response) => {
              swal("Failed", response.message, "error");
            });
        });
    }
  }

  render() {
    let current_lng = localStorage.getItem("I18N_LANGUAGE");
    let is_logged_in = this.props.is_logged;
    let have_active_registrations = this.props.have_active_registrations;
    let active_group = this.props.active_group;
    // const currentPath = window.location.pathname;
    // let is_live_page = currentPath.includes("live_activity");
    // console.log("is_live_page" + is_live_page);
    //console.log(this.props);
    return (
      <Router>
        <React.Fragment>
          <TopHeader
            current_lng={current_lng}
            is_logged_in={is_logged_in}
            have_active_registrations={have_active_registrations}
            active_group={active_group}
          />
          <Routes>
            <Route
              path="/"
              element={
                <Homepage
                  have_active_registrations={have_active_registrations}
                  active_group={active_group}
                />
              }
            />
            <Route path="/about" element={<WhoWeAre />} />
            <Route path="/about/whoweare" element={<WhoWeAre />} />
            <Route
              path="/about/message-chairman"
              element={<ChairmansMessage />}
            />
            <Route path="/about/message-ceo" element={<CeoMessage />} />
            <Route path="/about/team-mzadcom" element={<TeamMzadcom />} />
            <Route path="/terms" element={<TermsConditions />} />
            <Route path="/terms_payment" element={<TermsConditionsPayment />} />

            <Route path="/login" element={<Login />} />
            <Route path="/login_otp" element={<LoginOtp />} />
            <Route path="forgotPassword" element={<ForgotPassword />} />
            <Route path="/category-auctions" element={<CategoryAuctions />} />
            <Route path="/contact" element={<Contact />} />
            <Route
              path="/password/reset/:email/:token"
              element={<ResetPassword />}
            />
            <Route
              path="/registration/:group"
              element={
                have_active_registrations ? <Registration /> : <NotFound />
              }
            />
            {/** Protected Routes */}
            <Route
              path="/dashboard"
              element={
                localStorage.getItem("is_logged_in") &&
                (user.role === "A" ||
                  user.role === "VA" ||
                  user.role === "U") ? (
                  <Dashboard />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="/profile"
              element={
                localStorage.getItem("is_logged_in") ? (
                  <Profile />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="/enroll/:id"
              element={
                localStorage.getItem("is_logged_in") ? <Enroll /> : <NotFound />
              }
            />
            <Route
              path="/payments"
              element={
                localStorage.getItem("is_logged_in") ? (
                  <Payments />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="/create_auction"
              element={
                localStorage.getItem("is_logged_in") &&
                (user.role === "A" || user.role === "VA") ? (
                  <CreateAuction />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="/edit_auction/:id"
              element={
                localStorage.getItem("is_logged_in") &&
                (user.role === "A" || user.role === "VA") ? (
                  <EditAuction />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="/auctions_list"
              element={
                localStorage.getItem("is_logged_in") ? (
                  <AuctionsList />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="/groups"
              element={
                localStorage.getItem("is_logged_in") && user.role === "A" ? (
                  <Groups />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="/edit_group/:id"
              element={
                localStorage.getItem("is_logged_in") && user.role === "A" ? (
                  <EditGroup />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="/masks"
              element={
                localStorage.getItem("is_logged_in") && user.role === "A" ? (
                  <Masks />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="/edit_mask/:id"
              element={
                localStorage.getItem("is_logged_in") && user.role === "A" ? (
                  <EditMask />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="/payment-success/:group"
              element={
                localStorage.getItem("is_logged_in") ? (
                  <PaymentSuccess />
                ) : (
                  <PaymentSuccessReg />
                )
              }
            />
            <Route path="/payment-cancelled" element={<PaymentCancelled />} />
            <Route
              path="/users"
              element={
                localStorage.getItem("is_logged_in") && user.role === "A" ? (
                  <UsersList />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="/enrolled_users"
              element={
                localStorage.getItem("is_logged_in") &&
                (user.role === "A" || user.role === "VA") ? (
                  <EnrolledUsers />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="/my_auctions"
              element={
                localStorage.getItem("is_logged_in") ? (
                  <MyAuctions />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="/winning_bids"
              element={
                localStorage.getItem("is_logged_in") ? (
                  <WinningBids />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="/enrolled_auctions"
              element={
                localStorage.getItem("is_logged_in") ? (
                  <EnrolledAuctions />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="/reports"
              element={
                localStorage.getItem("is_logged_in") &&
                (user.role === "A" || user.role === "VA") ? (
                  <Reports />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="/reports_payments"
              element={
                localStorage.getItem("is_logged_in") &&
                (user.role === "A" || user.role === "VA") ? (
                  <ReportsPayments />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="/reports_enrolled"
              element={
                localStorage.getItem("is_logged_in") &&
                (user.role === "A" || user.role === "VA") ? (
                  <ReportsEnrolled />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="/reports_winners"
              element={
                localStorage.getItem("is_logged_in") &&
                (user.role === "A" || user.role === "VA") ? (
                  <ReportsWinners />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="/reports_positions"
              element={
                localStorage.getItem("is_logged_in") &&
                (user.role === "A" || user.role === "VA") ? (
                  <ReportsPosition />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="/bulk_mail"
              element={
                localStorage.getItem("is_logged_in") &&
                (user.role === "A" || user.role === "VA") ? (
                  <BulkMail />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route
              path="/live_activity"
              element={
                localStorage.getItem("is_logged_in") &&
                (user.role === "A" || user.role === "VA") ? (
                  <LiveActivity />
                ) : (
                  <NotFound />
                )
              }
            />
            <Route path="*" exact={true} element={<NotFound />} />
          </Routes>
        </React.Fragment>
      </Router>
    );
  }
}
