import { apiUrl } from "../constants/global";
import { getLoggedUser } from "../state/user";

const statuses = {
  A: "Approved",
  N: "Not Approved",
  D: "Deleted",
};

const user = getLoggedUser();

async function getUsers(q) {
  return fetch(apiUrl + "users?" + q, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function getUser(id) {
  return fetch(apiUrl + "users/" + id, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  }).then((data) => data.json());
}

async function createUser(payload) {
  return fetch(apiUrl + "users", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function updateUser(payload, id) {
  return fetch(apiUrl + "users/" + id, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

function deleteUser(id) {
  return fetch(apiUrl + "users/" + id, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function activateUser(payload) {
  return fetch(apiUrl + "activate_user", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function deActivateUser(payload) {
  return fetch(apiUrl + "de_activate_user", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function checkBlackList() {
  return fetch(apiUrl + "blacklist", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function checkUserActiveGroups() {
  return fetch(apiUrl + "active_groups", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

async function sendBulkMail(payload) {
  return fetch(apiUrl + "bulk_mail", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
    body: JSON.stringify(payload),
  }).then((data) => data.json());
}

async function checkUserAuth() {
  return fetch(apiUrl + "auth", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + user.token,
    },
  }).then((data) => data.json());
}

export {
  createUser,
  getUsers,
  deleteUser,
  getUser,
  updateUser,
  activateUser,
  deActivateUser,
  checkBlackList,
  checkUserActiveGroups,
  sendBulkMail,
  checkUserAuth,
  statuses,
};
