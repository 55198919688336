import React from "react";

export class Footer extends React.Component {
  render() {
    return (
      <div className="App" id="footer-sec">
        {/*Footer Section*/}
        <section style={{ position: "absolute", bottom: "0", width: "100%" }}>
          <footer className="bg-dark-purple text-white">
            {/*<div className="footer-main">
              <div className="container">
                <div className="row">
                  <div className="col-lg-2 col-md-12">
                    <h6>Resources</h6>
                    <hr className="deep-purple text-primary accent-2 mb-4 mt-0 d-inline-block mx-auto" />
                    <ul className="list-unstyled mb-0">
                      <li>
                        <a href={true}>Our Team</a>
                      </li>
                      <li>
                        <a href={true}>Contact US</a>
                      </li>
                      <li>
                        <a href={true}>About</a>
                      </li>
                      <li>
                        <a href={true}>CrusaderRecusandae</a>
                      </li>
                      <li>
                        <a href={true}>Blog</a>
                      </li>
                      <li>
                        <a href={true}>Terms and Conditions</a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-3 col-md-12">
                    <h6>Contact</h6>
                    <hr className="deep-purple  text-primary accent-2 mb-4 mt-0 d-inline-block mx-auto" />
                    <ul className="list-unstyled mb-0">
                      <li>
                        <a href={true}>
                          <i className="fa fa-address-book me-3 text-primary" />{" "}
                          AL-GHALBI TOWER Bausher, Bldg # 113, 2 floor Way #
                          5507, 23rd July Street, Al Ghubra South? PB 2470,
                          Muscat, Sultanate of Oman? Muscat 111
                        </a>
                      </li>
                      <li>
                        <a href={true}>
                          <i className="fa fa-envelope me-3 text-primary" />{" "}
                          info@mzadcom.om
                        </a>
                      </li>
                      <li>
                        <a href={true}>
                          <i className="fa fa-phone me-3 text-primary" /> 0096
                          892 788 822
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-4 col-md-12">
                    <h6>Download App</h6>
                    <hr className="deep-purple  accent-2 mb-4 mt-0 d-inline-block mx-auto" />
                    <div className>
                      <a className="btn bg-store fs-16" href>
                        <i className="fa fa-apple me-2" /> App Store
                      </a>
                      <a className="btn bg-store fs-16" href>
                        <i className="fa fa-android me-2" /> Google Pay
                      </a>
                    </div>
                    <h6 className="mb-0 mt-5">Payments</h6>
                    <hr className="deep-purple  text-primary accent-2 mb-2 mt-3 d-inline-block mx-auto" />
                    <div className="clearfix" />
                    <ul className="footer-payments">
                      <li className="ps-0">
                        <a href={true}>
                          <i className="fa fa-cc-amex" aria-hidden="true" />.
                        </a>
                      </li>
                      <li>
                        <a href={true}>
                          <i className="fa fa-cc-visa" aria-hidden="true" />.
                        </a>
                      </li>
                      <li>
                        <a href={true}>
                          <i
                            className="fa fa-credit-card-alt"
                            aria-hidden="true"
                          />
                          .
                        </a>
                      </li>
                      <li>
                        <a href={true}>
                          <i
                            className="fa fa-cc-mastercard"
                            aria-hidden="true"
                          />
                          .
                        </a>
                      </li>
                      <li>
                        <a href={true}>
                          <i className="fa fa-cc-paypal" aria-hidden="true" />.
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-3 col-md-12">
                    <h6>Subscribe</h6>
                    <hr className="deep-purple  text-primary accent-2 mb-4 mt-0 d-inline-block mx-auto" />
                    <div className="clearfix" />
                    <div className="input-group w-100">
                      <input
                        type="text"
                        className="form-control br-bs-3  br-be-3"
                        placeholder="Email"
                      />
                      <div className>
                        <button
                          type="button"
                          className="btn btn-primary br-ts-0  br-bs-0"
                        >
                          {" "}
                          Subscribe{" "}
                        </button>
                      </div>
                    </div>
                    <h6 className="mt-5 mb-3">Follow Us</h6>
                    <hr className="deep-purple  accent-2 mb-4 mt-0 d-inline-block mx-auto" />
                    <ul className="list-unstyled list-inline">
                      <li className="list-inline-item">
                        <a
                          href={true}
                          className="btn-floating btn-sm rgba-white-slight mx-1 waves-effect waves-light"
                        >
                          <i className="fa fa-facebook bg-facebook" />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a
                          href={true}
                          className="btn-floating btn-sm rgba-white-slight mx-1 waves-effect waves-light"
                        >
                          <i className="fa fa-twitter bg-info" />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a
                          href={true}
                          className="btn-floating btn-sm rgba-white-slight mx-1 waves-effect waves-light"
                        >
                          <i className="fa fa-google-plus bg-danger" />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a
                          href={true}
                          className="btn-floating btn-sm rgba-white-slight mx-1 waves-effect waves-light"
                        >
                          <i className="fa fa-linkedin bg-linkedin" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
    </div>*/}
            <div className="bg-dark-purple text-white p-0">
              <div className="container">
                <div className="row d-flex">
                  <div className="col-lg-12 col-sm-12 mt-3 mb-3 text-center foot-copyright">
                    Copyright © vodafone.om &nbsp; Powered By&nbsp;
                    <a
                      href="https://www.mzadcom.om"
                      className="fs-14 text-primary foot-comp-name"
                    >
                      mzadcom.om
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </footer>
        </section>
      </div>
    );
  }
}
