import React from "react";
import i18n from "../../i18n";
import logo from "../../logo.png";
import { Link, NavLink } from "react-router-dom";
import { getLoggedUser } from "../../state/user";

const user = getLoggedUser();
const lang = localStorage.getItem("I18N_LANGUAGE");

const changeLanguage = (ln) => {
  i18n.changeLanguage(ln);
  window.location.reload();
};

function logout() {
  localStorage.removeItem("is_logged_in");
  localStorage.removeItem("token");
  localStorage.removeItem("mzad_user_info");
  window.location.href = "/";
}

export class TopHeader extends React.Component {
  render() {
    let current_lng = this.props.current_lng;
    let is_logged_in = this.props.is_logged_in;
    let have_active_registrations = this.props.have_active_registrations;
    let active_group = this.props.active_group;

    return (
      <React.Fragment>
        <div className="header-main" id="main-top-header">
          <div className="top-bar">
            <div className="container">
              <div className="row">
                <div className="col-xl-4 col-lg-4 col-sm-4 col-4">
                  <div className="top-bar-left d-flex">
                    <div className="clearfix">
                      <ul className="contact">
                        {/* border-start */}
                        <li className="me-5 d-lg-none">
                          <a href={true} className="callnumber text-dark">
                            <span>
                              <i className="fa fa-phone me-1" />: +425 345 8765
                            </span>
                          </a>
                        </li>
                        <li className="select-country me-5">
                          {/*<span><img src={flag} alt="" width="15" /></span>*/}
                          {current_lng === "ar" && (
                            <button
                              className="btn btn-sm px-3 btn-outline-light text-dark"
                              onClick={(e) => changeLanguage("en")}
                            >
                              <img
                                src="assets/images/flags/en.svg"
                                height="15"
                                alt=""
                              />{" "}
                              English
                            </button>
                          )}
                          {current_lng === "en" && (
                            <button
                              className="btn btn-sm px-3 btn-outline-light text-dark"
                              onClick={(e) => changeLanguage("ar")}
                            >
                              <img
                                src="assets/images/flags/ar.svg"
                                height="15"
                                alt=""
                              />{" "}
                              عربي
                            </button>
                          )}
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-xl-8 col-lg-8 col-sm-8 col-8">
                  <div className="top-bar-right">
                    <h6
                      class="text-primary mt-4"
                      style={{ textAlign: lang === "ar" ? "right" : "left" }}
                    >
                      <span>Welcome</span>&nbsp;
                      <span>
                        <strong>
                          {user && user.name ? user.name : i18n.t("guest")}
                        </strong>
                      </span>
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Horizontal Header */}
          <div className="horizontal-header clearfix">
            <div className="container">
              <a
                id="horizontal-navtoggle"
                className="animated-arrow"
                href={true}
              >
                <span />
              </a>
              <span className="smllogo">
                <img src={logo} alt="" className="logo-responsive" />
              </span>
              <a href="tel:1717" className="callusbtn">
                <i className="fa fa-phone" aria-hidden="true" />.
              </a>
            </div>
          </div>
          {/* /Horizontal Header */}
          {/* Horizontal Main */}
          <div className="horizontal-main bg-dark-transparent clearfix">
            <div className="horizontal-mainwrapper container clearfix">
              <div className="desktoplogo">
                <Link exact="true" to="/">
                  <img src={logo} width={60} alt="" />
                </Link>
              </div>
              <div className="desktoplogo-1">
                <Link exact="true" to="/">
                  <img src={logo} width={60} alt="" />
                </Link>
              </div>

              {/*Nav*/}
              <nav className="horizontalMenu clearfix d-md-flex">
                <ul className="horizontalMenu-list">
                  <li>
                    <NavLink exact="true" to="/" activeClassName="active">
                      <i className="fa fa-home me-1" />
                      <span>{i18n.t("home")}</span>
                    </NavLink>
                  </li>
                  {is_logged_in && (
                    <li>
                      <NavLink to="/dashboard" activeClassName="active">
                        <i className="fa fa-dashboard me-1" />
                        <span>{i18n.t("dashboard")}</span>
                      </NavLink>
                    </li>
                  )}

                  {!is_logged_in && have_active_registrations && (
                    <li>
                      <Link to={"/registration/" + active_group.group}>
                        <i className="fa fa-user me-1" />
                        <span>{i18n.t("registration")}</span>
                      </Link>
                    </li>
                  )}

                  {is_logged_in ? (
                    <li>
                      <a
                        href={true}
                        activeClassName="active"
                        onClick={logout}
                        style={{ cursor: "pointer" }}
                      >
                        <i className="fa fa-sign-out me-1" />
                        <span>{i18n.t("logout")}</span>
                      </a>
                    </li>
                  ) : (
                    <li>
                      <Link to="/login" activeClassName="active">
                        <i className="fa fa-sign-in me-1" />
                        <span>{i18n.t("login")}</span>
                      </Link>
                    </li>
                  )}
                  {/* <li> */}
                  {/* <NavLink to="/about" activeClassName="active">
                        {i18n.t("aboutus")} */}
                  {/* <span className="fa fa-caret-down m-0"></span> */}
                  {/* </NavLink> */}
                  {/* <ul className="sub-menu">
                        <li>
                          <NavLink to="/about/whoweare">
                            {i18n.t("whoweare")}
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/about/message-chairman">
                            Chairman's Message
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to="/about/message-ceo">
                            CEO's Message
                          </NavLink>
                        </li>
                      </ul> */}
                  {/* </li> */}
                  {/* <li>
                      <NavLink to="/contact" activeClassName="active">
                        {i18n.t("contactus")}{" "}
                      </NavLink>
                    </li> */}
                  <li className="d-md-none">
                    {/*<span><img src={flag} alt="" width="15" /></span>*/}
                    {current_lng === "ar" && (
                      <button
                        className="btn btn-sm px-3 btn-outline-light text-dark"
                        onClick={(e) => changeLanguage("en")}
                      >
                        <img
                          src="assets/images/flags/en.svg"
                          height="15"
                          alt=""
                        />{" "}
                        English
                      </button>
                    )}
                    {current_lng === "en" && (
                      <button
                        className="btn btn-sm px-3 btn-outline-light text-dark"
                        onClick={(e) => changeLanguage("ar")}
                      >
                        <img
                          src="assets/images/flags/ar.svg"
                          height="15"
                          alt=""
                        />{" "}
                        عربي
                      </button>
                    )}
                  </li>
                </ul>
              </nav>
              {/*Nav*/}
            </div>
          </div>
          {/* /Horizontal Main */}
        </div>
      </React.Fragment>
    );
  }
}
