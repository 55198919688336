import React from "react";
import { Link } from "react-router-dom";
export class CategoryAuctions extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div>
          <div
            className="cover-image sptb-1 bg-background"
            data-image-src="../assets/images/banners/banner1.jpg"
          >
            <div className="header-text1 mb-0">
              <div className="container">
                <div className="row">
                  <div className="col-xl-8 col-lg-12 col-md-12 d-block mx-auto">
                    <div className="text-center text-white ">
                      <h1 className="">
                        Auctions Available
                      </h1>
                    </div>                    
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white border-bottom">
          <div className="container">
            <div className="page-header">
              <h4 className="page-title">Available Auctions</h4>
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link exact="true" to="/">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <a href={true}>Pages</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Auctions
                </li>
              </ol>
            </div>
          </div>
        </div>
        
        <section className="sptb">
        <div className="container">
          <div className="row">
            <div className="col-xl-9 col-lg-9 col-md-12">
              {/*Lists*/}
              <div className=" mb-0">
                <div className>
                  <div className="item2-gl ">
                    <div className=" mb-0">
                      <div className>
                        <div className="bg-white p-5 item2-gl-nav d-flex">
                          <h6 className="mb-0 mt-3 text-left">Showing 1 to 10 of 30 entries</h6>
                          <ul className="nav item2-gl-menu ms-auto mt-1">
                            <li className><a href="#tab-11" className="active show" data-bs-toggle="tab" title="List style"><i className="fa fa-list" /></a></li>
                            <li><a href="#tab-12" data-bs-toggle="tab" className title="Grid"><i className="fa fa-th" /></a></li>
                          </ul>
                          <div className="d-sm-flex">
                            <label className="me-2 mt-2 mb-sm-1">Sort By:</label>
                            <div className="selectgroup">
                              <label className="selectgroup-item mb-md-0">
                                <input type="radio" name="value" defaultValue="Price" className="selectgroup-input" defaultChecked />
                                <span className="selectgroup-button">Price <i className="fa fa-sort ms-1" /></span>
                              </label>
                              <label className="selectgroup-item mb-md-0">
                                <input type="radio" name="value" defaultValue="Popularity" className="selectgroup-input" />
                                <span className="selectgroup-button">Popularity</span>
                              </label>
                              <label className="selectgroup-item mb-0">
                                <input type="radio" name="value" defaultValue="Latest" className="selectgroup-input" />
                                <span className="selectgroup-button">Latest</span>
                              </label>
                              <label className="selectgroup-item mb-0">
                                <input type="radio" name="value" defaultValue="Rating" className="selectgroup-input" />
                                <span className="selectgroup-button">Rating</span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-content">
                      <div className="tab-pane active" id="tab-11">
                        <div className="card overflow-hidden">
                          <div className="d-md-flex">
                            <div className="item-card9-img">
                              <div className="item-card9-imgs">
                                <img src="../assets/images/auctions/dummy/pajero.jpeg" alt="img" className="cover-image" />
                              </div>
                              <div className="item-card9-icons">
                                <a href={true} className="item-card9-icons1 wishlist like-counter"><i className="fa fa fa-heart-o" /></a>
                                <a href className="like-counter item-card9-icons1 like fs-14 bg-blue"><i className="fa fa-eye me-1" /><span className="counter fs-14 mt-0">12</span></a>
                              </div>
                              <div className="item-overly-trans">
                                <span><a href={true} className="bg-info 2minute">Current Bid : OMR 2300</a></span>
                              </div>
                            </div>
                            <div className="card border-0 mb-0">
                              <div className="card-body ">
                                <div className="item-card9">
                                  <a href="cars-auction-detail.html" className="text-dark"><h4 className="font-weight-semibold mt-1"> Mitsubishi Pajero</h4></a>
                                  <div className="item-card9-desc mb-2">
                                    <a href={true} className="me-4"><span className><i className="fa fa-map-marker text-muted me-1" /> OMAN</span></a>
                                    <a href={true} className="me-4"><span className><i className="fa fa-calendar-o text-muted me-1" />  2 days ago</span></a>
                                  </div>
                                  <p className="mb-0 leading-tight">2022-01-15 12:00:00</p>
                                </div>
                              </div>
                              <div className="card-footer pt-4 pb-4 pe-4 ps-4">
                                <div className="item-card9-footer d-sm-flex">
                                  <div className="item-card9-cost">
                                    <h4 className="text-dark font-weight-bold mb-0 mt-0">$748.00</h4>
                                  </div>
                                  <div className="ms-auto">
                                    <a href={true} className="me-2" title="Car type"><i className="fa fa-car  me-1 text-muted" />  Automatic</a>
                                    <a href={true} className="me-2" title="Kilometrs"><i className="fa fa-road text-muted me-1 " />4000Kms</a>
                                    <a href={true} className title="FuealType"><i className="fa fa-tachometer text-muted me-1" />Electric</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card overflow-hidden">
                          <div className="d-md-flex">
                            <div className="item-card9-img">
                              <div className="item-card9-imgs">
                                <img src="../assets/images/auctions/dummy/cruiser.jpeg" alt="img" className="cover-image" />
                              </div>
                              <div className="item-card9-icons">
                                <a href={true} className="item-card9-icons1 wishlist like-counter"><i className="fa fa fa-heart-o" /></a>
                                <a href className="like-counter item-card9-icons1 like fs-14 bg-blue"><i className="fa fa-eye me-1" /><span className="counter fs-14 mt-0">12</span></a>
                              </div>
                              <div className="item-overly-trans">
                                <span><a href={true} className="bg-info 2minute">Current Bid : OMR 2300</a></span>
                              </div>
                            </div>
                            <div className="card border-0 mb-0">
                              <div className="card-body ">
                                <div className="item-card9">
                                  <a href="cars-auction-detail.html" className="text-dark"><h4 className="font-weight-semibold mt-1"> Land Cruiser 2009</h4></a>
                                  <div className="item-card9-desc mb-2">
                                    <a href={true} className="me-4"><span className><i className="fa fa-map-marker text-muted me-1" /> OMAN</span></a>
                                    <a href={true} className="me-4"><span className><i className="fa fa-calendar-o text-muted me-1" />  2 days ago</span></a>
                                  </div>
                                  <p className="mb-0 leading-tight">2022-01-15 12:00:00</p>
                                </div>
                              </div>
                              <div className="card-footer pt-4 pb-4 pe-4 ps-4">
                                <div className="item-card9-footer d-sm-flex">
                                  <div className="item-card9-cost">
                                    <h4 className="text-dark font-weight-bold mb-0 mt-0">$748.00</h4>
                                  </div>
                                  <div className="ms-auto">
                                    <a href={true} className="me-2" title="Car type"><i className="fa fa-car  me-1 text-muted" />  Automatic</a>
                                    <a href={true} className="me-2" title="Kilometrs"><i className="fa fa-road text-muted me-1 " />4000Kms</a>
                                    <a href={true} className title="FuealType"><i className="fa fa-tachometer text-muted me-1" />Electric</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="card overflow-hidden">
                          <div className="d-md-flex">
                            <div className="item-card9-img">
                              <div className="item-card9-imgs">
                                <img src="../assets/images/auctions/dummy/civic.jpeg" alt="img" className="cover-image" />
                              </div>
                              <div className="item-card9-icons">
                                <a href={true} className="item-card9-icons1 wishlist like-counter"><i className="fa fa fa-heart-o" /></a>
                                <a href className="like-counter item-card9-icons1 like fs-14 bg-blue"><i className="fa fa-eye me-1" /><span className="counter fs-14 mt-0">12</span></a>
                              </div>
                              <div className="item-overly-trans">
                                <span><a href={true} className="bg-info 2minute">Current Bid : OMR 2300</a></span>
                              </div>
                            </div>
                            <div className="card border-0 mb-0">
                              <div className="card-body ">
                                <div className="item-card9">
                                  <a href="cars-auction-detail.html" className="text-dark"><h4 className="font-weight-semibold mt-1"> Honda Civic</h4></a>
                                  <div className="item-card9-desc mb-2">
                                    <a href={true} className="me-4"><span className><i className="fa fa-map-marker text-muted me-1" /> OMAN</span></a>
                                    <a href={true} className="me-4"><span className><i className="fa fa-calendar-o text-muted me-1" />  2 days ago</span></a>
                                  </div>
                                  <p className="mb-0 leading-tight">2022-01-15 12:00:00</p>
                                </div>
                              </div>
                              <div className="card-footer pt-4 pb-4 pe-4 ps-4">
                                <div className="item-card9-footer d-sm-flex">
                                  <div className="item-card9-cost">
                                    <h4 className="text-dark font-weight-bold mb-0 mt-0">$748.00</h4>
                                  </div>
                                  <div className="ms-auto">
                                    <a href={true} className="me-2" title="Car type"><i className="fa fa-car  me-1 text-muted" />  Automatic</a>
                                    <a href={true} className="me-2" title="Kilometrs"><i className="fa fa-road text-muted me-1 " />4000Kms</a>
                                    <a href={true} className title="FuealType"><i className="fa fa-tachometer text-muted me-1" />Electric</a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="center-block text-center">
                    <ul className="pagination mb-3">
                      <li className="page-item page-prev disabled">
                        <a className="page-link" href={true} tabIndex={-1}>Prev</a>
                      </li>
                      <li className="page-item active"><a className="page-link" href={true}>1</a></li>
                      <li className="page-item"><a className="page-link" href={true}>2</a></li>
                      <li className="page-item"><a className="page-link" href={true}>3</a></li>
                      <li className="page-item page-next">
                        <a className="page-link" href={true}>Next</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/*/Lists*/}
            </div>
            {/*Right Side Content*/}
            <div className="col-xl-3 col-lg-3 col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="input-group">
                    <input type="text" className="form-control br-bs-3  br-be-3" placeholder="Search" />
                    <div className>
                      <button type="button" className="btn btn-primary br-ts-0  br-bs-0">
                        Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="card overflow-hidden">
                <div className="px-4 py-3 border-bottom">
                  <h4 className="mb-0">Categories</h4>
                </div>
                <div className="card-body">
                  <div className id="container">
                    <div className="filter-product-checkboxs">
                      <label className="custom-control custom-checkbox mb-3">
                        <input type="checkbox" className="custom-control-input" name="checkbox1" defaultValue="option1" />
                        <span className="custom-control-label">
                          <a href={true} className="text-dark">Vehicles<span className="label label-secondary float-end">14</span></a>
                        </span>
                      </label>
                      <label className="custom-control custom-checkbox mb-3">
                        <input type="checkbox" className="custom-control-input" name="checkbox2" defaultValue="option2" />
                        <span className="custom-control-label">
                          <a href={true} className="text-dark">Great Deals<span className="label label-secondary float-end">22</span></a>
                        </span>
                      </label>
                      <label className="custom-control custom-checkbox mb-3">
                        <input type="checkbox" className="custom-control-input" name="checkbox3" defaultValue="option3" />
                        <span className="custom-control-label">
                          <a href={true} className="text-dark">Antiques<span className="label label-secondary float-end">78</span></a>
                        </span>
                      </label>
                      <label className="custom-control custom-checkbox mb-3">
                        <input type="checkbox" className="custom-control-input" name="checkbox4" defaultValue="option3" />
                        <span className="custom-control-label">
                          <a href={true} className="text-dark">Properties<span className="label label-secondary float-end">35</span></a>
                        </span>
                      </label>
                      <label className="custom-control custom-checkbox mb-3">
                        <input type="checkbox" className="custom-control-input" name="checkbox5" defaultValue="option3" />
                        <span className="custom-control-label">
                          <a href={true} className="text-dark">Copyrights<span className="label label-secondary float-end">23</span></a>
                        </span>
                      </label>
                      <label className="custom-control custom-checkbox mb-3">
                        <input type="checkbox" className="custom-control-input" name="checkbox6" defaultValue="option3" />
                        <span className="custom-control-label">
                          <a href={true} className="text-dark">Special Items<span className="label label-secondary float-end">14</span></a>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="px-4 py-3 border-bottom border-top">
                  <h4 className="mb-0">Price Range</h4>
                </div>
                <div className="card-body">
                  <div className="h6">
                  <input type="range" className="form-control-range" id="formControlRange" />
                  </div>
                </div>
                <div className="px-4 py-3 border-bottom border-top">
                  <h4 className="mb-0">Year</h4>
                </div>
                <div className="card-body">
                  <div className="row">
                    <div className="form-group col-md-6 mb-0">
                      <label htmlFor="inputState1" className="col-form-label">Min</label>
                      <select id="inputState1" className="form-control select2">
                        <option>1995</option>
                        <option>1996</option>
                        <option>1997</option>
                        <option>1998</option>
                        <option>1999</option>
                        <option>2000</option>
                        <option>2001</option>
                        <option>2002</option>
                        <option>2003</option>
                        <option>2004</option>
                        <option>2005</option>
                        <option>2006</option>
                        <option>2007</option>
                        <option>2008</option>
                        <option>2009</option>
                        <option>2010</option>
                        <option>2011</option>
                        <option>2012</option>
                        <option>2013</option>
                        <option>2014</option>
                        <option>2015</option>
                        <option>2016</option>
                        <option>2017</option>
                        <option>2019</option>
                        <option>2019</option>
                        <option>2020</option>
                      </select>
                    </div>
                    <div className="form-group col-md-6 mb-0">
                      <label htmlFor="inputState2" className="col-form-label">Max</label>
                      <select id="inputState2" className="form-control select2">
                        <option>2020</option>
                        <option>2019</option>
                        <option>2019</option>
                        <option>2017</option>
                        <option>2016</option>
                        <option>2015</option>
                        <option>2014</option>
                        <option>2013</option>
                        <option>2012</option>
                        <option>2011</option>
                        <option>2010</option>
                        <option>2009</option>
                        <option>2008</option>
                        <option>2007</option>
                        <option>2006</option>
                        <option>2005</option>
                        <option>2004</option>
                        <option>2003</option>
                        <option>2002</option>
                        <option>2001</option>
                        <option>2000</option>
                        <option>1999</option>
                        <option>1998</option>
                        <option>1997</option>
                        <option>1996</option>
                        <option>1995</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="card-footer">
                  <a href={true} className="btn btn-secondary btn-block">Apply Filter</a>
                </div>
              </div>
            </div>
            {/*/Right Side Content*/}
          </div>
        </div>
      </section>

      </React.Fragment>
    );
  }
}
