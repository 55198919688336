import React from "react";
import { Link } from "react-router-dom";
import { updatePayment } from "../models/auctions";

export class PaymentSuccess extends React.Component {
  constructor(props) {
    super(props);
    let path = window.location.pathname;
    let groupId = path.substring(path.lastIndexOf("/") + 1, path.length);
    this.state = {
      group_id: groupId,
      error: false,
      error_msg: "",
    };
  }

  async componentDidMount() {
    const response = await updatePayment({
      group_id: this.state.group_id,
      invoice: localStorage.getItem("invoice") || null,
    });
    console.log(response);
    if (!response.success) {
      this.setState({ error: true, error_msg: response.message });
    }
  }
  render() {
    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image bg-background3 sptb-2"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-white ">
                  <h1 className="">
                    {this.state.error
                      ? "Payment Error!!!"
                      : "Payment Success!!!"}
                  </h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <Link exact="true" to="/">
                        Home
                      </Link>
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>

        {this.state.error && (
          <section className="sptb">
            <div className="container">
              <div className="container text-center">
                <div className="display-1 mb-8"></div>
                <h1 className="h2 mb-7 text-danger">Payment Error</h1>
                <p className="h4 font-weight-Automatic text-danger mb-8 leading-Automatic">
                  {this.state.error_msg}
                </p>
                <Link className="btn btn-warning" to="/">
                  Back To Home
                </Link>
              </div>
            </div>
          </section>
        )}

        {!this.state.error && (
          <section className="sptb">
            <div className="container">
              <div className="container text-center">
                <div className="display-1 mb-8"></div>
                <h1 className="h2 mb-7 text-success">Payment Success</h1>
                <p className="h4 font-weight-Automatic text-success mb-8 leading-Automatic">
                  Success!!!. Now you can do bidding with available auctions of
                  same group.
                </p>
                <Link className="btn btn-warning" to="/">
                  Back To Home
                </Link>
              </div>
            </div>
          </section>
        )}
      </React.Fragment>
    );
  }
}
