import React from "react";
import { Link } from "react-router-dom";
import { SideBar } from "./sidebar";
import { getPayments, getUserPayments } from "../../models/payments";
import { getLoggedUser } from "../../state/user";
import DataTable from "react-data-table-component";
import i18n from "../../i18n";
import { apiUrl } from "../../constants/global";

const user = getLoggedUser();

const columns = [
  {
    name: "ID",
    selector: "id",
    sortable: true,
    wrap: true,
    width: "50px",
    cell: (row) => <a href={"/edit_auction/" + row.id}>{row.id}</a>,
  },
  {
    name: i18n.t("group"),
    selector: "group",
    sortable: true,
    cell: (row) => row.group.group_name,
  },
  {
    name: i18n.t("price"),
    selector: "amount",
  },
  {
    name: i18n.t("payment_date"),
    selector: "payment_date",
    wrap: true,
  },
  {
    name: i18n.t("invoice"),
    selector: "reference_number",
    wrap: true,
  },
  {
    name: i18n.t("download"),
    button: true,
    cell: (row) => (
      <a
        className="btn btn-info"
        href={apiUrl + "payment/" + row.id}
        target="_blank"
        rel="noreferrer"
      >
        {i18n.t("download")}
      </a>
    ),
  },
];
const customStyles = {
  headCells: {
    style: {
      fontWeight: "bold",
      backgroundColor: "#e60000",
      color: "#FFFFFF",
    },
  },
};
export class Payments extends React.Component {
  constructor() {
    super();
    this.state = {
      payments: {},
    };
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadPayments();
  }

  async loadPayments() {
    let response = null;
    if (user.role === "A" || user.role === "VA") {
      response = await getPayments();
    }
    if (user.role === "U") {
      response = await getUserPayments();
    }
    let payments = response.data;
    this.setState({ payments: payments });
  }
  render() {
    let payments = this.state.payments;
    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image bg-background3"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-white">
                  <h1 className="">{i18n.t("dashboard")}</h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <Link to="/">{i18n.t("home")}</Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-white"
                      aria-current="page"
                    >
                      {i18n.t("payment")}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb">
          <div className="container">
            <div className="row">
              <div className="col-xl-3 col-lg-12 col-md-12">
                <SideBar />
              </div>
              <div className="col-xl-9 col-lg-12 col-md-12">
                <div className="card mb-0">
                  <div className="card-header">
                    <h3 className="card-title">{i18n.t("payments")}</h3>
                  </div>
                  <div className="card-body">
                    <div className="table-responsive ">
                      {payments.length === 0 && (
                        <div class="alert alert-danger">
                          No payments found!!!
                        </div>
                      )}
                      {payments.length > 0 && (
                        <DataTable
                          title=""
                          columns={columns}
                          data={payments}
                          customStyles={customStyles}
                          highlightOnHover
                          pagination
                          paginationPerPage={15}
                          paginationRowsPerPageOptions={[15, 25, 50, 100]}
                          paginationComponentOptions={{
                            rowsPerPageText: "Payments per page:",
                            rangeSeparatorText: "out of",
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
