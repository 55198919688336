import React from "react";
import { Link } from "react-router-dom";
import { SideBar } from "../sidebar";
import {
  getAllAuctionsByGroup,
  getAllBiddersByGroup,
} from "../../../models/auctions";
import { getAllGroups } from "../../../models/groups";
import DataTable from "react-data-table-component";
import i18n from "../../../i18n";
import { Spinner } from "react-bootstrap";
import { apiUrl } from "../../../constants/global";

const cats = {
  D: "Diamond",
  G: "Gold",
};
export class Reports extends React.Component {
  constructor() {
    super();
    this.state = {
      hide_menu: false,
      input: {},
      groups: {},
      phone_numbers: {},
      bidders: {},
      phone_loader: false,
      open_popup: false,
      bidder_loading: false,
      customStyles: {
        headCells: {
          style: {
            fontWeight: "bold",
            backgroundColor: "#e60000",
            color: "#FFFFFF",
          },
        },
      },
      //actionsMemo : React.useMemo(() => <Export onExport={() => downloadCSV(data)} />, []),
      columns: [
        {
          name: "Bidder ID",
          selector: "enroll_id",
          sortable: true,
          width: "70px",
        },
        {
          name: "User ID",
          selector: "userId",
          sortable: true,
          width: "100px",
        },
        // {
        //   name: "Name",
        //   selector: "name",
        //   cell: (row) => <span>{row.user.name}</span>,
        // },
        {
          name: "Username",
          selector: "username",
          cell: (row) => <span>{row.user.username}</span>,
        },
        {
          name: "Email",
          selector: "email",
          cell: (row) => <span>{row.user.email}</span>,
        },
        {
          name: "Phone",
          selector: "mobile",
          cell: (row) => <span>{row.user.mobile}</span>,
        },
        {
          name: i18n.t("Auction number"),
          selector: "phone_number",
          cell: (row) => <span>{row.auction.phone_number}</span>,
        },
        {
          name: i18n.t("latest_bid_amount"),
          selector: "bid_amount",
          sortable: true,
        },
        {
          name: i18n.t("category"),
          selector: "category",
          wrap: true,
          cell: (row) => <span>{cats[row.auction.class]}</span>,
        },
        {
          name: "Bid Date",
          selector: "created_at",
          sortable: true,
        },
        // {
        //   name: i18n.t("group"),
        //   selector: "group",
        //   cell: (row) => <span>{row.group.group_name}</span>,
        // },
        {
          name: i18n.t("start_date"),
          selector: "start_date",
          wrap: true,
          cell: (row) => <span>{row.auction.start_date}</span>,
        },
        {
          name: i18n.t("end_date"),
          selector: "end_date",
          wrap: true,
          cell: (row) => <span>{row.auction.end_date}</span>,
        },
      ],
    };

    this.handleChange = this.handleChange.bind(this);
    this.loadPhoneNumbers = this.loadPhoneNumbers.bind(this);
    this.searchSubmit = this.searchSubmit.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.loadAllGroups();
  }

  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
    if (event.target.name === "group") {
      input.phone_number = false;
      this.setState({ input });
      this.loadPhoneNumbers(event.target.value);
    }
  }

  async loadAllGroups() {
    const response = await getAllGroups();
    let groups = response.data;
    this.setState({ groups });
  }

  async loadPhoneNumbers(group) {
    if (group) {
      this.setState({ phone_loader: true });
      const response = await getAllAuctionsByGroup(group);
      if (response.data) {
        this.setState({ phone_loader: false });
        this.setState({ phone_numbers: response.data });
      }
    }
  }

  async searchSubmit() {
    this.setState({ bidders: {} });
    this.setState({ bidder_loading: true });
    const response = await getAllBiddersByGroup(this.state.input);
    if (response.data) {
      this.setState({ bidder_loading: false });
      this.setState({ bidders: response.data });
    }
  }

  render() {
    let groups = this.state.groups;
    let phone_numbers = this.state.phone_numbers;
    let bidders = this.state.bidders;
    return (
      <React.Fragment>
        <section>
          <div
            className="bannerimg cover-image bg-background3"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-white">
                  <h1 className="">{i18n.t("reports")}</h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <Link to="/">{i18n.t("home")}</Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-white"
                      aria-current="page"
                    >
                      {i18n.t("dashboard")}
                    </li>
                    <li
                      className="breadcrumb-item active text-white"
                      aria-current="page"
                    >
                      {i18n.t("reports")}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="sptb">
          <div className="container">
            <div className="row">
              <div
                className={
                  this.state.hide_menu
                    ? "d-none"
                    : "col-xl-3 col-lg-12 col-md-12"
                }
              >
                <SideBar />
              </div>
              <div
                className={
                  this.state.hide_menu
                    ? "col-xl-12 col-lg-12 col-md-12"
                    : "col-xl-9 col-lg-12 col-md-12"
                }
              >
                <form
                  id="UpdateProfile"
                  className="card mb-0"
                  autoComplete="off"
                >
                  <div className="card-header">
                    <h3 className="card-title">
                      <span
                        onClick={() => {
                          this.setState({ hide_menu: !this.state.hide_menu });
                        }}
                      >
                        <i
                          class={
                            this.state.hide_menu
                              ? "fa fa-arrow-right"
                              : "fa fa-arrow-left"
                          }
                          aria-hidden="true"
                        ></i>
                        &nbsp;
                      </span>
                      {i18n.t("search")}
                    </h3>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-12 col-md-5">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("group")}
                          </label>
                          <select
                            className="form-control"
                            data-placeholder="Select Class"
                            name="group"
                            value={this.state.input.group}
                            onChange={this.handleChange}
                          >
                            <option value="">--{i18n.t("group")}--</option>
                            {groups.length > 0 &&
                              groups.map((item) => {
                                return (
                                  <option value={item.id} key={item.id}>
                                    {item.group_name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-5">
                        <div className="form-group">
                          <label className="form-label">
                            {i18n.t("phone_number")}
                          </label>

                          <div className="input-group">
                            <select
                              className="form-control"
                              data-placeholder="Select Class"
                              name="phone_number"
                              value={this.state.input.phone_number}
                              onChange={this.handleChange}
                            >
                              <option value="">
                                --{i18n.t("phone_number")}--
                              </option>
                              {phone_numbers.length > 0 &&
                                phone_numbers.map((item) => {
                                  return (
                                    <option value={item.id} key={item.id}>
                                      {item.phone_number}
                                    </option>
                                  );
                                })}
                            </select>

                            {this.state.phone_loader && (
                              <span
                                className="input-group-addon"
                                style={{
                                  position: "absolute",
                                  marginLeft: "54%",
                                  marginTop: "10px",
                                }}
                              >
                                <i className="fa fa-refresh fa-spin"></i>
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-12 col-md-2">
                        <div className="form-group">
                          <label className="form-label">&nbsp;</label>
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={this.searchSubmit}
                            disabled={!this.state.input.group}
                          >
                            {i18n.t("search")}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

                <hr />

                <div className="card mb-0">
                  <div className="card-header">
                    <h3 className="card-title">{i18n.t("result")}</h3>
                    {bidders.length > 0 && (
                      <div style={{ position: "absolute", right: "5px" }}>
                        <a
                          href={
                            apiUrl +
                            "report/pdf/" +
                            this.state.input.phone_number +
                            "/" +
                            this.state.input.group
                          }
                          className="btn btn-dark"
                          target="_blank"
                          rel="noreferrer"
                        >
                          PDF
                        </a>{" "}
                        <a
                          href={
                            apiUrl +
                            "report/excel/" +
                            this.state.input.phone_number +
                            "/" +
                            this.state.input.group
                          }
                          className="btn btn-dark"
                          target="_blank"
                          rel="noreferrer"
                        >
                          Excel
                        </a>
                      </div>
                    )}
                  </div>
                  <div className="card-body">
                    <div className="table-responsive ">
                      {this.state.bidder_loading && (
                        <div className="text-center">
                          <Spinner animation="border" />
                        </div>
                      )}
                      {!bidders.length && (
                        <div className="alert alert-danger">
                          {i18n.t("no_result")}
                        </div>
                      )}
                      {bidders.length > 0 && (
                        <DataTable
                          title=""
                          columns={this.state.columns}
                          data={bidders}
                          customStyles={this.state.customStyles}
                          highlightOnHover
                          pagination
                          paginationPerPage={15}
                          paginationRowsPerPageOptions={[15, 25, 50, 100]}
                          paginationComponentOptions={{
                            rowsPerPageText: "Bidders per page:",
                            rangeSeparatorText: "out of",
                          }}
                          //actions={this.state.actionsMemo}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}
