import React from "react";
import i18n from "../../i18n";
export class SocialSignin extends React.Component {
  render() {
    return (
      <React.Fragment>
        {/* <hr className="divider" />
        <div className="card-body social-images">
          <p className="text-body text-left">
            {i18n.t("social_account_signin")}
          </p>
          <div className="row">
            <div className="col-6">
              <a
                href="https://www.facebook.com/"
                className="btn btn-white me-2 border px-2 btn-lg btn-block text-left"
              >
                <img
                  src="../assets/images/svgs/svg/facebook.svg"
                  className="h-5 w-5"
                  alt=""
                />
                <span className="ms-3 d-inline-block font-weight-bold">
                  {" "}
                  Facebook
                </span>
              </a>
            </div>
            <div className="col-6">
              <a
                href="https://www.google.com/gmail/"
                className="btn btn-white me-2 px-2 border btn-lg btn-block text-left"
              >
                <img
                  src="../assets/images/svgs/svg/search.svg"
                  className="h-5 w-5"
                  alt=""
                />
                <span className="ms-3 d-inline-block font-weight-bold">
                  {" "}
                  Google
                </span>
              </a>
            </div>
          </div>
        </div> */}
      </React.Fragment>
    );
  }
}
