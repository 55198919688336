import React from "react";
import { Link } from "react-router-dom";
import i18n from "../../i18n";
import { forgotPassword } from "../../models/login";
import swal from "sweetalert";
export class ForgotPassword extends React.Component {
  constructor() {
    super();
    this.state = {
      input: {},
      errors: {},
    };
    this.handleChange = this.handleChange.bind(this);
    this.sendForgetPasswordEmail = this.sendForgetPasswordEmail.bind(this);
  }
  handleChange(event) {
    let input = this.state.input;
    input[event.target.name] = event.target.value;
    this.setState({ input });
  }

  async sendForgetPasswordEmail() {
    const response = await forgotPassword({ userId: this.state.input.userId });
    if (response.success) {
      swal("Success", response.message, "success", {
        buttons: false,
        timer: 2000,
      })
        .then((value) => {})
        .catch((response) => {
          swal("Failed", response.message, "error");
        });
    } else {
      swal("Failed", response.message, "error");
    }
  }

  render() {
    return (
      <div>
        <section>
          <div
            className="bannerimg cover-image bg-background3"
            data-image-src="../assets/images/banners/banner2.jpg"
          >
            <div className="header-text mb-0">
              <div className="container">
                <div className="text-center text-white">
                  <h1 className="">{i18n.t("forget_password")}</h1>
                  <ol className="breadcrumb text-center">
                    <li className="breadcrumb-item">
                      <Link exact="true" to="/">
                        {i18n.t("home")}
                      </Link>
                    </li>
                    <li
                      className="breadcrumb-item active text-white"
                      aria-current="page"
                    >
                      {i18n.t("forget_password")}
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="sptb mb-8">
          <div className="container">
            <div className="row">
              <div className="col-lg-5 col-xl-4 col-md-6 d-block mx-auto">
                <div className="single-page w-100 p-0">
                  <div className="wrapper wrapper2">
                    <form id="forgotpsd" className="card-body">
                      <h3 className="pb-2">{i18n.t("forget_password")}</h3>
                      <div className="mail">
                        <input
                          type="email"
                          name="userId"
                          value={this.state.input.userId}
                          onChange={this.handleChange}
                        />
                        <label>Mail or Username</label>
                      </div>
                      <div className="submit">
                        <button
                          type="button"
                          className="btn btn-primary btn-block"
                          disabled={!this.state.input.userId}
                          onClick={this.sendForgetPasswordEmail}
                        >
                          {i18n.t("send")}
                        </button>
                      </div>
                      <div className="text-center text-dark mb-0">
                        Forget it, <Link to="/login">send me back </Link> to the{" "}
                        <Link to="/login">sign in</Link>.
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
